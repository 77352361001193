import { Typography, withStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { AppBar, Responsive } from 'react-admin';
import CustomUserMenu from './CustomUserMenu';

import Logo from './Logo';

const styles = {
    appbar: {
        '& .app-loader': {
            margin: 15,
        },
    },
    title: {
        position: 'absolute',
        left: 72,
        paddingTop: 12,
        paddingBottom: 12,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: '1 0 1px',
        display: 'flex',
        justifyContent: 'center',
    },
};

const CustomAppBar = ({ classes, ...props }) => (
    <AppBar {...props}
            userMenu={<CustomUserMenu logout={props.logout} />}
            color="primary"
            className={classes.appbar}
    >
        <Responsive
            medium={
                <div className={classes.spacer}>
                    <Logo />
                </div>
            }
            large={
                <Fragment>
                    <Typography variant="title"
                                color="inherit"
                                className={classes.title}
                                id="react-admin-title"
                    />
                    <div className={classes.spacer}>
                        <Logo />
                    </div>
                </Fragment>
            }
        />
    </AppBar>
);

export default withStyles(styles)(CustomAppBar);
