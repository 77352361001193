import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { SimpleForm, TextInput, Toolbar } from 'react-admin';

import { ToolbarContainer } from '../components';
import generalStyles from '../styles';
import { required } from '../validations';

const styles = theme => ({
    spacer: generalStyles(theme).spacer,
});

class ArticleCategoryForm extends Component {

    render() {
        const { classes, record, ...props } = this.props;
        const resource = 'article-categories';

        return (
            <SimpleForm {...props}
                        redirect={record.id ? false : 'edit'}
                        resource={resource}
                        record={record}
                        toolbar={
                            <Toolbar classes={{ spacer: classes.spacer }}>
                                <ToolbarContainer maxWidth="lg" />
                            </Toolbar>
                        }
            >
                <TextInput source="name" validate={required(resource, 'name')} />
            </SimpleForm>
        );
    }

}

export default withStyles(styles)(ArticleCategoryForm);
