import { i18nProvider } from '../providers';
import { changeLocale } from 'react-admin';
import { connect } from 'react-redux';

const mapCountriesToProps = state => {
    const { locale } = state.i18n;
    const { countries } = i18nProvider(locale);

    return {
        countries: Object.keys(countries).map(countryCode => ({
            code: countryCode,
            name: countries[countryCode],
        })),
    };
};

export default connect(
    mapCountriesToProps,
    { changeLocale },
);
