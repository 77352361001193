import { Button, Card, CardHeader, withStyles } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import React, { Component, cloneElement } from 'react';
import { DeleteButton, EditButton, ShowButton, translate } from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import RestoreButton from './RestoreButton';

import ExportProjectRecord from '../components/ExportRecord';
import generalStyles from '../styles';

const styles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
        '&:last-child': {
            paddingBottom: theme.spacing.unit * 2,
        },
    },
    selected: {
        backgroundColor: theme.palette.primary.toolbar,
    },
    textButton: {
        marginLeft: '0.5rem',
    },
    barcodeHere: {
        backgroundColor: theme.palette.primary.barcodeHere,
    },
    ...generalStyles(theme),
});

class ViewMobileGrid extends Component {

    state = {};

    handleSetState = state => {
        this.setState(state);
    };

    handleToggleItem = () => {
        const { id, isLoading, hasBulkActions, onToggleItem } = this.props;

        if (!isLoading && hasBulkActions) {
            onToggleItem(id);
        }
    };

    render() {
        const {
            classes,
            record,
            title,
            body,
            editable,
            deletable,
            isShow,
            exportable,
            deleteButton,
            customButtons,
            resource,
            basePath,
            permissions,
            isSelected,
            barcodeHere,
            ...props
        } = this.props;

        return record ? (
            <Card onClick={this.handleToggleItem}
                  className={[
                      isSelected ? classes.selected : null,
                      classes.card,
                      record.id === barcodeHere ? classes.barcodeHere : null
                  ].filter(Boolean).join(' ')}
            >
                <CardHeader title={
                    <div className={classes.cardTitleContent}>
                        <div>
                            {cloneElement(title, { record })}
                        </div>
                        <div className={classes.nowrap}>
                            {deletable && !record.deleted_at && (
                                <DeleteButton resource={resource}
                                              label={deleteButton}
                                              basePath={basePath}
                                              record={record}
                                              undoable={false}
                                              disabled={record.deletable === false}
                                />
                            )}
                            {editable && !record.deleted_at && (
                                <EditButton resource={resource} basePath={basePath} record={record} />
                            )}
                            {record.deleted_at && <RestoreButton resource={resource} basePath={basePath} record={record} />}
                            {isShow && <ShowButton resource={resource} basePath={basePath} record={record} />}
                            {exportable && <ExportProjectRecord onlyIcon={true} record={record} />}
                            {customButtons && cloneElement(customButtons, {
                                ...this.state,
                                record,
                                permissions,
                                basePath,
                                resource,
                                setState: this.handleSetState,
                            })}
                        </div>
                    </div>
                } />
                {cloneElement(body, {
                    ...props,
                    ...this.state,
                    permissions,
                    record: record,
                    className: classes.cardContent,
                    basePath,
                    resource,
                })}
            </Card>
        ) : null;
    }

}

class UnconnectedMobileGrid extends Component {

    handleUnselectItems = () => {
        const { onUnselectItems } = this.props;

        onUnselectItems();
    };

    handleSelectItems = () => {
        const { ids, onSelect } = this.props;

        onSelect(ids);
    };

    isSelectedAll = () => {
        const { selectedIds, ids } = this.props;

        return selectedIds.filter(i => !ids.includes(i))
            .concat(ids.filter(i => !selectedIds.includes(i)))
            .length === 0;
    };

    render() {
        const {
            classes,
            ids,
            data,
            selectedIds,
            hasBulkActions,
            translate,
            ...props
        } = this.props;

        return (
            <div style={{ margin: '1em' }}>
                {ids.length > 0 && hasBulkActions ? (
                    this.isSelectedAll() ? (
                        <Button variant="contained" color="primary" onClick={this.handleUnselectItems} size="small">
                            <CheckIcon />
                            <span className={classes.textButton}>{translate('ra.action.unselect_all')}</span>
                        </Button>
                    ) : (
                        <Button variant="contained" color="primary" onClick={this.handleSelectItems} size="small">
                            <CheckIcon />
                            <span className={classes.textButton}>{translate('ra.action.select_all')}</span>
                        </Button>
                    )
                ) : null}
                {ids.map(id => (
                    <ViewMobileGrid key={id}
                                    id={id}
                                    isSelected={selectedIds.includes(id)}
                                    record={data[id]}
                                    hasBulkActions={hasBulkActions}
                                    classes={classes}
                                    {...props}
                    />
                ))}
            </div>
        );
    }

}

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
    withStyles(styles),
    translate,
    connect(mapStateToProps, undefined),
);

const MobileGrid = enhance(UnconnectedMobileGrid);

MobileGrid.defaultProps = {
    data: {},
    ids: [],
    deletable: true,
    editable: true,
    isShow: false,
    exportable: false,
};

export default MobileGrid;
