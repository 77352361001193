import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { GET_LIST, withDataProvider } from 'react-admin';
import { compose } from 'recompose';

import StorageSpace from './StorageSpace';
import Warehouses from './Warehouses';
import Customers from './Customers';

const styles = theme => ({
    widget: {
        marginBottom: theme.spacing.unit * 3,
    },
    customers: {
        maxWidth: theme.breakpoints.values['md'],
    },
    warehouses: {
        maxWidth: theme.breakpoints.values['md'],
    }
});

const perPage = 10;

class Dashboard extends Component {

    state = {
        totalSpace: 0,
        freeSpace: 0,
        warehouses: [],
        page: 1,
        nbPages: 0
    };

    _isMounted = false;

    setPage = async (page) => {
        const { dataProvider } = this.props;
        const { data: warehouses } = await dataProvider(GET_LIST, `storageareas?limit=${perPage}&offset=${perPage * (page - 1)}&filter[level]=1`, {});
        this.setState({ warehouses, page });
    }

    componentDidMount = async () => {
        const { dataProvider } = this.props;
        this._isMounted = true;

        const { data: warehouses, total } = await dataProvider(GET_LIST, `storageareas?limit=${perPage}&offset=0&filter[level]=1`, {});

        const nbPages = Math.ceil(total / perPage) || 1;

        if (this._isMounted) {
            //to doo
            const [totalSpace, freeSpace] = warehouses.reduce((
                [accumulatorTotalSpace, accumulatorFreeSpace],
                { total_area, free_area },
            ) => [
                accumulatorTotalSpace + total_area,
                accumulatorFreeSpace + free_area,
            ], [0, 0]);

            this.setState({
                totalSpace,
                freeSpace,
                warehouses,
                nbPages
            });
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { classes } = this.props;
        const { totalSpace, freeSpace, warehouses, page, nbPages } = this.state;

        return (
            <div>
                <StorageSpace total={totalSpace} free={freeSpace} className={classes.widget} />
                <Customers className={`${classes.widget} ${classes.customers}`} />
                <Warehouses warehouses={warehouses} className={`${classes.widget} ${classes.warehouses}`} page={page} nbPages={nbPages} setPage={this.setPage}/>
            </div>
        );
    }

}

const enhance = compose(
    withStyles(styles),
    withDataProvider,
);

export default enhance(Dashboard);
