import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { SimpleForm, Toolbar } from 'react-admin';

import { ToolbarContainer } from '../components';
import generalStyles from '../styles';
import { ROLE_COLLECTOR_GUY, ROLE_CUSTOMER, ROLE_WAREHOUSE_MANAGER } from '../users/roles';

import ProfileFormFields from './ProfileFormFields';

const styles = theme => ({
    container: {
        width: '100%',
        maxWidth: theme.breakpoints.values['lg'],
    },
    spacer: generalStyles(theme).spacer,
});

class ProfileForm extends Component {
    constructor(props) {
        super(props);
        const {
            record: { avatar },
        } = this.props;
        this.avatar = avatar;
    }

    handleChangeAvatar = avatar => {
        this.avatar = avatar;
    };

    handleSave = ({ different_invoice_address, ...user }, redirect) => {
        const { save } = this.props;

        if (user.storagearea && user.storagearea.id) {
            user.storagearea = {
                id: user.storagearea.id,
            };
        }

        user.photo = this.avatar;

        if (!different_invoice_address) {
            user.invoice_address = null;
        }

        save(user, redirect);

        const userOfLocalStorage = JSON.parse(localStorage.getItem('user'));
        userOfLocalStorage.photo = user.photo;
        localStorage.setItem('user', JSON.stringify(userOfLocalStorage));
    };

    render() {
        const { classes, permissions, record, ...props } = this.props;

        return (
            <SimpleForm
                {...props}
                redirect={record.id ? false : 'edit'}
                record={record}
                toolbar={
                    <Toolbar classes={{ spacer: classes.spacer }}>
                        <ToolbarContainer maxWidth="lg"
                                          isShowToListButton={
                                              permissions !== ROLE_CUSTOMER
                                              && permissions !== ROLE_WAREHOUSE_MANAGER
                                              && permissions !== ROLE_COLLECTOR_GUY
                                          }
                        />
                    </Toolbar>
                }
                save={this.handleSave}
            >
                <ProfileFormFields className={classes.container}
                                   permissions={permissions}
                                   onChangeAvatar={this.handleChangeAvatar}
                />
            </SimpleForm>
        );
    }
}

export default withStyles(styles)(ProfileForm);
