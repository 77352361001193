import englishMessages from 'ra-language-english';
import { CARTON_TYPE, OBJECT_TYPE, PALLET_TYPE } from '../articles/articleTypes';

export default {
    ...englishMessages,
    ra: {
        validation: {
            required: 'Field "%{field}" can not be blank',
            required_of: 'Field "%{field}" of "%{target}" can not be blank',
            password_confirmation: 'Please check that you\'ve entered and confirmed your password',
            required_for_files: 'This field can not be blank',
            is_valid_password: 'At least 6 characters, at least one uppercase letter, one lowercase letter and one number',
        },
        notification: {
            upload_file_error: 'Failed to load some files',
            outsourced: 'Outsourced',
            removed: 'Removed',
            exchanged: 'Exchanged',
            warning: 'Something went wrong!',
        },
        chip: {
            deleted: 'Deleted',
        },
        action: {
            add_and_print: 'Add and print',
            add_subsection: 'Add subsection',
            add_color: 'Add color',
            restore_password: 'Restore password',
            print: 'Print',
            print_all: 'Print all',
            reply: 'Reply',
            send: 'Send',
            print_selected: 'Print selected',
            delete_selected: 'Delete selected',
            next: 'Next',
            done: 'Done',
            select_all: 'Select all',
            unselect_all: 'Unselect all',
            transfer: 'Transfer',
            create: 'Create',
            add: 'Add',
            save: 'Save',
            list: 'List',
            delete: 'Delete',
            confirm: 'Confirm',
            cancel: 'Cancel',
            edit: 'Edit',
            add_to_cart: 'Add to cart',
            remove_from_button: 'Remove from cart',
            deliver: 'Deliver',
            deliver_all: 'Deliver all',
            order: 'Order',
            order_now: 'Order Now',
            order_all: 'Order All',
            other: 'Other',
            choice_address: 'Choice address',
            delivery: 'Delivery',
            disposal: 'Disposal',
            approve: 'Approve',
            outsource: 'Outsource',
            revert: 'Revert',
            pickup: 'Pickup',
            hide_articles: 'Hide articles',
            show_articles: 'Show articles',
            restore: 'Restore',
        },
        auth: {
            forgot_password: 'Forgot password?',
            email: 'Email',
        },
        message: {
            to_list: 'Are you sure?',
            to_list_content: 'Are you sure you want to leave this page? You will lose any unsaved data.',
            error_add_barcode_to_article: 'Another article has this QR code.',
            remove_file: 'Are you sure you want to delete this file?',
            cancel_content: 'Are you sure you want to cancel this %{name}.',
            cancel_title: 'Cancel %{name} #%{id}',
            equal_barcodes: 'Scanned QR codes must match the QR codes of the article',
            restore_title: 'Restore',
            restore_content: 'Are you sure you want to restore this user?',
            error_long_name: 'Warehouse name must be less than 255 characters',
            error_empty_name: 'Warehouse name must not be empty',
        },
    },
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        profile: 'Profile',
        language: 'Language',
        default_country: 'Default country',
        add_address: 'Add address',
        file: 'File',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            welcome: {
                title: 'Welcome to Warehouse',
                subtitle: 'This is the admin panel of warehouse management for freight forwarding goods, ' +
                    'which are stored by forwarding companies for other companies',
                aor_button: 'react-admin site',
                demo_button: 'Source for this demo',
            },
            storage_space: {
                title: 'Storage space',
                total_space: 'Total space',
                free_space: 'Free space',
            },
            warehouses: {
                title: 'Warehouses',
                total_area: 'Total space',
                free_area: 'Free space',
            },
            customers: {
                title: 'upcoming deliveries',
            },
        },
    },
    resources: {
        general: {
            fields: {
                name: 'Name',
                updated_at: 'Last updated',
                created_at: 'Created at',
                search: 'Search',
                transfer_tools: 'Transfer tools',
            },
            data: {
                set_measurements: 'Set measurements to project #%{project_id}',
            }
        },
        customers: {
            name: 'Customer |||| Customers',
            fields: {
                subsidiary: 'Subsidiary of',
                address: 'Address',
                different_invoice_address: 'Different invoice address',
                invoice_address: 'Invoice address',
                name: 'Name',
                user: 'User',
            },
            page: {
                delete: 'Delete customers',
            },
            action: {
                add_loading_point: 'Add loading point',
                apply_customer_address: 'Apply customer address',
            },
        },
        address: {
            fields: {
                company: 'Company',
                firstname: 'First name',
                surname: 'Surname',
                street: 'Street',
                house_number: 'House number',
                postal_code: 'Postal code',
                city: 'City',
                province: 'Province',
                country: 'Country',
                additional_information: 'Additional information',
            },
            placeholders: {
                company: 'Sample Inc.',
                firstname: 'John',
                surname: 'Doe',
                street: 'Sample Street',
                house_number: '1a',
                postal_code: 'CA 95014',
                city: 'Cupertino',
                province: 'California',
                additional_information: '2nd floor',
            },
        },
        projects: {
            name: 'Project |||| Projects',
            fields: {
                customer: 'Customer',
                loading_points: 'Loading points',
                unloading_points: 'Unloading points',
                order_number: 'Order number',
                document_urls: 'Documents',
                comment: 'Comment',
                articles_number: 'Articles number',
                barcodes: 'QR codes',
                unused_barcodes: 'Unused QR codes',
                used_barcodes: 'Used QR codes',
                measurements: 'Measurements',
                area: 'Area',
                warehouse_place_area: 'Last edited Warehouse place',
                total_area: 'Total area',
                articles: 'Articles',
                id: 'Project id',
            },
            articleinfos: {
                of: 'of',
                scanned: 'scanned',
            },
        },
        barcodes: {
            name: 'QR code |||| QR codes',
            fields: {
                project_id: 'Project ID',
                barcodes_number: 'QR codes number',
            },
            errors: {
                delete_selected: 'Some QR codes did not go away',
            },
            action: {
                add_barcodes: 'Add QR codes',
            },
        },
        warehouses: {
            name: 'Warehouse |||| Warehouses',
            data: {
                structure: 'Structure',
            },
            action: {
                add_subsection: 'Add subsection for "%{name}"',
                edit: 'Edit section "%{name}"',
            },
            fields: {
                name: 'Warehouse Name',
            }
        },
        storageareas: {
            fields: {
                name: 'Name',
                quantity: 'Quantity',
                area: 'Area',
                first: 'First',
                length: 'Depth',
                width: 'Width',
                total_area: 'Total space',
                filled_area: 'Filled space',
                free_area: 'Free space',
            },
        },
        users: {
            name: 'User |||| Users',
            fields: {
                firstname: 'First name',
                phone_number: 'Phone number',
                mobile_phone_number: 'Mobile phone number',
                surname: 'Surname',
                gender: 'Gender',
                language: 'Language',
                email: 'Email',
                username: 'Username',
                role: 'Role',
                plain_password: 'Password',
                confirm_password: 'Confirm password',
                enabled: 'Enabled',
                created_by: 'Created by',
                agent: 'Agent',
                'agent.id': 'Agent',
            },
        },
        articles: {
            name: 'Article |||| Articles',
            check_free_space_error: 'Greater than free area',
            integer_error: 'Only integer',
            fields: {
                name: 'Description',
                photos: 'Photos',
                photo: 'Photo',
                comments: 'Comments',
                type: 'Type',
                barcodes: 'QR codes',
                barcodes_ids: 'QR codes',
                other_type: 'Other type',
                is_scratch: 'Damages',
                colors: 'Colors',
                manufacturer: 'Manufacturer',
                category: 'Category',
                category_name: 'Category name',
                damages: 'Damages',
                damages_photos: 'Damages photos',                
                warehouse_place: 'Warehouse place',
                width: 'Width',
                height: 'Height',
                length: 'Depth',
                project: 'Project'
            },
            action: {
                add_other_category: 'Add other category',
                scan_barcode: 'SCAN QR-CODE',
            },
            data: {
                add_category: 'Add category',
                created_category: 'Category successfully created'
            },
            validations: {
                count_photos: 'You must specify at least one photo.',
            },
            type: {
                [CARTON_TYPE]: 'Carton',
                [PALLET_TYPE]: 'Pallet',
                [OBJECT_TYPE]: 'Object',
            }
        },
        'article-categories': {
            name: 'Article category |||| Article categories',
            fields: {
                name: 'Name',
            },
        },
        agents: {
            name: 'Agent |||| Agents',
            fields: {
                address: 'Address',
                user: 'User',
            },
        },
        project_areas: {
            fields: {
                area: 'Area'
            },
        },
        delivery: {
            fields: {
                address: 'Address',
                date: 'Date',
                recipient: 'Recipient',
                type: 'Type',
            },
        },
        deliveries: {
            multiply_name: 'Deliveries',
            name: 'Delivery |||| Deliveries',
            fields: {
                address: 'Address',
                date: 'Date',
                surname: 'Surname',
            },
            actions: {
                remove_article: 'Remove',
                exchange_article: 'Exchange',
            },
            messages: {
                remove_article: 'Remove article #%{articleId} from delivery #%{deliveryId}',
                remove_article_content: 'Are you sure you want to remove article #%{articleId} from delivery #%{deliveryId}?',
            },
        },
        outsourcing: {
            name: 'Outsourcing',
            fields: {
                recipient: 'Recipient',
                address: 'Address',
                date: 'Date',
                type: 'Type'
            },
            type: {
                pickup: 'Pick up',
                delivery: 'Delivery',
                disposal: 'Disposal',
            }
        },
        shopping_cart: {
            name: 'Shopping cart',
        },
        acceptance: {
            fields: {
                scanned_barcodes: 'Scanned QR codes',
                articles: 'Articles',
            },
        },
    },
    resetting: {
        check_email: 'An email has been sent. It contains a link you must click to reset your password. ' +
            'You can only request a new password once within %{token_lifetime}. ' +
            'If you don\'t get an email check your spam folder or try again.',
    },
    qr_reader: {
        open: 'SCAN QR-CODE',
        title: 'QR-Code Reader',
        webcam_not_supported: 'Webcam not supported',
        submit_an_image: 'Submit an Image',
        decoded: 'Decoded QR-Code',
    },
    comment: {
        name: 'Comment |||| Comments',
        action: {
            send: 'Send comment',
        },
    },
    barcode: {
        value: 'Value',
    },
    time: {
        second: 'second |||| seconds',
        minute: 'minute |||| minutes',
        hour: 'hour |||| hours',
        day: 'day |||| days',
        week: 'week |||| weeks',
        month: 'month |||| months',
        year: 'year |||| years',
    },
    export: {
        from: 'Date from',
        to: 'Date to',
        head: 'Export project\'s records',
    },
    countries: {
        AF: 'Afghanistan',
        AX: '\u00c5land Islands',
        AL: 'Albania',
        DZ: 'Algeria',
        AS: 'American Samoa',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctica',
        AG: 'Antigua and Barbuda',
        AR: 'Argentina',
        AM: 'Armenia',
        AW: 'Aruba',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbaijan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BE: 'Belgium',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivia',
        BQ: 'Bonaire, Sint Eustatius and Saba',
        BA: 'Bosnia and Herzegovina',
        BW: 'Botswana',
        BV: 'Bouvet Island',
        BR: 'Brazil',
        IO: 'British Indian Ocean Territory',
        BN: 'Brunei Darussalam',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        CV: 'Cape Verde',
        KY: 'Cayman Islands',
        CF: 'Central African Republic',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CX: 'Christmas Island',
        CC: 'Cocos (keeling) Islands',
        CO: 'Colombia',
        KM: 'Comoros',
        CG: 'Congo',
        CD: 'Democratic Republic of the Congo',
        CK: 'Cook Islands',
        CR: 'Costa Rica',
        CI: 'Côte D\'Ivoire',
        HR: 'Croatia',
        CU: 'Cuba',
        CW: 'Curaçao',
        CY: 'Cyprus',
        CZ: 'Czech Republic',
        DK: 'Denmark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        ET: 'Ethiopia',
        FK: 'Falkland Islands',
        FO: 'Faroe Islands',
        FJ: 'Fiji',
        FI: 'Finland',
        FR: 'France',
        GF: 'French Guiana',
        PF: 'French Polynesia',
        TF: 'French Southern Territories',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        DE: 'Germany',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Greece',
        GL: 'Greenland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Guatemala',
        GG: 'Guernsey',
        GN: 'Guinea',
        GW: 'Guinea-bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HM: 'Heard Island and Mcdonald Islands',
        VA: 'Holy See (vatican City State)',
        HN: 'Honduras',
        HK: 'Hong Kong',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran, Islamic Republic of',
        IQ: 'Iraq',
        IE: 'Ireland',
        IM: 'Isle of Man',
        IL: 'Israel',
        IT: 'Italy',
        JM: 'Jamaica',
        JP: 'Japan',
        JE: 'Jersey',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KP: 'Korea, Democratic People\'s Republic of',
        KR: 'Korea, Republic of',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: 'Lao People\'s Democratic Republic',
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        MO: 'Macao',
        MK: 'Macedonia, The Former Yugoslav Republic of',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Mexico',
        FM: 'Micronesia, Federated States of',
        MD: 'Moldova, Republic of',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Netherlands',
        NC: 'New Caledonia',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolk Island',
        MP: 'Northern Mariana Islands',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PS: 'Palestine, State of',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PN: 'Pitcairn',
        PL: 'Poland',
        PT: 'Portugal',
        PR: 'Puerto Rico',
        QA: 'Qatar',
        RE: 'Réunion',
        RO: 'Romania',
        RU: 'Russian Federation',
        RW: 'Rwanda',
        BL: 'Saint Barthélemy',
        SH: 'Saint Helena, Ascension and Tristan Da Cunha',
        KN: 'Saint Kitts and Nevis',
        LC: 'Saint Lucia',
        MF: 'Saint Martin (French Part)',
        PM: 'Saint Pierre and Miquelon',
        VC: 'Saint Vincent and The Grenadines',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'Sao Tome and Principe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SX: 'Sint Maarten (Dutch Part)',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        GS: 'South Georgia and The South Sandwich Islands',
        SS: 'South Sudan',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SJ: 'Svalbard and Jan Mayen',
        SZ: 'Swaziland',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syrian Arab Republic',
        TW: 'Taiwan, Province of China',
        TJ: 'Tajikistan',
        TZ: 'United Republic of Tanzania',
        TH: 'Thailand',
        TL: 'Timor-leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad and Tobago',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TC: 'Turks and Caicos Islands',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'United Kingdom',
        US: 'United States',
        UM: 'United States Minor Outlying Islands',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela, Bolivarian Republic of',
        VN: 'Viet Nam',
        VG: 'Virgin Islands, British',
        VI: 'Virgin Islands, U.s.',
        WF: 'Wallis and Futuna',
        EH: 'Western Sahara',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe',
    },
};
