import React, { Component } from 'react';
import { Edit } from 'react-admin';

import ArticleTitle from './ArticleTitle';
import ArticleForm from './ArticleForm';

class ArticleEdit extends Component {

    componentDidMount() {
        if (localStorage.getItem('is_create') !== null) {
            localStorage.removeItem('is_next');
            localStorage.removeItem('is_create');
        }
        localStorage.setItem('is_edit', 'is_edit');
    }

    render() {
        const { permissions, location: { search }, ...props } = this.props;

        const searchParams = new URLSearchParams(search);
        const barcodeId = searchParams.get('barcode') ? parseInt(searchParams.get('barcode')) : undefined;

        return (
            <Edit title={<ArticleTitle />} actions={null} undoable={false} {...props}>
                <ArticleForm permissions={permissions} barcodeId={barcodeId} />
            </Edit>
        );
    }

}

export default ArticleEdit;
