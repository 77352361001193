import { debounce } from 'lodash';
import { withStyles } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { NumberInput, TextInput, minValue } from 'react-admin';

import { Measurement } from '../components';
import { guttersContainer, marginGutters } from '../helpers';
import { required } from '../validations';

const styles = theme => {
    const gutters = marginGutters(theme);
    const container = guttersContainer(theme);
    const upSmBreakpoint = theme.breakpoints.up('sm');

    const guttersSize = gutters.marginLeft + gutters.marginRight;
    const upSmGuttersSize = gutters[upSmBreakpoint].marginLeft + gutters[upSmBreakpoint].marginRight;

    return {
        container: {
            ...container,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        input: {
            ...gutters,
            flex: `1 0 calc(100% - ${guttersSize}px)`,
            [upSmBreakpoint]: {
                ...gutters[upSmBreakpoint],
                flexBasis: `calc(50% - ${upSmGuttersSize}px)`,
            },
        },
        fullWidth: {
            flexBasis: `calc(100% - ${guttersSize}px)`,
            [upSmBreakpoint]: {
                flexBasis: `calc(100% - ${upSmGuttersSize}px)`,
            },
        },
    };
};

class UnconnectedFieldsSection extends Component {

    state = {
        quantity: 1,
    };

    constructor(props) {
        super(props);

        this.handleQuantityChange = debounce(this.handleQuantityChange, 200);
    }

    handleQuantityChange = (event, quantity) => {
        this.setState({ quantity });
    };

    render() {
        const { classes, isShowAddedFields, onChangeName, ...props } = this.props;
        const { quantity } = this.state;

        return (
            <div className={classes.container}>
                <TextInput {...props}
                           className={`${classes.input} ${classes.fullWidth}`}
                           onChange={onChangeName}
                           source="name"
                           validate={required('storageareas', 'name')}
                />
                {isShowAddedFields && <Fragment>
                    <NumberInput className={classes.input}
                                 defaultValue={1}
                                 inputProps={{
                                     min: 1,
                                 }}
                                 source="quantity"
                                 validate={[
                                     required('storageareas', 'quantity'),
                                     minValue(1),
                                 ]}
                                 onChange={this.handleQuantityChange}
                    />
                    <NumberInput className={classes.input}
                                 defaultValue={1}
                                 inputProps={{
                                     min: 1,
                                 }}
                                 source="first"
                                 validate={[
                                     required('storageareas', 'first'),
                                     minValue(1),
                                 ]}
                                 disabled={quantity === 1}
                    />
                </Fragment>}
                <Measurement source="area"
                             className={`${classes.input} ${classes.fullWidth}`}
                             placeholder="1000"
                             validate={minValue(0)}
                             measure="m²"
                             defaultValue={0}
                />
            </div>
        );
    }

}

const FieldsSection = withStyles(styles)(UnconnectedFieldsSection);

FieldsSection.defaultProps = {
    isShowAddedFields: true,
    onChangeName: () => '',
};

export default FieldsSection;
