import React from 'react';
import { Edit } from 'react-admin';

import WarehouseForm from './WarehouseForm';

const WarehouseTitle = ({ record }) => (
    <span>
        #{record.id}. {record.name}
    </span>
);

const WarehouseEdit = props => (
    <Edit title={<WarehouseTitle />} actions={null} undoable={false} {...props}>
        <WarehouseForm />
    </Edit>
);

export default WarehouseEdit;
