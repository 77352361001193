import red from '@material-ui/core/colors/red';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const overrides = {
    MuiDialog: {
        paper: {
            marginLeft: 24,
            marginRight: 24,

            [breakpoints.down('xs')]: {
                width: 'calc(100% - 48px)',
            },
        },
    },
    MuiDialogContent: {
        root: {
            paddingBottom: 0,
        },
    },
    MuiDialogContentText: {
        root: {
            [breakpoints.down('xs')]: {
                minWidth: '200px!important',
            },
        },
    },
    MuiChip: {
        root: {
            height: 'auto',
            lineHeight: '24px',
            paddingTop: 4,
            paddingBottom: 4,
        },
        label: {
            whiteSpace: 'initial',
        },
    },
    MuiTableCell: {
        root: {
            '&[colspan]': {
                paddingTop: 0,
                paddingRight: 0,
                paddingBottom: 0,
            },
        },
    },
};

export const darkTheme = {
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.
        primary: {
            light: '#75c3fb',
            main: '#1da4f2',
            dark: '#008cf0',
            contrastText: '#fff',
            special: '#fff',
            toolbar: '#5d5d5d',   
        },
    },
    overrides,
};

export const lightTheme = {
    palette: {
        primary: {
            light: '#75c3fb',
            main: '#1da4f2',
            dark: '#008cf0',
            contrastText: '#fff',
            special: '#000',
            toolbar: 'rgb(234, 246, 254)',
            scanned: '#75c3fb36',
            notScanned: '#5d5d5d27',
            outsourced: '#5d5d5d27',
            barcodeHere: 'rgba(0, 0, 0, 0.07)',
        },
        secondary: {
            light: red.A100,
            main: red.A400,
            dark: red.A700,
            contrastText: '#fff',
        },
    },
    overrides,
};
