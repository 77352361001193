import React from 'react';
import { Filter, translate } from 'react-admin';

import SearchInput from './SearchInput';

const UnconnectedListFilter = ({ classes, label, translate, ...props }) => (
    <Filter {...props}>
        <SearchInput source="q" label={label} alwaysOn autoFocus placeholder={translate("resources.general.fields.search")} />
    </Filter>
);

const ListFilter = translate(UnconnectedListFilter);

ListFilter.defaultProps = {
    label: 'resources.general.fields.search',
};

export default ListFilter;
