import React from 'react';
import { Layout, Sidebar } from 'react-admin';
import { connect } from 'react-redux';

import AppBar from './AppBar';
import Menu from './Menu';
import { lightTheme, darkTheme } from './themes';
import { SIDEBAR_SIZE } from '../helpers';

const CustomSidebar = props => <Sidebar {...props} size={SIDEBAR_SIZE} />;

const CustomLayout = props => (
    <Layout {...props} appBar={AppBar} sidebar={CustomSidebar} menu={Menu} />
);

export default React.memo(connect(
    state => ({
        theme: state.theme === 'dark' ? darkTheme : lightTheme,
    }),
    {},
)(CustomLayout));
