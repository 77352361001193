import { Remove as RemoveIcon } from "@material-ui/icons";
import React, { Component } from "react";
import { translate } from "react-admin";
import { ConfirmButton } from "../components";
import { OUTSOURCED } from "../articles/statuses";

class RemoveArticleButton extends Component {
  render() {
    const {
      className,
      translate,
      deliveryId,
      record: { id: articleId, barcodes, status },
      handleConfirmRemove,
      scannedBarcodes
    } = this.props;

    return (
      <ConfirmButton
        label="resources.deliveries.actions.remove_article"
        onConfirm={() => handleConfirmRemove(deliveryId, articleId)}
        className={className}
        icon={<RemoveIcon />}
        title="resources.deliveries.messages.remove_article"
        translateOptions={{
          articleId,
          deliveryId
        }}
        variant="text"
        disabled={
          !barcodes.filter(({ id }) => !scannedBarcodes.includes(id)).length ||
          status === OUTSOURCED
        }
      >
        {translate("resources.deliveries.messages.remove_article_content", {
          articleId,
          deliveryId
        })}
      </ConfirmButton>
    );
  }
}

export default translate(RemoveArticleButton);
