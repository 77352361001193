import { withStyles } from '@material-ui/core';
import React from 'react';
import { List, NumberField, Responsive, TextField, translate } from 'react-admin';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import {
    Datagrid,
    ListActions as WarehouseActions,
    ListFilter as WarehouseFilter,
    UpdatedAtField,
} from '../components';
import styles from '../styles';
import { ROLE_WAREHOUSE_MANAGER, ROLE_OFFICE_EMPLOYEE, ROLE_AGENT } from '../users/roles';

import MobileGrid from './MobileGrid';
import WarehouseCreateForm from './WarehouseCreateForm';

const optionsNumberFormat = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
};

const WarehouseList = ({ dispatch, classes, locale, permissions, translate, ...props }) => (
    <>
        {permissions === ROLE_AGENT && <WarehouseCreateForm />}
        <List {...props}
            filters={<WarehouseFilter />}
            filter={{ level: 1 }}
            perPage={25}
            bulkActionButtons={false}
            exporter={false}
        >
            <Responsive
                medium={<MobileGrid permissions={permissions} />}
                large={
                    <Datagrid>
                        <TextField source="name" />
                        <NumberField source="total_area"
                            label={translate('resources.storageareas.fields.total_area') + ', m²'}
                            headerClassName={classes.minWidth}
                            className={classes.nowrap}
                            locales={locale}
                            options={optionsNumberFormat}
                            sortable={false}
                        />
                        <NumberField source="filled_area"
                            label={translate('resources.storageareas.fields.filled_area') + ', m²'}
                            headerClassName={classes.minWidth}
                            className={classes.nowrap}
                            locales={locale}
                            options={optionsNumberFormat}
                            sortable={false}
                        />

                        <UpdatedAtField sortBy="updatedAt" headerClassName={classes.minWidth} />
                        <WarehouseActions headerClassName={classes.minWidth}
                            deletable={permissions !== ROLE_WAREHOUSE_MANAGER && permissions !== ROLE_OFFICE_EMPLOYEE}
                            editable={permissions !== ROLE_WAREHOUSE_MANAGER && permissions !== ROLE_OFFICE_EMPLOYEE}
                            isShow={permissions === ROLE_WAREHOUSE_MANAGER || permissions === ROLE_OFFICE_EMPLOYEE}
                        />
                    </Datagrid>
                }
            />
        </List>
    </>
);

const mapStateToProps = state => ({
    locale: state.i18n.locale,
});

const enhance = compose(
    withStyles(styles),
    translate,
    connect(
        mapStateToProps,
        null,
    ),
);

export default enhance(WarehouseList);
