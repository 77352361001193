import { isEqual, isString } from 'lodash';
import memoize from './memoize';

export default memoize(message =>
    (revertedBarcodes, { barcodes }, { translate }) => {
        if (isString(revertedBarcodes)) {
            revertedBarcodes = revertedBarcodes.split(',');
        }
        return  isEqual(revertedBarcodes, barcodes.map(({ id }) => id)) ? undefined : translate(message)
    });
