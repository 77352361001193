import { LocalShipping as DeliveryIcon } from '@material-ui/icons';

import DeliveryList from './DeliveryList';
import DeliveryEdit from './DeliveryEdit';
import DeliveryShow from './DeliveryShow';

export default {
    list: DeliveryList,
    edit: DeliveryEdit,
    show: DeliveryShow,
    icon: DeliveryIcon,
};
