import { isEqual, range } from 'lodash';
import { Card, CardContent, CardHeader, Chip, withStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { Component } from 'react';
import { Button, Responsive, TextField, translate } from 'react-admin';
import { compose } from 'recompose';

import { AddressField, Datagrid, FullNameField } from '../components';
import { nextUniqueKey } from '../helpers';

import LoadingPointsListActions from './LoadingPointsListActions';

const DeleteButton = ({ onClick, record }) => (
    <Button color="secondary" label="ra.action.delete" onClick={() => onClick(record)}>
        <Delete />
    </Button>
);

const styles = theme => ({
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    root: {
        maxWidth: theme.breakpoints.values['lg'],
    },
    nowrap: {
        whiteSpace: 'nowrap',
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
    },
});

class UnconnectedLoadingPoints extends Component {

    handleAddAddress = newLoadingPoint => {
        const { onChange, loadingPoints } = this.props;
        const { id, created_at, updated_at, ...newLoadingPointAddress } = newLoadingPoint.address;

        const isExist = loadingPoints.some(({ address: { id, created_at, updated_at, ...loadingPointAddress } }) =>
            isEqual(loadingPointAddress, newLoadingPointAddress));

        if (!isExist) {
            loadingPoints.push(newLoadingPoint);

            onChange(loadingPoints);
        }
    };

    handleAddCustomerAddress = () => {
        const { customer } = this.props;
        const { id, ...address } = customer.address;

        this.handleAddAddress({ address });
    };

    handleRemoveAddress = (loadingPoint, index) => {
        const { onChange, loadingPoints } = this.props;

        if (!index) {
            index = loadingPoints.indexOf(loadingPoint);
        }

        loadingPoints.splice(index, 1);

        onChange(loadingPoints);
    };

    render() {
        const { classes, translate, title, customer, loadingPoints, disabled } = this.props;

        return (
            <Card className={classes.root}>
                <CardHeader title={
                    <div className={classes.header}>
                        {translate(title)}
                        <LoadingPointsListActions
                            inHeader
                            loadingPoints={loadingPoints}
                            disabled={disabled}
                            customer={customer}
                            handleAddCustomerAddress={this.handleAddCustomerAddress}
                            handleAddAddress={this.handleAddAddress}
                        />
                    </div>
                } />
                <CardContent className={classes.content}>
                    <Responsive
                        small={
                            <div>
                                {loadingPoints.map((loadingPoint, index) => (
                                    <Chip key={nextUniqueKey()}
                                        label={<AddressField record={loadingPoint} />}
                                        onDelete={disabled ?
                                            undefined : () => this.handleRemoveAddress(loadingPoint, index)}
                                    />
                                ))}
                            </div>
                        }
                        medium={
                            <Datagrid data={{ ...loadingPoints }}
                                ids={range(loadingPoints.length)}
                                currentSort={{
                                    field: '',
                                }}
                            >
                                <FullNameField source="address" />
                                <TextField source="address.company"
                                    label="resources.address.fields.company"
                                    className={classes.nowrap}
                                />
                                <AddressField showName={false} />

                                {!disabled && <DeleteButton onClick={this.handleRemoveAddress} />}
                            </Datagrid>
                        }
                    />
                </CardContent>
                <LoadingPointsListActions
                    loadingPoints={loadingPoints}
                    disabled={disabled}
                    customer={customer}
                    handleAddCustomerAddress={this.handleAddCustomerAddress}
                    handleAddAddress={this.handleAddAddress}
                />
            </Card>
        );
    }

}

const enhance = compose(
    translate,
    withStyles(styles),
);

const LoadingPoints = enhance(UnconnectedLoadingPoints);

LoadingPoints.defaultProps = {
    title: 'resources.projects.fields.loading_points',
    onChange: () => {
    },
    disabled: false,
};

export default LoadingPoints;
