import { withStyles } from '@material-ui/core';
import React from 'react';
import { List, NumberField, Responsive, TextField } from 'react-admin';

import {
    Datagrid,
    ListActions as ArticleCategoryActions,
    ListFilter as ArticleCategoryFilter,
    UpdatedAtField,
} from '../components';
import styles from '../styles';

import MobileGrid from './MobileGrid';
import { ROLE_AGENT, ROLE_SUPER_ADMIN, ROLE_WAREHOUSE_MANAGER, ROLE_OFFICE_EMPLOYEE } from '../users/roles';

const ArticleCategoryList = ({ classes, permissions, ...props }) => (
    <List {...props}
          filters={<ArticleCategoryFilter />}
          perPage={25}
          bulkActionButtons={false}
          exporter={false}
    >
        <Responsive
            medium={<MobileGrid permissions={permissions} />}
            large={
                <Datagrid resource="article-categories">
                    <NumberField source="id" />
                    <TextField source="name" headerClassName={classes.fullWidth} />

                    <UpdatedAtField sortBy="updatedAt" headerClassName={classes.minWidth} />
                    {(permissions === ROLE_SUPER_ADMIN
                    || permissions === ROLE_AGENT
                    || permissions === ROLE_WAREHOUSE_MANAGER
                    || permissions === ROLE_OFFICE_EMPLOYEE) && (
                        <ArticleCategoryActions headerClassName={classes.minWidth} />
                    )}
                </Datagrid>
            }
        />
    </List>
);

export default withStyles(styles)(ArticleCategoryList);
