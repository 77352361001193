import { get } from 'lodash';
import { withStyles } from '@material-ui/core';
import React from 'react';

import styles from '../styles';

const UnconnectedFullNameField = ({ classes, record, source }) => {
    let value = get(record, source);
    if (!value) {
        value = record;
    }

    return (
        <span className={classes.nowrap}>
            {value.firstname} {value.surname}
        </span>
    );
};

const FullNameField = withStyles(styles)(UnconnectedFullNameField);

FullNameField.defaultProps = {
    source: "user",
    label: 'resources.general.fields.name',
};

export default FullNameField;
