import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { LongTextInput, REDUX_FORM_NAME, maxLength, translate } from 'react-admin';
import { compose } from 'recompose';
import { change } from 'redux-form';

import styles from '../../styles';
import { memoize, required as baseRequired } from '../../validations';

import CountryAutocompleteInput from '../CountryAutocompleteInput';
import httpClient from '../../providers/httpClient';
import Autosuggest from '../Autosuggest';
import addressHelpers from '../../helpers/addressHelpers';
import { connect } from 'react-redux';

const notRequired = memoize(() =>
    Object.assign(() => undefined, { isRequired: false }));

const required = (disabled, resource, field) => {
    return disabled ?
        notRequired() : baseRequired(resource, field);
};

class UnconnectedAddressInput extends Component {

    fetchAddress = async (value, field) => {
        if (!value) {
            return;
        }

        const { json: address } = await httpClient(
            `${process.env.REACT_APP_API_URL}/address/${field}?q=${value}`,
            {
                method: 'GET',
            },
        );

        return address;
    };

    addressToString = address => {
        const { translate } = this.props;

        return addressHelpers({
            address,
            translate,
        });
    };

    handleChangeAddress = ({ suggestion: address }) => {
        const { formName, source, change, isShowName, isOtherAddress, setChangingAddress } = this.props;
        if (isShowName) {
            change(formName, `${source}.firstname`, address.firstname || '');
            change(formName, `${source}.surname`, address.surname || '');
        }
        if (isOtherAddress && setChangingAddress) {
            setChangingAddress(address);
            change(formName, `${source}.id`, address.id);
        }
        change(formName, `${source}.company`, address.company || '');
        change(formName, `${source}.province`, address.province || '');
        change(formName, `${source}.city`, address.city || '');
        change(formName, `${source}.postal_code`, address.postal_code || '');
        change(formName, `${source}.street`, address.street || '');
        change(formName, `${source}.house_number`, address.house_number || '');
    };

    render() {
        const { source, disabled, isShowName, classes, translate, isPopup } = this.props;

        const inputClasses = isPopup ? '' : classes.input;

        return (
            <div className={isPopup ? '' : classes.container}>
                {isShowName && 
                    <Autosuggest
                        fetchOptions={val => this.fetchAddress(val, 'firstname')}
                        label={translate('resources.address.fields.firstname')}
                        placeholder={translate('resources.address.placeholders.firstname')}
                        source={`${source}.firstname`}
                        className={inputClasses}
                        validate={maxLength(255)}
                        options={{
                            disabled: disabled,
                        }}
                        optionText={this.addressToString}
                        optionValue="firstname"
                        onChange={this.handleChangeAddress}
                    />
                }
                {isShowName && 
                    <Autosuggest
                        fetchOptions={val => this.fetchAddress(val, 'surname')}
                        label={translate('resources.address.fields.surname')}
                        placeholder={translate('resources.address.placeholders.surname')}
                        source={`${source}.surname`}
                        className={inputClasses}
                        validate={[
                            required(disabled, 'address', 'surname'),
                            maxLength(255),
                        ]}
                        options={{
                            disabled: disabled,
                        }}
                        optionText={this.addressToString}
                        optionValue="surname"
                        onChange={this.handleChangeAddress}
                    />
                }
                <Autosuggest
                    fetchOptions={val => this.fetchAddress(val, 'company')}
                    label={translate('resources.address.fields.company')}
                    placeholder={translate('resources.address.placeholders.company')}
                    source={`${source}.company`}
                    className={inputClasses}
                    validate={[
                        maxLength(255),
                    ]}
                    options={{
                        disabled: disabled,
                    }}
                    optionText={this.addressToString}
                    optionValue="company"
                    onChange={this.handleChangeAddress}
                />
                <CountryAutocompleteInput label="resources.address.fields.country"
                                          source={`${source}.country_code`}
                                          className={inputClasses}
                                          validate={[
                                              required(disabled, 'address', 'country'),
                                              maxLength(255),
                                          ]}
                                          disabled={disabled}
                />
                <Autosuggest
                    fetchOptions={val => this.fetchAddress(val, 'province')}
                    label={translate('resources.address.fields.province')}
                    placeholder={translate('resources.address.placeholders.province')}
                    source={`${source}.province`}
                    className={inputClasses}
                    validate={maxLength(255)}
                    options={{
                        disabled: disabled,
                    }}
                    optionText={this.addressToString}
                    optionValue="province"
                    onChange={this.handleChangeAddress}
                />
                <Autosuggest
                    fetchOptions={val => this.fetchAddress(val, 'city')}
                    label={translate('resources.address.fields.city')}
                    placeholder={translate('resources.address.placeholders.city')}
                    source={`${source}.city`}
                    className={inputClasses}
                    validate={[
                        required(disabled, 'address', 'city'),
                        maxLength(255),
                    ]}
                    options={{
                        disabled: disabled,
                    }}
                    optionText={this.addressToString}
                    optionValue="city"
                    onChange={this.handleChangeAddress}
                />
                <Autosuggest
                    fetchOptions={val => this.fetchAddress(val, 'postalcode')}
                    label={translate('resources.address.fields.postal_code')}
                    placeholder={translate('resources.address.placeholders.postal_code')}
                    source={`${source}.postal_code`}
                    className={inputClasses}
                    validate={[
                        required(disabled, 'address', 'postal_code'),
                        maxLength(10),
                    ]}
                    options={{
                        disabled: disabled,
                    }}
                    optionText={this.addressToString}
                    optionValue="postal_code"
                    onChange={this.handleChangeAddress}
                />
                <Autosuggest
                    fetchOptions={val => this.fetchAddress(val, 'street')}
                    label={translate('resources.address.fields.street')}
                    placeholder={translate('resources.address.placeholders.street')}
                    source={`${source}.street`}
                    className={inputClasses}
                    validate={[
                        required(disabled, 'address', 'street'),
                        maxLength(255),
                    ]}
                    options={{
                        disabled: disabled,
                    }}
                    optionText={this.addressToString}
                    optionValue="street"
                    onChange={this.handleChangeAddress}
                />
                <Autosuggest
                    fetchOptions={val => this.fetchAddress(val, 'housenumber')}
                    label={translate('resources.address.fields.house_number')}
                    placeholder={translate('resources.address.placeholders.house_number')}
                    source={`${source}.house_number`}
                    className={inputClasses}
                    validate={[
                        required(disabled, 'address', 'house_number'),
                        maxLength(10),
                    ]}
                    options={{
                        disabled: disabled,
                    }}
                    optionText={this.addressToString}
                    optionValue="house_number"
                    onChange={this.handleChangeAddress}
                />
                <LongTextInput label="resources.address.fields.additional_information"
                               source={`${source}.additional_information`}
                               className={inputClasses}
                               placeholder={translate('resources.address.placeholders.additional_information')}
                               validate={maxLength(255)}
                               disabled={disabled}
                />
            </div>
        );
    }

}

const enhanceAddressInput = compose(
    withStyles(styles),
    translate,
    connect(undefined, { change }),
);

const AddressInput = enhanceAddressInput(UnconnectedAddressInput);

AddressInput.defaultProps = {
    source: 'address',
    disabled: false,
    isShowName: true,
    formName: REDUX_FORM_NAME,
};

export default AddressInput;
