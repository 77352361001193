import React, { Component } from 'react';
import { Create, GET_LIST, withDataProvider } from 'react-admin';
import { push } from 'react-router-redux';

import ArticleForm from './ArticleForm';
import { CONFIRMED, OUTSOURCED } from './statuses';

const WrapperArticleForm = ({ record: { barcode, ...record }, ...props }) => (
    <ArticleForm record={record} {...props} />
);

class ArticleCreate extends Component {

    state = {
        isExistsArticle: true,
    };

    componentDidMount = async () => {
        const { dataProvider, dispatch, location: { search } } = this.props;
        const searchParams = new URLSearchParams(search);
        const barcodeId = parseInt(searchParams.get('barcode'));

        if (barcodeId) {
            const { data: articles } = await dataProvider(GET_LIST, 'articles', {
                queryParams: {
                    fromAll: 1,
                },
                filter: {
                    'barcodes.id': barcodeId,
                },
            });

            if (articles.length > 0) {
                const article = articles[0];

                if (article.status === CONFIRMED) {
                    dispatch(push(`/outsourcing?barcode=${barcodeId}`));
                } else if (article.status === OUTSOURCED) {
                    dispatch(push(`/articles/${article.id}?barcode=${barcodeId}`));
                } else {
                    dispatch(push(`/articles/${article.id}`));
                }
            } else {
                localStorage.setItem('is_create', 'is_create');
                this.setState({
                    isExistsArticle: false,
                    barcodeId,
                });
            }
        } else {
            if (localStorage.getItem('is_edit') === null) {
                localStorage.setItem('is_create', 'is_create');
            } else {
                localStorage.removeItem('is_edit');
            }
            this.setState({
                isExistsArticle: false,
            });
        }
    };

    render() {
        const { dataProvider, dispatch, permissions, ...props } = this.props;
        const { isExistsArticle, barcodeId } = this.state;

        return isExistsArticle ? null : (
            <Create {...props}>
                <WrapperArticleForm barcodeId={barcodeId}
                                    permissions={permissions}
                                    dataProvider={dataProvider}
                                    dispatch={dispatch}
                />
            </Create>
        );
    }

}

export default withDataProvider(ArticleCreate);
