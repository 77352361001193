import React from 'react';

const AreaField = ({ children, ...props }) => (
    <span {...props}>
        <span>{children}</span>
        <span>m²</span>
    </span>
);

export default AreaField;
