import { keyBy } from 'lodash';
import { withStyles } from '@material-ui/core';
import React from 'react';
import { DateField } from 'react-admin';

import { AddressField, Datagrid } from '../components';

const styles = {
    root: {
        marginLeft: 24,
        width: 'calc(100% - 24px)',
    },
    address: {
        width: '100%',
    },
    date: {
        whiteSpace: 'nowrap',
    },
};

const ProjectDeliveries = ({ classes, record: { deliveries } }) => (
    <Datagrid data={keyBy(deliveries, 'id')}
              ids={deliveries.map(({ id }) => id)}
              currentSort={{
                  field: '',
              }}
              className={classes.root}
    >
        <AddressField headerClassName={classes.address} sortable={false} />
        <DateField className={classes.date} showTime={true} source="date" />
    </Datagrid>
);

export default withStyles(styles)(ProjectDeliveries);
