import React, { Component } from 'react';
import { compose } from 'recompose';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { PhotoLibrary as GalleryIcon } from '@material-ui/icons';
import Close from '@material-ui/icons/Close';
import { Button } from 'react-admin';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import NavArrow from './NavArrow';

const styles = theme => ({
    arrow: {
        minWidth: theme.spacing.unit * 6,
        [theme.breakpoints.up('sm')]: {
            '&:first-child': {
                marginRight: theme.spacing.unit * 3
            },
            '&:last-child': {
                marginLeft: theme.spacing.unit * 3
            }
        },
    },
    lightBoxImage: {
        maxWidth: '390px',
        width: '390px',
        height: '500px',
        objectFit: 'contain',
        [theme.breakpoints.down('sm')]: {
            height: '300px',
            maxWidth: 'calc(100% - 98px)',
            width: 'initial'
        }
    },
    theOnlyImage: {
        maxWidth: '500px',
        [theme.breakpoints.down('sm')]: {
            height: '300px',
            maxWidth: '100%'
        }
    },
    content: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            justifyContent: 'center'
        }
    },
    imageField: {
        position: 'relative',
        '& img': {
            display: 'block',
            margin: 0,
            cursor: 'pointer',
            width: '100px',
            height: '100px',
            objectFit: 'cover',
            objectPosition: 'center'
        },
    },
    galleryIcon: {
        position: 'absolute',
        bottom: '4px',
        right: '4px',
        cursor: 'pointer',
        color: theme.palette.primary.contrastText
    }
})

class LightboxField extends Component {
    _isMounted = false;
    state = {
        currentImg: 0,
        opened: false
    }

    componentDidMount = () => {
        this._isMounted = true;
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    handleClose = () => {
        if (this._isMounted) {
            this.setState({
                opened: false
            })
        }
    }

    handleOpen = () => {
        if (this._isMounted) {
            this.setState({
                opened: true,
                currentImg: 0
            })
        }
    }

    handleClickNext = () => {
        if (this._isMounted) {
            const { currentImg } = this.state;
            this.setState({
                currentImg: currentImg + 1
            })
        }
    }

    handleClickBefore = () => {
        if (this._isMounted) {
            const { currentImg } = this.state;
            this.setState({
                currentImg: currentImg - 1
            })
        }
    }

    render() {
        const { record, source, classes } = this.props;
        const { currentImg, opened } = this.state;
        const images = get(record, source);

        if (images?.length === 0) return '';

        const theOnlyImage = images.length === 1;

        return (
            <>
                <div className={classes.imageField} onClick={this.handleOpen}>
                    <img src={images[0]} />
                    { !theOnlyImage ? <GalleryIcon className={classes.galleryIcon} /> : '' }
                </div>
                <Dialog
                    aria-labelledby="simple-dialog-title"
                    open={opened}
                    onClose={this.handleClose}
                >
                    <DialogContent className={classes.content}>
                        {
                            theOnlyImage
                                ? ''
                                : <NavArrow
                                    type="prev"
                                    onClick={this.handleClickBefore}
                                    disabled={currentImg === 0}
                                    className={classes.arrow}
                                />
                        }

                        <img src={images[currentImg]} className={theOnlyImage ? classes.theOnlyImage : classes.lightBoxImage} />
                        {
                            theOnlyImage
                                ? ''
                                : <NavArrow
                                    type="next"
                                    onClick={this.handleClickNext}
                                    disabled={currentImg === images.length - 1}
                                    className={classes.arrow}
                                />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} label='ra.action.close'>
                            <Close />
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

LightboxField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
}

LightboxField.defaultProps = {
    addLabel: true,
    record: {}
}

const enhance = compose(
    withStyles(styles)
)

export default enhance(LightboxField);