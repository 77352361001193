import { withStyles } from '@material-ui/core';
import React from 'react';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';

import { Logo as LogoIcon } from '../components';

const styles = {
    logo: {
        width: 130,
        height: 34,
    },

};

const Logo = ({ classes }) => (
    <Button component={Link} to="/" color="inherit">
        <LogoIcon classes={{ root: classes.logo }} />
    </Button>
);

export default React.memo(withStyles(styles)(Logo));
