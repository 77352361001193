import { keyBy } from "lodash";
import { withStyles } from "@material-ui/core";
import React from "react";
import { lightTheme } from "../layout/themes";
import ArticleDatagrid from "../articles/ArticleDatagrid";
import generalStyles from "../styles";
import RemoveArticleButton from "./RemoveArticleButton";
import ExchangeArticleButton from "./ExchangeArticleButton";
import { OUTSOURCED } from "../articles/statuses";

const styles = theme => {
  const { nowrap } = generalStyles(theme);

  return {
    nowrap,
    lastLine: {
      "& > tbody > tr:last-child > td": {
        border: "none"
      }
    }
  };
};

const ExpandDelivery = ({
  classes,
  permissions,
  record: { articles, id: deliveryId },
  handleConfirmRemove,
  scannedBarcodes,
  exchange
}) => {
  const scannedArticleRowStyle = ({ barcodes, status }) => {
    if (status === OUTSOURCED) {
      return {
        backgroundColor: lightTheme.palette.primary.outsourced
      };
    } else if (
      !barcodes.filter(({ id }) => !scannedBarcodes.includes(id)).length
    ) {
      return {
        backgroundColor: lightTheme.palette.primary.scanned
      };
    }
  };

  return (
    <ArticleDatagrid
      ids={articles.map(({ id }) => id)}
      data={keyBy(articles, "id")}
      currentSort={{
        field: "id",
        order: "DESC"
      }}
      rowStyle={scannedArticleRowStyle}
      basePath="deliveries"
      permissions={permissions}
      className={classes.lastLine}
      scannedBarcodes={scannedBarcodes}
      outsourcingPage={true}
      customActions={[
        <RemoveArticleButton
          key="1"
          className={classes.nowrap}
          deliveryId={deliveryId}
          handleConfirmRemove={handleConfirmRemove}
          scannedBarcodes={scannedBarcodes}
        />,
        <ExchangeArticleButton
          key="2"
          className={classes.nowrap}
          scannedBarcodes={scannedBarcodes}
          deliveryId={deliveryId}
          exchange={exchange}
        />
      ]}
    />
  );
};

export default withStyles(styles)(ExpandDelivery);
