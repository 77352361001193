import { get } from 'lodash';

export default async (id, childrenName, { source, onDelete }, state, setState) => {
    await onDelete(id);

    const newChildren = state[childrenName].filter(child => {
        const childId = get(child, `${source}.id`);

        return childId !== id;
    });

    if (newChildren.length !== state[childrenName].length) {
        setState({
            [childrenName]: newChildren,
        });
    }
}
