import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { BooleanInput, SimpleForm, Toolbar, translate, REDUX_FORM_NAME } from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { AddressCard, ToolbarContainer } from '../components';
import generalStyles from '../styles';
import { showAlertHelper } from '../helpers';

import UserCard from './UserCard';

const styles = theme => ({
    input: {
        marginTop: theme.spacing.unit * 2,
        [theme.breakpoints.up('xs')]: {
            marginTop: theme.spacing.unit * 3,
        },
    },
    spacer: generalStyles(theme).spacer,
});

class CustomerForm extends Component {

    constructor(props) {
        super(props);

        const { record } = this.props;

        this.state = {
            isExistsInvoiceAddress: !!record.invoice_address,
        };
    }

    componentDidUpdate() {
        showAlertHelper.saveDefaultState(this.props.initial);
        showAlertHelper.saveCurrentState(this.props.current);
    }

    handleChangeDifferentInvoiceAddress = (customer, checked) => {
        this.setState({
            isExistsInvoiceAddress: checked,
        });
    };

    handleSave = ({ different_invoice_address, ...customer }, redirect) => {
        const { save } = this.props;

        if (!different_invoice_address) {
            customer.invoice_address = null;
        }

        save(customer, redirect);
    };

    handleChangeRole = (role) => {
        this.setState({ role });
    };

    componentDidMount() {
        const { record: customer } = this.props;

        if (!isEmpty(customer)) {
            const { user: { role } } = customer;

            this.setState({ role });
        }
    }

    render() {
        const { classes, permissions, translate, record, ...props } = this.props;
        const { isExistsInvoiceAddress } = this.state;

        return (
            <SimpleForm {...props}
                redirect={record.id ? false : 'edit'}
                record={record}
                toolbar={
                    <Toolbar classes={{ spacer: classes.spacer }}>
                        <ToolbarContainer maxWidth="lg" />
                    </Toolbar>
                }
                save={this.handleSave}
            >
                <UserCard source="user"
                    cardClass={classes.input}
                    onChangeRole={this.handleChangeRole}
                    permissions={permissions}
                />
                <AddressCard cardClass={classes.input} isShowName={false} />
                <BooleanInput source="different_invoice_address"
                    className={classes.input}
                    onChange={this.handleChangeDifferentInvoiceAddress}
                    defaultValue={isExistsInvoiceAddress}
                />
                <AddressCard source="invoice_address" cardClass={classes.input} disabled={!isExistsInvoiceAddress} />
            </SimpleForm>
        );
    }

}

const mapStateToProps = state => ({
    initial: state.form[REDUX_FORM_NAME]?.initial,
    current: state.form[REDUX_FORM_NAME]?.values,
})

const enhance = compose(
    withStyles(styles),
    translate,
    connect(mapStateToProps),
);

export default enhance(CustomerForm);
