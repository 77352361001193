import { Card, CardContent, CardHeader, withStyles } from '@material-ui/core';
import React from 'react';
import { translate } from 'react-admin';
import { compose } from 'recompose';

import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Toolbar from '@material-ui/core/Toolbar';

import WarehousesDatagrid from './WarehousesDatagrid';

const styles = {
    content: {
        padding: 0,
        '& tr:last-child > td': {
            borderBottom: 'none',
        },
    },
    toolbar: {
        float: 'right'
    }
};

const UnconnectedWarehouses = ({ className, classes, warehouses, translate, page, nbPages, setPage }) => (
    <Card className={className}>
        <CardHeader title={translate('pos.dashboard.warehouses.title')} />
        <CardContent className={classes.content}>
            <WarehousesDatagrid warehouses={warehouses} />
            {nbPages > 1 &&
                <Toolbar className={classes.toolbar}>
                    {page > 1 &&
                        <Button color="primary" key="prev" onClick={() => setPage(page - 1)}>
                            <ChevronLeft />
                            Prev
                        </Button>
                    }
                    {page !== nbPages &&
                        <Button color="primary" key="next" onClick={() => setPage(page + 1)} labelPosition="before">
                            Next
                            <ChevronRight />
                        </Button>
                    }
                </Toolbar>
            }
        </CardContent>
    </Card>
);

const enhance = compose(
    withStyles(styles),
    translate,
);

const Warehouses = enhance(UnconnectedWarehouses);

Warehouses.defaultProps = {
    warehouses: [],
};

export default Warehouses;
