import React from 'react';
import { CardActions } from 'react-admin';

import ArticleList from './ArticleList';
import DeliveryButton from './DeliveryButton';
import RemoveFromCartButton from './RemoveFromCartButton';
import { IN_CART } from '../articles/statuses';

const ShoppingCartBulkActionButtons = props => (
    <>
        <DeliveryButton {...props} />
        <RemoveFromCartButton {...props} />
    </>
);

const ShoppingCartActions = props => (
    <CardActions>
        <DeliveryButton {...props} deliverAll />
    </CardActions>
);

const ShoppingCart = props => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (!user.id) return null;

    return (
        <ArticleList {...props}
            title="resources.shopping_cart.name"
            filter={{
                'status][in][': IN_CART,
                'updatedBy][in][': user.id,
            }}
            filters={{
                q: true,
            }}
            resource="articles"
            bulkActionButtons={<ShoppingCartBulkActionButtons />}
            customActions={<ShoppingCartBulkActionButtons />}
            actions={<ShoppingCartActions />}
        />
    )
};

export default ShoppingCart;
