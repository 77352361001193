import React from 'react';

import { FullNameField } from '../components';

const ProjectTitle = ({ record }) => (
    <span>
        #{record.id}. <FullNameField record={record.customer} />
        {
            record.customer.address && record.customer.address.company && (
                <span> ({record.customer.address.company})</span>
            )
        }
    </span>
);

export default ProjectTitle;
