import { isEqual } from 'lodash';
import { Typography, withStyles, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component, Fragment } from 'react';
import { withDataProvider, UPDATE } from 'react-admin';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { expandPanel } from '../AC';

import { nextUniqueKey, ConditionalWrapper } from '../helpers';
import { BarcodeView, QRCodeField } from '../components';

import AddSectionButton from './AddSectionButton';
import DeleteSectionButton from './DeleteSectionButton';
import SectionTree from './SectionTree';
import EditSectionButton from './EditSectionButton';

//TODO style bottom border for expanded node
const styles = theme => ({
    wrapper: {
        borderTop: '1px #ccc solid',
        "&:first-child": {
            border: 'none'
        }
    },
    detailsRoot: {
        paddingRight: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    rootPanel: {
        boxShadow: 'none',
        "&>div:last-child": {
            transition: 'none!important',
        }
    },
    expandToggle: {
        paddingLeft: '0px',
        paddingRight: '0px',
        "&$expanded": {
            borderBottom: '1px #ccc solid',
            minHeight: '48px',
        },
        "&:hover:not(.Mui-disabled)": {
            cursor: 'default'
        }
    },
    hoverToggle: {
        "&:hover:not(.Mui-disabled)": {
            backgroundColor: 'rgba(0, 0, 0, 0.07)',
            cursor: 'pointer',
        },
    },
    expandIcon: {
        right: 'initial',
        left: '0',
        transform: 'translateY(-50%) rotate(-90deg)',
        "&$expanded": {
            transform: 'translateY(-50%) rotate(0deg)',
        }
    },
    expanded: {},
    summary: {
        alignItems: 'center',
        margin: '0',
        "&$expanded": {
            margin: '0',
        },
    },
    children: {
        boxSizing: 'border-box',
        paddingLeft: 0,
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        paddingLeft: '48px',
        paddingRight: '0',
    },
    descriptionBarcode: {
        color: '#000',
        width: '210px !important',
        height: '210px !important',
        position: 'absolute',
        top: -22,
        left: -22,
        fontSize: 10,
        lineHeight: '10px',
        wordBreak: 'break-all',
        "& > div": {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            position: 'absolute',
            width: 190
        },
        "& .snake-0": {
            transform: 'rotate(-90deg)',
            transformOrigin: '0 100%',
            bottom: 20,
            left: 10,
        },
        "& .snake-1": {
            transform: 'rotate(-90deg)',
            transformOrigin: '0 100%',
            bottom: 20,
            left: 20,
        },
        "& .snake-2": {
            top: 0,
            left: 20,
        },
        "& .snake-3": {
            top: 10,
            left: 20,
        },
        "& .snake-4": {
            right: 0,
            bottom: -10,
            transform: 'rotate(90deg)',
            transformOrigin: '100% 0',
        },
        "& .snake-5": {
            right: 10,
            bottom: -10,
            transform: 'rotate(90deg)',
            transformOrigin: '100% 0',
        },
        "& .snake-6": {
            bottom: 0,
            left: 0,
            transform: 'rotate(180deg)',

        },
        "& .snake-7": {
            bottom: 10,
            left: 0,
            transform: 'rotate(180deg)',
        },
    },
    '@media print': {
        descriptionBarcode: {
            top: -20,
            left: -20,
            width: '155px !important',
            height: '155px !important',
            lineHeight: '10px',
            fontSize: 10,
            "& > div": {
                width: 134
            },
        },
    },
    descriptionParagraph: {
        margin: 0,
    },
});

class SectionNode extends Component {

    section = {};

    constructor(props) {
        super(props);

        const { section: sectionProps } = this.props;
        const section = this.setSection(sectionProps);
        this.state = { section };
    }

    componentWillReceiveProps = ({ section }) => {
        if (!isEqual(this.section, section)) {
            const newSection = this.setSection(section);

            this.setState({
                section: newSection,
            });
        }
    };

    setSection(section) {
        const newSection = {
            ...section,
            children: section.children.map(child => ({
                ...child,
                uniqueKey: nextUniqueKey(),
            })),
        };
        this.section = section;

        return newSection;
    }

    cloneSection(section) {
        return {
            ...section,
            children: [
                ...section.children,
            ],
        }
    }

    handleAddSection = async ({ quantity, first, ...resource }) => {
        const { section } = this.state;
        const { openedSections, expandPanel } = this.props;
        const prevSection = this.cloneSection(section);

        resource = {
            ...resource,
            uniqueKey: nextUniqueKey(),
            children: [],
            deletable: true,
        };

        const newSections = [];

        if (quantity > 1) {
            const { name } = resource;
            for (let i = first; i < (quantity + first); i++) {
                newSections.push({
                    ...resource,
                    uniqueKey: nextUniqueKey(),
                    name: name.indexOf('#') > -1 ? name.replace('#', i) : name.concat(' ' + i),
                })
            }
        } else {
            newSections.push(resource);
        }

        section.children = section.children ? section.children.concat(newSections) : newSections;

        const updatedSection = await this.handleUpdateSection(section);

        this.onChange(prevSection, updatedSection);

        if (!openedSections.includes(updatedSection.id)) expandPanel(updatedSection.id);
    };

    handleChange = (changedChild, changedChildIndex) => {
        const { section } = this.state;
        const prevSection = this.cloneSection(section);

        section.children[changedChildIndex] = changedChild;

        this.onChange(prevSection, section);
    };

    handleUpdateSection = async section => {
        const { dataProvider, formSection } = this.props;

        const sectionForUpdate = section.id ? section : formSection;

        const { data: updatedSection } = await dataProvider(UPDATE, 'storageareas', {
            id: sectionForUpdate.id,
            data: {
                ...sectionForUpdate,
                children: section.children,
            }
        });

        return updatedSection;
    }

    handleDeleteSection = async sectionIndex => {
        const { section } = this.state;
        const prevSection = this.cloneSection(section);

        section.children.splice(sectionIndex, 1);

        const updatedSection = await this.handleUpdateSection(section);

        this.onChange(prevSection, updatedSection);
    };

    handleEditClick = async section => {
        const { section: prevSection } = this.state;

        const updatedSection = await this.handleUpdateSection(section);

        this.onChange(prevSection, updatedSection);
    };

    onChange = (prevSection, section) => {
        const { sectionIndex, onChange } = this.props;

        this.prevSection = prevSection;
        this.setState({ section });

        onChange(section, sectionIndex);
    };


    handleExpand = section => {

        if (section.id) {
            const { expandPanel } = this.props;

            expandPanel(section.id);

            // if (!onExpand) return;

            if (!section.loaded) {

                // onExpand(section.id);

                this.setState({
                    section: {
                        ...section,
                        loaded: true
                    }
                });
            }
        }
    }

    render() {
        const { sectionIndex, classes, isRoot, onDelete, path: pathProps, disabled, onExpand, openedSections, openSection, visible } = this.props;
        const { section } = this.state;
        const area = !!section.area ? `(${section.area}m²)` : '';
        const path = !isRoot ? [...pathProps, `${section.name} ${area}`] : [...pathProps];

        const opened = () => {
            if (openedSections === undefined) return;
            if (!section.id) return true;
            return openedSections.includes(section.id);
        }

        const isExpansion = !isRoot && section.children.length > 0;

        return section && visible ? (
            <div className={!isRoot ? classes.wrapper : ''}>
                <ConditionalWrapper
                    condition={isExpansion}
                    wrapper={
                        children => <ExpansionPanel onChange={() => this.handleExpand(section)} expanded={opened()} defaultExpanded={!section.id} classes={{ root: classes.rootPanel }}>{children}</ExpansionPanel>
                    }
                >
                    <ExpansionPanelSummary
                        expandIcon={isExpansion && <ExpandMoreIcon />}
                        classes={
                            {
                                content: classes.summary,
                                expandIcon: classes.expandIcon,
                                root: isExpansion ? classes.expandToggle + ' ' + classes.hoverToggle : classes.expandToggle,
                                expanded: classes.expanded
                            }
                        }
                    >
                        {!isRoot && <div className={classes.row}>
                            <Typography variant="subheading">
                                {section.name} {!!section.area && <span>({section.area}m²)</span>}
                            </Typography>
                            <div>
                                {section.id && section.children.length === 0 && (
                                    <QRCodeField source="value"
                                        size={32}
                                        record={{
                                            value: JSON.stringify({
                                                storagearea: section.id,
                                                name: section.name,
                                            }),
                                        }}
                                        description={
                                            <div className={classes.descriptionBarcode}>
                                                {
                                                    path.map((el, ind) => {
                                                        return (
                                                            <div className={`snake-${ind}`} key={ind}>
                                                                {el}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        view={<BarcodeView size={248} />}
                                    />
                                )}
                                {!disabled && (
                                    <Fragment>
                                        <EditSectionButton section={section}
                                            onEdit={this.handleEditClick}
                                        />
                                        <AddSectionButton section={section}
                                            onAdd={this.handleAddSection}
                                        />
                                        <DeleteSectionButton section={section}
                                            onConfirm={() => onDelete(sectionIndex)}
                                        />
                                    </Fragment>
                                )}
                            </div>
                        </div>}
                    </ExpansionPanelSummary>
                    <ConditionalWrapper
                        condition={isExpansion}
                        wrapper={children => <ExpansionPanelDetails classes={{ root: classes.detailsRoot }}>{children}</ExpansionPanelDetails>}
                    >
                        {section.children.length > 0 && (
                            <SectionTree children={section.children}
                                className={!isRoot && classes.children}
                                visible={section.level === 2 || opened()}
                                classes={classes}
                                onDeleteSection={this.handleDeleteSection}
                                onExpand={onExpand}
                                onChange={this.handleChange}
                                path={path}
                                disabled={disabled}
                                openSection={openSection}
                            />
                        )}
                    </ConditionalWrapper>
                </ConditionalWrapper>
            </div>

        ) : null;
    }
}

const mapStateToProps = state => ({
    openedSections: state.openedSections,
})

const enhance = compose(
    withDataProvider,
    withStyles(styles),
)

export default enhance(connect(mapStateToProps, { expandPanel }, null, { withRef : true })(SectionNode));
