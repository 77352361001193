import { debounce } from 'lodash';
import { Edit as EditIcon } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import { Button } from 'react-admin';

import { DialogForm } from '../components';
import { formUniqueKey } from '../helpers';

import FieldsSection from './FieldsSection';

class EditSectionButton extends Component {

    _isMounted = false;

    state = {
        isOpenDialog: false,
    };

    constructor(props) {
        super(props);

        const { section: { name } } = this.props;
        this.state = {
            isOpenDialog: false,
            name,
        };
        this.handleChangeName = debounce(this.handleChangeName, 200);
    }

    componentDidMount = async () => {
        this._isMounted = true;
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChangeName = ({ target: { value } }) => {
        if (this._isMounted) {
            this.setState({
                name: value,
            });
        }
    };

    handleConfirmDialog = resource => {
        const { onEdit } = this.props;

        onEdit(resource);
    };

    handleDialogClose = () => {
        if (this._isMounted) {
            this.setState({
                isOpenDialog: false,
            });
        }
    };

    handleEditSectionClick = () => {
        if (this._isMounted) {
            this.setState({
                isOpenDialog: true,
            });
        }
    };

    render() {
        const { section } = this.props;
        const { isOpenDialog, name } = this.state;

        return (
            <Fragment>
                <Button label="ra.action.edit" onClick={this.handleEditSectionClick}>
                    <EditIcon />
                </Button>
                <DialogForm form={`edit-section-form-${formUniqueKey(section)}`}
                            onClose={this.handleDialogClose}
                            onSubmit={this.handleConfirmDialog}
                            record={section}
                            saveButton="save"
                            showDialog={isOpenDialog}
                            size="sm"
                            title="resources.warehouses.action.edit"
                            titleParams={{ name }}
                >
                    <FieldsSection isShowAddedFields={false} onChangeName={this.handleChangeName} />
                </DialogForm>
            </Fragment>
        );
    }

}

export default EditSectionButton;
