import { keyBy } from 'lodash';
import { Card, CardContent, CardHeader, Toolbar, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { TextField, GET_LIST, translate, withDataProvider, Pagination } from 'react-admin';
import { compose } from 'recompose';

import { AddressField, Datagrid, FullNameField } from '../components';

import CustomerProjects from './CustomerProjects';
import { CONFIRMED } from '../articles/statuses';

const styles = {
    content: {
        padding: 0,
        '& tr:last-child > td': {
            borderBottom: 'none',
        },
    },
    toolbar: {
        float: 'right',
    },
    company: {
        whiteSpace: 'nowrap',
    },
    address: {
        width: '100%',
    },
};

class Customers extends Component {

    _isMounted = false;
    state = {
        customers: [],
        page: 1,
        perPage: 10,
    };

    componentDidMount = async () => {
        const { perPage } = this.state;
        this._isMounted = true;

        this.updateCustomers(1, perPage);
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    handlePageChange = async page => {
        const { perPage } = this.state;
        await this.updateCustomers(page, perPage);
    };

    handlePerPageChange = async perPage => {
        await this.updateCustomers(1, perPage);
    };

    render() {
        const { className, classes, translate } = this.props;
        const { customers, page, perPage, total } = this.state;

        return (
            <Card className={className}>
                <CardHeader title={translate('pos.dashboard.customers.title')} />
                <CardContent className={classes.content}>
                    <Datagrid data={keyBy(customers, 'id')}
                              ids={customers.map(({ id }) => id)}
                              currentSort={{
                                  field: '',
                              }}
                              expand={<CustomerProjects />}
                    >
                        <FullNameField sortBy="user.surname" sortable={false} />
                        <TextField label="resources.address.fields.company"
                                   source="address.company"
                                   sortable={false}
                                   className={classes.company}
                        />
                        <AddressField headerClassName={classes.address}
                                      showName={false}
                                      sortable={false}
                        />
                    </Datagrid>
                    {total > 0 && (
                        <Toolbar className={classes.toolbar}>
                            <Pagination total={total}
                                        page={page}
                                        perPage={perPage}
                                        setPage={this.handlePageChange}
                                        setPerPage={this.handlePerPageChange}
                            />
                        </Toolbar>
                    )}
                </CardContent>
            </Card>
        );
    }

    updateCustomers = async (page, perPage) => {
        const { dataProvider } = this.props;
        const { data: customers, total } = await dataProvider(GET_LIST, 'customers', {
            filter: {
                'articles.delivery][isNotNull': 1,
                'articles.status': CONFIRMED,
            },
            pagination: {
                page,
                perPage,
            },
        });

        if (this._isMounted) {
            this.setState({ customers, perPage, page, total });
        }
    };

}

const enhance = compose(
    withStyles(styles),
    translate,
    withDataProvider,
);

export default enhance(Customers);
