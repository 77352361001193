import { get, debounce } from 'lodash';
import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    BooleanInput,
    RadioButtonGroupInput,
    SelectInput,
    TextInput,
    email,
    minLength,
    maxValue,
    ReferenceInput,
    translate,
    REDUX_FORM_NAME
} from 'react-admin';

import generalStyles from '../styles';
import { passwordConfirmation, required, isValidPassword } from '../validations';
import { showAlertHelper } from '../helpers';

import genders from './genders';
import languages from './languages';
import defaultRoles, { ROLE_AGENT, ROLE_OFFICE_EMPLOYEE, ROLE_SUPER_ADMIN, ROLE_MAIN_CUSTOMER } from './roles';
import { compose } from 'recompose';

const styles = theme => ({
    ...generalStyles(theme),
    enabled: {
        marginTop: 16,
        marginBottom: 8,
        justifyContent: 'flex-end',
    },
});

class UnconnectedUserFormFields extends Component {

    state = {};

    constructor(props) {
        super(props);

        this.handleChangePassword = debounce(this.handleChangePassword, 200);
    }

    componentDidUpdate() {
        const initialState = {...this.props.initial};
        const currentState = {...this.props.current};

        delete initialState.deletable;
        delete currentState.deletable;

        showAlertHelper.saveDefaultState(initialState);
        showAlertHelper.saveCurrentState(currentState);
    }

    handleChangePassword = ({ target: { value } }) => {
        this.setState({
            password: value,
        });
    };

    handleChangeRole = currentRole => {
        const { onChangeRole } = this.props;

        this.setState({ currentRole });
        onChangeRole(currentRole);
    };

    render() {
        const { classes, className, permissions, record, roles, source, chooseAgent, translate } = this.props;
        const { password, currentRole } = this.state;

        const prefixSource = source ? `${source}.` : '';
        const isNewRecord = !get(record, `${prefixSource}id`);

        return (
            <div className={className}>
                <div className={classes.container}>
                    <TextInput label="resources.users.fields.firstname"
                        source={`${prefixSource}firstname`}
                        className={classes.input}
                        validate={maxValue(255)}
                    />
                    <TextInput label="resources.users.fields.surname"
                        source={`${prefixSource}surname`}
                        className={classes.input}
                        validate={[
                            required('users', 'surname'),
                            maxValue(255),
                        ]}
                    />
                    <TextInput label="resources.users.fields.email"
                        source={`${prefixSource}email`}
                        className={classes.input}
                        type={`${prefixSource}email`}
                        validate={[
                            required('users', 'email'),
                            email(),
                            minLength(2),
                            maxValue(254),
                        ]}
                    />
                    <TextInput label="resources.users.fields.username"
                        source={`${prefixSource}username`}
                        className={classes.input}
                        validate={[
                            required('users', 'username'),
                            minLength(2),
                            maxValue(255),
                        ]}
                    />
                    <TextInput label="resources.users.fields.plain_password"
                        source={`${prefixSource}plain_password`}
                        className={classes.input}
                        type="password"
                        validate={isNewRecord ? [
                            required('users', 'plain_password'),
                            isValidPassword(translate('ra.validation.is_valid_password'))
                        ] : isValidPassword(translate('ra.validation.is_valid_password'))}
                        onChange={this.handleChangePassword}
                    />
                    <TextInput label="resources.users.fields.confirm_password"
                        source={`${prefixSource}confirm_password`}
                        className={classes.input}
                        type="password"
                        validate={[
                            (isNewRecord || !!password) && required('users', 'confirm_password'),
                            !!password && passwordConfirmation(),
                        ].filter(validator => !!validator)}
                    />
                    <TextInput
                        label="resources.users.fields.phone_number"
                        source={`${prefixSource}phone_number`}
                        className={classes.input}
                    />
                    <TextInput
                        label="resources.users.fields.mobile_phone_number"
                        source={`${prefixSource}mobile_phone_number`}
                        className={classes.input}
                    />
                    {(permissions === ROLE_SUPER_ADMIN || permissions === ROLE_AGENT || permissions === ROLE_OFFICE_EMPLOYEE || permissions === ROLE_MAIN_CUSTOMER)
                        && roles.length > 0 && !record.id && (
                            <SelectInput label="resources.users.fields.role"
                                source={`${prefixSource}role`}
                                choices={roles.map(role => ({
                                    id: role,
                                    name: role.replace(/^role_/ig, '').replace('_', ' '),
                                }))}
                                className={classes.input}
                                validate={required('users', 'role')}
                                onChange={this.handleChangeRole}
                            />
                        )}
                    {permissions === ROLE_SUPER_ADMIN && currentRole !== ROLE_AGENT && chooseAgent && !record.id && (
                        <ReferenceInput label="resources.users.fields.agent"
                            resource="agents"
                            source={`${prefixSource}agent.id`}
                            reference="agents"
                            className={classes.input}
                            validate={required('users', 'agent.id')}>
                            <SelectInput optionText="user.username" />
                        </ReferenceInput>
                    )}
                </div>
                <div className={classes.container}>
                    <RadioButtonGroupInput label="resources.users.fields.gender"
                        source={`${prefixSource}gender`}
                        choices={genders}
                        options={{ row: true }}
                        className={classes.input}
                        validate={required('users', 'gender')}
                    />
                    <RadioButtonGroupInput label="resources.users.fields.language"
                        source={`${prefixSource}language_code`}
                        choices={languages}
                        options={{ row: true }}
                        className={classes.input}
                        validate={required('users', 'language_code')}
                    />
                    <BooleanInput label="resources.users.fields.enabled"
                        source={`${prefixSource}enabled`}
                        className={`${classes.input} ${classes.enabled}`}
                    />
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    initial: state.form[REDUX_FORM_NAME]?.initial,
    current: state.form[REDUX_FORM_NAME]?.values,
})

const enhance = compose(
    withStyles(styles),
    translate,
    connect(mapStateToProps),
);

const UserFormFields = enhance(UnconnectedUserFormFields);

UserFormFields.defaultProps = {
    onChangeRole: () => undefined,
    roles: defaultRoles,
};

export default UserFormFields;


