import { TextField } from '@material-ui/core';
import React from 'react';

export default ({ meta: { touched, error } = {}, input: { ...inputProps }, ...props }) => (
    <TextField error={!!(touched && error)}
               helperText={touched && error}
               {...inputProps}
               {...props}
               fullWidth
    />
);
