import { keyBy } from 'lodash';
import { CircularProgress, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { NumberField, TextField, GET_LIST, translate, withDataProvider } from 'react-admin';
import { compose } from 'recompose';

import { Datagrid } from '../components';
import { CONFIRMED } from '../deliveries/statuses';

import ProjectDeliveries from './ProjectDeliveries';

const styles = {
    orderNumber: {
        width: '100%',
    },
};

class CustomerProjects extends Component {

    _isMounted = false;
    state = {
        projects: [],
        isLoading: false,
    };

    componentDidMount = async () => {
        const { record: { projects: projectsProps }, dataProvider } = this.props;
        this._isMounted = true;

        this.setState({
            isLoading: true,
        });

        const projectsDeliveries = await Promise.all(
            projectsProps.map(({ id: projectId }) => dataProvider(GET_LIST, 'deliveries', {
                filter: {
                    'articles.barcodes.project': projectId,
                    'status': CONFIRMED,
                },
            })),
        );

        if (this._isMounted) {
            const projects = projectsProps.map((project, index) => {
                const { data: projectDeliveries } = projectsDeliveries[index];

                return {
                    ...project,
                    deliveries: projectDeliveries,
                };
            }).filter(({ deliveries }) => deliveries.length > 0);

            this.setState({
                projects,
                isLoading: false,
            });
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { classes, translate } = this.props;
        const { projects, isLoading } = this.state;

        return isLoading ? (
            <CircularProgress size={20} thickness={2} />
        ) : (
            <Datagrid data={keyBy(projects, 'id')}
                      ids={projects.map(({ id }) => id)}
                      currentSort={{
                          field: '',
                      }}
                      expand={<ProjectDeliveries />}
            >
                <NumberField source="id" label={translate('resources.projects.fields.id')} sortable={false} />
                <TextField source="order_number"
                           label={translate('resources.projects.fields.order_number')}
                           headerClassName={classes.orderNumber}
                           sortable={false}
                />
                <NumberField source="area"
                             label={translate('resources.projects.fields.area') + ', m²'}
                             sortable={false}
                />
                <NumberField source="measurements"
                             label={translate('resources.projects.fields.measurements') + ', m³'}
                             sortable={false}
                />
            </Datagrid>
        );
    }

}

const enhance = compose(
    withDataProvider,
    withStyles(styles),
    translate,
);

export default enhance(CustomerProjects);
