import React, { Fragment } from 'react';
import { CardActions, withStyles } from '@material-ui/core';

import AddressDialog from './AddressDialog';
import CustomerAddressApplyButton from './CustomerAddressApplyButton';

const styles = () => ({
  root: {
    justifyContent: "flex-end"
  }
})

const LoadingPointsListActions = ({ 
  inHeader, 
  loadingPoints,
  disabled, 
  customer, 
  handleAddCustomerAddress, 
  handleAddAddress, 
  classes 
}) => {

  if (!inHeader && (!loadingPoints || loadingPoints.length === 0)) return null;

  return (
    <Fragment>
      {!disabled && (
        <CardActions className={inHeader && classes.root}>
          {customer && <CustomerAddressApplyButton onApply={handleAddCustomerAddress} />}
          <AddressDialog title="resources.customers.action.add_loading_point"
            onAdd={handleAddAddress}
          />
        </CardActions>
      )
      }
    </Fragment>
  )
}


export default withStyles(styles)(LoadingPointsListActions);