import React from 'react';
import { SelectInput, translate } from 'react-admin';

import { addressToString } from '../helpers';

const CustomSelectProjectInput = props => {
    const { translate, choices } = props;
    const projects = choices.filter(({ customer: { address, user } }) => address && user)
        .map(({ id, customer: { address, user } }) => ({
            id: id,
            name: addressToString({
                address,
                user,
                showName: false,
                translate,
            }),
        }));

    return (
        <SelectInput choices={projects} source="project" label="resources.articles.fields.project" />
    );
};

export default translate(CustomSelectProjectInput);
