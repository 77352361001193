import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { register as registerServiceWorker } from './serviceWorker';
import * as Sentry from '@sentry/browser';

import './index.css';
import App from './App';

Sentry.init({dsn: process.env.REACT_APP_SENTRY_DSN});
ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
