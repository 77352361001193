const timeFormatter = (value, unit, translate) => {
    return value + ' ' + translate(`time.${unit}`, { smart_count: value });
};

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = DAY * 30;
const YEAR = DAY * 365;

const roundSeconds = seconds => {
    switch (true) {
        case seconds < MINUTE:
            return [Math.floor(seconds), 'second'];
        case seconds < HOUR:
            return [Math.floor(seconds / MINUTE), 'minute'];
        case seconds < DAY:
            return [Math.floor(seconds / HOUR), 'hour'];
        case seconds < WEEK:
            return [Math.floor(seconds / DAY), 'day'];
        case seconds < MONTH:
            return [Math.floor(seconds / WEEK), 'week'];
        case seconds < YEAR:
            return [Math.floor(seconds / MONTH), 'month'];
        default:
            return [Math.floor(seconds / YEAR), 'year'];
    }
};

const toSeconds = (value, unit) => {
    switch (unit) {
        case 'minute':
            return value * MINUTE;
        case 'hour':
            return value * HOUR;
        case 'day':
            return value * DAY;
        case 'week':
            return value * WEEK;
        case 'month':
            return value * MONTH;
        case 'year':
            return value * YEAR;
        case 'second':
        default:
            return value;
    }
};

export default (seconds, translate) => {
    const result = [];

    let countUnits = 0;
    let value;
    let unit;

    do {
        [value, unit] = roundSeconds(seconds);
        result.push([value, unit]);

        seconds -= toSeconds(value, unit);
    } while (unit !== 'second' && 7 > countUnits++);

    return result.filter(([value]) => !!value)
        .map(([value, unit]) => timeFormatter(value, unit, translate))
        .join(' ');
};
