import { connect } from 'react-redux';

import { UPLOAD_FILE } from './actions';

const mapDispatchToProps = dispatch => ({
    uploadProvider: (resource, file) => new Promise(function (resolve, reject) {
        const action = {
            type: UPLOAD_FILE,
            payload: { file: file.rawFile },
            meta: {
                resource,
                onSuccess: src => resolve({
                    ...file,
                    src,
                }),
                onFailure: ({ message }) => reject(message),
            },
        };

        return dispatch(action);
    }),
    dispatch: dispatch,
});

export default Component => {
    return connect(null, mapDispatchToProps)(Component);
}
