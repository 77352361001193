import { CheckCircle } from '@material-ui/icons';
import React from 'react';
import { Button } from 'react-admin';

const CustomerAddressApplyButton = ({ onApply }) => (
    <Button label="resources.customers.action.apply_customer_address" onClick={onApply}>
        <CheckCircle />
    </Button>
);

export default CustomerAddressApplyButton;
