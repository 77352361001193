import { withStyles } from '@material-ui/core';
import { unparse as convertToCSV } from 'papaparse/papaparse';
import React, { Component, cloneElement } from 'react';
import {
    List,
    Responsive,
    downloadCSV,
    setListSelectedIds,
    Pagination
} from 'react-admin';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { parse, stringify } from 'querystring';

import {
    ROLE_COLLECTOR_GUY,
    ROLE_CUSTOMER,
    ROLE_MAIN_CUSTOMER,
    ROLE_WAREHOUSE_MANAGER,
} from '../users/roles';

import AddToCartButton from '../articles/AddToCartButton';
import ArticleDatagrid from '../articles/ArticleDatagrid';
import ArticleFilter from '../articles/ArticleFilter';
import MobileGrid from '../articles/MobileGrid';
import { IN_WAREHOUSE, NOT_IN_WAREHOUSE } from '../articles/statuses';

const exporter = posts => {
    posts = posts.map(el => ({
        'Id': el.id,
        'Name': el.name,
        'Project_id': el.project,
        'Barcodes': el.barcodes.reduce((a, b) => +a + b.id + ', ', '').slice(0, -2),
        'Category': el.category ? el.category.name : '',
        'Colors': el.colors ? el.colors.join(', ') : '',
        'Damages': el.damages ? el.damages.join(', ') : '',
        'Height': el.height,
        'Depth': el.length,
        'Width': el.width,
        'Manufacturer': el.manufacturer,
        'Photos': el.photos ? el.photos.join(', ') : '',
        'Storage_area': el.storagearea ? el.storagearea.full_name : '',
        'Type': el.type,
        'Created_at': new Date(el.created_at).toLocaleString(),
        'Updated_at': new Date(el.updated_at).toLocaleString(),
    }));

    const csv = convertToCSV({
        data: posts,
        fields: [
            'Id',
            'Name',
            'Project_id',
            'Barcodes',
            'Category',
            'Colors',
            'Damages',
            'Height',
            'Depth',
            'Width',
            'Manufacturer',
            'Photos',
            'Storage_area',
            'Type',
            'Created_at',
            'Updated_at',
        ],
    });
    downloadCSV(csv, 'articles');
};

const styles = theme => ({
    articleList: {
        '& div[data-test="bulk-actions-toolbar"]': {
            backgroundColor: theme.palette.primary.toolbar,
        },
    },
    pagination: {
        width: '100%'
    },
    wrap: {
        '& > div > div:nth-child(2)': {
            flexWrap: 'wrap',
            paddingRight: 0
        }
    }
});

const ArticleListPagination = props => <Pagination rowsPerPageOptions={[25, 100, 250, 500]} {...props} />;

const ArticleListTopbar = ({ actions, total, params, setPerPage, setPage, classes }) => (
    <>
        {cloneElement(actions)}
        {total > 0
            ? <ArticleListPagination
                classes={{
                    root: classes.pagination
                }}
                total={total}
                page={params.page ?? 1}
                perPage={params.perPage ?? 25}
                setPerPage={setPerPage}
                setPage={setPage}
            />
            : ''}
    </>
)

class UnconnectedArticleList extends Component {

    componentDidMount() {
        this.props.setListSelectedIds('articles', []);
    }

    handlePageChange = page => {
        const { push, location } = this.props;
        const query = parse(location.search.replace('?', ''));

        push(`/shopping-cart?${stringify({ ...query, page })}`);
    };

    handlePerPageChange = perPage => {
        const { push, location } = this.props;
        const query = parse(location.search.replace('?', ''));

        push(`/shopping-cart?${stringify({ ...query, perPage })}`);
    };

    render() {
        const {
            classes,
            filters,
            actions,
            customActions,
            permissions,
            bulkActionButtons,
            setListSelectedIds,
            pagination,
            params,
            total,
            ...props
        } = this.props;

        return (
            <>
                <List
                    {...props}
                    className={classes.articleList}
                    bulkActionButtons={permissions !== ROLE_COLLECTOR_GUY ? bulkActionButtons : false}
                    filters={<ArticleFilter filters={filters} permissions={permissions} />}
                    perPage={25}
                    classes={{
                        root: classes.wrap
                    }}
                    actions={
                        <ArticleListTopbar
                            actions={actions}
                            total={total}
                            params={params}
                            setPerPage={this.handlePerPageChange}
                            setPage={this.handlePageChange}
                            classes={classes}
                        />
                    }
                    exporter={
                        (
                            !customActions
                            && permissions !== ROLE_WAREHOUSE_MANAGER
                            && permissions !== ROLE_MAIN_CUSTOMER
                            && permissions !== ROLE_COLLECTOR_GUY
                            && permissions !== ROLE_CUSTOMER
                        ) ? exporter : false
                    }
                    pagination={pagination ?? <ArticleListPagination />}
                >
                    <Responsive
                        medium={<MobileGrid permissions={permissions} customButtons={customActions} />}
                        large={<ArticleDatagrid permissions={permissions} customActions={customActions} />}
                    />
                </List>
            </>
        );
    }
}

const mapStateToProps = state => ({
    params: state.admin.resources.articles.list.params,
    total: state.admin.resources.articles.list.total
})

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, { setListSelectedIds, push }),
);

const ArticleList = enhance(UnconnectedArticleList);

ArticleList.defaultProps = {
    filter: {
        'status][in][': [NOT_IN_WAREHOUSE, IN_WAREHOUSE],
    },
    customActions: false,
    bulkActionButtons: <AddToCartButton />,
    filters: {
        q: true,
        qid: true,
        name: true,
        category: true,
        project: true,
        type: true,
        manufacturer: true,
        storagearea: true,
        isScratch: true,
        width: true,
        height: true,
        length: true,
        colors: true,
    }
};

export default ArticleList;
