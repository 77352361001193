import React from 'react';

import { FullNameField } from '../components';

const DeliveryTitle = ({ record }) => (
    <span>
        #{record.id}. {record.address ? (
        <FullNameField record={record.address} />
    ) : record.surname}
    </span>
);

export default DeliveryTitle;
