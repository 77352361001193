import React, { Component, Fragment } from 'react';
import { Button } from 'react-admin';

import Dialog from './Dialog';


class ConfirmButton extends Component {

    constructor(props) {
        super(props);

        const { isOpen } = this.props;
        this.state = { isOpen };
    }

    handleConfirm = () => {
        const { onConfirm } = this.props;

        if (onConfirm() !== false) {
            this.handleDialogClose();
        }
    };

    handleDialogClose = () => {
        const { onClose } = this.props;

        this.setState({
            isOpen: false,
        });

        if (onClose) {
            onClose();
        }
    };

    handleOpenDialogClick = () => {
        const { onClick } = this.props;
        if (onClick) {
            if (onClick() === false) {
                return;
            }
        }

        this.setState({
            isOpen: true,
        });
    };

    render() {
        const {
            className,
            children,
            title,
            disabled,
            disabledConfirm,
            label,
            icon,
            alignIcon,
            variant,
            color,
            size,
            translateOptions,
        } = this.props;
        const { isOpen } = this.state;

        return (
            <Fragment>
                <Button className={className}
                        disabled={disabled}
                        variant={variant}
                        color={color}
                        onClick={this.handleOpenDialogClick}
                        label={label}
                        alignIcon={alignIcon}
                        size={size}
                >
                    {icon}
                </Button>
                <Dialog isOpen={isOpen}
                        title={title}
                        onConfirm={this.handleConfirm}
                        onClose={this.handleDialogClose}
                        translateOptions={translateOptions}
                        disabled={disabledConfirm}
                >
                    {children}
                </Dialog>
            </Fragment>
        );
    }

}

ConfirmButton.defaultProps = {
    isOpen: false,
    variant: "contained",
    color: "primary",
};

export default ConfirmButton;
