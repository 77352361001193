import { withStyles } from '@material-ui/core';
import React from 'react';
import { BooleanField, ReferenceField, SimpleShowLayout, TextField, FunctionField } from 'react-admin';

import {
    AddressField,
    CommentList, Container,
    MobileGrid as BaseMobileGrid,
    UpdatedAtField,
} from '../components';
import LightboxField from '../components/LightboxField';
import { guttersContainer, marginGutters } from '../helpers';
import {
    ROLE_AGENT,
    ROLE_COLLECTOR_GUY,
    ROLE_CUSTOMER,
    ROLE_MAIN_CUSTOMER,
    ROLE_OFFICE_EMPLOYEE,
    ROLE_SUPER_ADMIN,
    ROLE_WAREHOUSE_MANAGER,
} from '../users/roles';
import AddToCartButton from './AddToCartButton';
import ArticleType from './ArticleType';
import { OUTSOURCED } from './statuses';

const TitleMobileGrid = ({ record }) => (
    <span>
        #{record.id}. {record.name}
    </span>
);

const styles = theme => ({
    root: {
        ...guttersContainer(theme),
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    articlePhotoModal: {
        maxWidth: 500,
    },
    gutter: {
        '&.ra-field': {
            color: theme.palette.primary.special,
            ...marginGutters(theme),
            flex: '1 0 80%',
        },
    },
    field: {
        '&.ra-field': {
            color: theme.palette.primary.special,
            flex: '1 0 calc(33.33% - 32px)',
            minWidth: 280,
            [theme.breakpoints.up('sm')]: {
                flexBasis: 'calc(33.33% - 48px)',
            },
        },
    },
    lightbox: {
        margin: `0 ${theme.spacing.unit * 2}px`
    },
    img: {
        '& img': {
            maxWidth: 'calc(100% - 16px)',
        },
    },
    measurements: {
        width: 'calc(100% + 32px)',
        [theme.breakpoints.up('sm')]: {
            width: 'calc(100% + 48px)',
        },
        '&, &:first-child, &:last-child': {
            padding: 0,
        },
        '& .ra-field': {
            minWidth: 50,
        },
    },
    barcodesContainer: {
        '& > span': {
            display: 'inline-block',
            padding: '5px',
            borderRadius: '50%',
            margin: 2,
        },
    },
    scanned: {
        background: theme.palette.primary.scanned,
    },
    notScanned: {
        background: theme.palette.primary.notScanned,
    },
});

const BarcodesForOutsourcingPage = ({ record, classes, scannedBarcodes }) => {

    const fullyScannedArticle = !record.barcodes.filter(({ id }) => !scannedBarcodes.includes(id)).length;

    return (
        <div className={classes.barcodesContainer}>
            {record.barcodes.map(({ id }) =>
                <span
                    className={
                        fullyScannedArticle || record.status === OUTSOURCED
                            ? null
                            : scannedBarcodes.includes(id)
                                ? classes.scanned
                                : classes.notScanned
                    }
                    key={id}
                >
                    {id}
                </span>)
            }
        </div>
    );
};

BarcodesForOutsourcingPage.defaultProps = {
    addLabel: true,
};

const Barcodes = ({ record, ...props }) => (
    <TextField {...props}
        source="barcodes"
        record={{ barcodes: record.barcodes.map(({ id }) => id).join(', ') }}
    />
);

Barcodes.defaultProps = {
    addLabel: true,
};

class UnconnectedBodyMobileGrid extends React.Component {

    render() {
        const {
            record,
            classes,
            className,
            isEmbedComments,
            permissions,
            outsourcingPage,
            scannedBarcodes,
            ...props
        } = this.props;

        return (
            <SimpleShowLayout className={`${className} ${classes.root}`} record={{
                ...record,
                default: '-',
            }} {...props} basePath="/projects">
                {!outsourcingPage
                    ? <Barcodes label="QID" className={`${classes.field} ${classes.gutter}`} />
                    : <BarcodesForOutsourcingPage
                        label="QID"
                        classes={classes}
                        scannedBarcodes={scannedBarcodes}
                        className={`${classes.field} ${classes.gutter}`}
                    />
                }
                {permissions !== ROLE_COLLECTOR_GUY && (
                    <ReferenceField source="barcodes[0].project_id"
                        label="resources.articles.fields.project"
                        reference="projects"
                        className={`${classes.field} ${classes.gutter}`}
                        linkType={permissions === ROLE_WAREHOUSE_MANAGER ? 'show' : 'edit'}
                    >
                        <AddressField source="customer.address" />
                    </ReferenceField>
                )}
                <TextField source={record.name ? 'name' : 'default'}
                    label="resources.articles.fields.name"
                    className={`${classes.field} ${classes.gutter}`}
                />
                <TextField source={record.category && record.category.name ? 'category.name' : 'default'}
                    label="resources.articles.fields.category"
                    className={`${classes.field} ${classes.gutter}`}
                />
                <ArticleType source={record.type ? 'type' : 'default'}
                    label="resources.articles.fields.type"
                    className={`${classes.field} ${classes.gutter}`}
                />
                <SimpleShowLayout
                    className={`${classes.field} ${classes.gutter} ${classes.measurements} ${classes.root}`}>
                    <TextField source={record.width ? 'width' : 'default'}
                        label="resources.articles.fields.width"
                        className={`${classes.field} ${classes.gutter}`}
                    />
                    <TextField source={record.height ? 'height' : 'default'}
                        label="resources.articles.fields.height"
                        className={`${classes.field} ${classes.gutter}`}
                    />
                    <TextField source={record.length ? 'length' : 'default'}
                        label="resources.articles.fields.length"
                        className={`${classes.field} ${classes.gutter}`}
                    />
                </SimpleShowLayout>
                <TextField source={record.manufacturer ? 'manufacturer' : 'default'}
                    label="resources.articles.fields.manufacturer"
                    className={`${classes.field} ${classes.gutter}`}
                />
                <TextField source={record.storagearea && record.storagearea.name ? 'storagearea.full_name' : 'default'}
                    label="resources.articles.fields.warehouse_place"
                    className={`${classes.field} ${classes.gutter}`}
                />
                {record.is_scratch ? (
                    <BooleanField source="is_scratch"
                        label="resources.articles.fields.is_scratch"
                        className={`${classes.field} ${classes.gutter}`}
                    />
                ) : (
                    <TextField source="default"
                        label="resources.articles.fields.is_scratch"
                        className={`${classes.field} ${classes.gutter}`}
                    />
                )}
                <FunctionField
                    render={record => record.thread.num_comments || '-'}
                    label="resources.articles.fields.comments"
                    sortable={false}
                    className={`${classes.field} ${classes.gutter}`}
                />
                {record.is_scratch && record.damages && record.damages.length > 0 ? (
                    <LightboxField
                        source="damages"
                        label="resources.articles.fields.damages_photos"
                        className={`${classes.field} ${classes.gutter} ${classes.lightbox}`}
                        addLabel={true}
                    />
                ) : (
                    <TextField source="default"
                        label="resources.articles.fields.damages_photos"
                        className={`${classes.field} ${classes.gutter} ${classes.img}`}
                    />
                )}
                {record.photos && record.photos.length > 0 ? (
                    <LightboxField
                        source="photos"
                        label="resources.articles.fields.photo"
                        className={`${classes.field} ${classes.gutter} ${classes.lightbox}`}
                        addLabel={true}
                    />
                ) : (
                    <TextField source="default"
                        label="resources.articles.fields.photo"
                        className={`${classes.field} ${classes.gutter} ${classes.img}`}
                    />
                )}
                {record.colors ? (
                    <Container source="colors" addLabel={true} className={`${classes.field} ${classes.gutter}`}>
                        {record.colors.join(', ')}
                    </Container>
                ) : (
                    <TextField source="default"
                        label="resources.articles.fields.colors"
                        className={`${classes.field} ${classes.gutter}`}
                    />
                )}

                <UpdatedAtField className={`${classes.field} ${classes.gutter}`} />

                {isEmbedComments && <CommentList record={record} className={classes.gutter} />}
            </SimpleShowLayout>
        );
    }
}

export const BodyMobileGrid = withStyles(styles)(UnconnectedBodyMobileGrid);

const MobileGrid = ({ customButtons, permissions, ...props }) => (
    <BaseMobileGrid {...props}
        permissions={permissions}
        title={<TitleMobileGrid />}
        body={<BodyMobileGrid {...props} />}
        editable={
            !customButtons
            && permissions !== ROLE_MAIN_CUSTOMER
            && permissions !== ROLE_CUSTOMER
        }
        deletable={
            !customButtons && (
                permissions === ROLE_OFFICE_EMPLOYEE
                || permissions === ROLE_SUPER_ADMIN
                || permissions === ROLE_AGENT
            )
        }
        isShow={
            !customButtons && (
                permissions === ROLE_MAIN_CUSTOMER
                || permissions === ROLE_CUSTOMER
            )
        }
        customButtons={
            customButtons || (
                permissions !== ROLE_COLLECTOR_GUY && <AddToCartButton />
            )
        }
    />
);

export default MobileGrid;
