import React, { createElement } from 'react';
import { Resource as BaseResource } from 'react-admin';

const withPermissions = element =>
    ({ permissions, ...props }) => permissions ? createElement(element, {
        ...props,
        permissions,
    }) : null;

const Resource = ({ create, edit, list, show, ...props }) => {
    const resource = {
        create: !!create ? withPermissions(create) : undefined,
        edit: !!edit ? withPermissions(edit) : undefined,
        list: !!list ? withPermissions(list) : undefined,
        show: !!show ? withPermissions(show) : undefined,
    };

    return (
        <BaseResource {...resource} {...props} />
    );
};

export default Resource;
