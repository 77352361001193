import { withStyles } from '@material-ui/core';
import { SearchInput } from 'react-admin';

export default withStyles({
    input: {
        marginTop: 16,
    },
}, {
    name: 'SearchInput',
})(SearchInput);
