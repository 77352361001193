import { withStyles } from '@material-ui/core';
import React from 'react';
import { List, Responsive, TextField } from 'react-admin';

import {
    AddressField,
    Datagrid,
    FullNameField,
    ListActions as CustomerActions,
    ListFilter as CustomerFilter,
    UpdatedAtField,
} from '../components';
import styles from '../styles';
import {
    ROLE_CUSTOMER,
    ROLE_WAREHOUSE_MANAGER,
} from '../users/roles';
import MobileGrid from './MobileGrid';

const CustomerList = ({ classes, permissions, ...props }) => (
    <List {...props}
          filters={<CustomerFilter />}
          perPage={25}
          bulkActionButtons={false}
          filter={{
              'parent][isNull': 1
          }}
          exporter={false}
    >
        <Responsive
            medium={
                <MobileGrid
                    editable={
                        permissions !== ROLE_WAREHOUSE_MANAGER && permissions !== ROLE_CUSTOMER
                    }
                    deletable={
                        permissions !== ROLE_WAREHOUSE_MANAGER && permissions !== ROLE_CUSTOMER
                    }
                />
            }
            large={
                <Datagrid>
                    <FullNameField sortBy="user.surname" />
                    <TextField label="resources.address.fields.company"
                               source="address.company"
                               className={classes.nowrap}
                    />
                    <AddressField showName={false} sortBy="address" />
                    <AddressField source="invoice_address" sortBy="invoiceAddress" />
                    <UpdatedAtField headerClassName={classes.minWidth} sortBy="updatedAt" />
                    <CustomerActions headerClassName={classes.minWidth}
                                     editable={
                                         permissions !== ROLE_WAREHOUSE_MANAGER && permissions !== ROLE_CUSTOMER
                                     }
                                     deletable={
                                         permissions !== ROLE_WAREHOUSE_MANAGER && permissions !== ROLE_CUSTOMER
                                     }
                    />
                </Datagrid>
            }
        />
    </List>
);
export default withStyles(styles)(CustomerList);
