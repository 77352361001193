import { format } from 'date-fns';
import { withStyles } from '@material-ui/core';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import React, { Component, Fragment } from 'react';
import { Button, GET_LIST, downloadCSV, withDataProvider } from 'react-admin';
import { compose } from 'recompose';

import generalStyles from '../styles';

import { DialogForm } from './index';
import Container from './Container';
import { DateInput } from './DateInput';

const styles = theme => {
    const { container, input } = generalStyles(theme);
    const smBreakpoint = theme.breakpoints.up('sm');

    return {
        container,
        input,
        fullWidth: {
            width: `calc(100% + ${theme.spacing.unit * 4}px)`,
            [smBreakpoint]: {
                width: `calc(100% + ${theme.spacing.unit * 6}px)`,
            },
        },
        minWidth: {
            minWidth: 100,
        },
    };
};

class ExportRecords extends Component {

    state = {
        showModal: false,
        from: '',
        to: '',
    };

    download = async () => {
        const { dataProvider } = this.props;
        const { from, to } = this.state;

        const { data: reports } = await dataProvider(
            GET_LIST,
            `reports?limit=10000${from && `&filter[period][created_at]=${from},${to}`}`,
            {},
        );

        if (reports.length) {
            const data = reports.map(el => ({
                'Project': el.project,
                'Number_of_articles': el.number_of_articles,
                'QRcode_ID\'s': el.barcodes,
                'User': el.full_name,
                'Date': new Date(el.update_date_of_article).toLocaleString(),
                'Activity': el.activity,
                'Old_storage_area': el.old_storage_area,
                'New_storage_area': el.new_storage_area,
                'Article_status': el.article_status,
                'Qm_of_Project': el.square_meters,
                'Cbm_of_Project': el.cubic_meters,
                'Transfer_tools': el.transfer_tools,
                'Old_loading_points': el.old_loading_points,
                'New_loading_points': el.new_loading_points,
                'Old_unloading_points': el.old_unloading_points,
                'New_unloading_points': el.new_unloading_points,
            }));
            const csv = convertToCSV({
                data,
                fields: [
                    'Project',
                    'Number_of_articles',
                    'QRcode_ID\'s',
                    'User',
                    'Date',
                    'Activity',
                    'Old_storage_area',
                    'New_storage_area',
                    'Article_status',
                    'Qm_of_Project',
                    'Cbm_of_Project',
                    'Transfer_tools',
                    'Old_loading_points',
                    'New_loading_points',
                    'Old_unloading_points',
                    'New_unloading_points',
                ],
            });

            downloadCSV(csv, `Report${from && ` ${from} - ${to}`}`);
        } else {
            alert('There are no reports');
        }

        this.setState({
            showModal: false,
            from: '',
            to: '',
        });
    };

    handleChangeFrom = (event, from) => {
        this.setState({
            from: format(from, 'yyyy-MM-dd'),
        });
    };

    handleChangeTo = (event, to) => {
        this.setState({
            to: format(to, 'yyyy-MM-dd'),
        });
    };

    handleClosePublicDialog = () => {
        this.setState({
            showModal: false,
        });
    };

    handleShowPublishClick = () => {
        this.setState({
            showModal: true,
        });
    };

    render() {
        const { classes } = this.props;
        const { showModal, from, to } = this.state;

        return (
            <Fragment>
                <Button onClick={this.handleShowPublishClick} label="ra.action.export">
                    <GetAppIcon />
                </Button>
                <DialogForm form="export-form"
                            onClose={this.handleClosePublicDialog}
                            onSubmit={this.download}
                            size="sm"
                            showDialog={showModal}
                            title="export.head"
                            customSaveButton={
                                <Button autoFocus
                                        disabled={!((from && to) || (!from && !to))}
                                        label="ra.action.export"
                                >
                                    <GetAppIcon />
                                </Button>
                            }
                >
                    <Container className={`${classes.container} ${classes.fullWidth}`}
                               isReplaceProps={true}
                    >
                        <DateInput className={`${classes.input} ${classes.minWidth}`}
                                   label="export.from"
                                   onChange={this.handleChangeFrom}
                                   source="from"
                                   options={{
                                       format: 'dd.MM.yyyy',
                                   }}
                        />
                        <DateInput className={`${classes.input} ${classes.minWidth}`}
                                   label="export.to"
                                   onChange={this.handleChangeTo}
                                   source="to"
                                   options={{
                                       format: 'dd.MM.yyyy',
                                   }}
                        />
                    </Container>
                </DialogForm>
            </Fragment>
        );
    }

}

const enhance = compose(
    withDataProvider,
    withStyles(styles),
);

export default enhance(ExportRecords);
