import React from 'react';
import { Route } from 'react-router-dom';

import ArticleMove from './articles/ArticleMove';
import { Configuration } from './configuration';
import ForgotPassword from './layout/ForgotPassword';
import RecoveryPassword from './layout/RecoveryPassword';
import ProfileEdit from './profile';
import Revert from './revert/Revert';

export default [
    <Route exact path="/profile" component={ProfileEdit} />,
    <Route exact path="/configuration" component={Configuration} />,
    <Route exact path="/transfer/:articleId" component={ArticleMove} />,
    <Route exact path="/revert" component={Revert} />,
    <Route exact
           path="/forgot-password"
           render={props => <ForgotPassword {...props} />}
           noLayout
    />,
    <Route exact
           noLayout
           path="/recovery-password/:confirmation_token"
           render={props => <RecoveryPassword {...props} />}
    />,
];
