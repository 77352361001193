import { withStyles } from '@material-ui/core';
import { Datagrid } from 'react-admin';

export default withStyles({
    headerCell: {
        whiteSpace: 'nowrap',
    },
    rowCell: {
        paddingTop: 8,
        paddingBottom: 8,
    },
}, {
    name: 'Datagrid',
})(Datagrid);

