import { get } from 'lodash';
import React, { Children, Fragment, cloneElement } from 'react';

const TreeNodeContent = ({ children, node, source, ...props }) => {
    if (source) {
        node = get(node, source);
    }

    return (
        <Fragment>
            {cloneElement(Children.only(children), { node, ...props })}
        </Fragment>
    );
};

export default TreeNodeContent;
