import { withStyles } from '@material-ui/core';
import React, { cloneElement } from 'react';
import { DeleteButton, EditButton, ShowButton } from 'react-admin';
import RestoreButton from './RestoreButton';
import styles from '../styles';

import ExportRecord from './ExportRecord';

const UnconnectedListActions = ({
                                    headerClassName,
                                    className,
                                    classes,
                                    deleteButton,
                                    deletable,
                                    editable,
                                    isShow,
                                    exportable,
                                    customButtons,
                                    ...props
                                }) => {
    const { deleted_at, deletable: active  } = props.record;

    return (
        <div className={`${className} ${classes.flex}`}>
            {deletable && !deleted_at && <DeleteButton label={deleteButton} {...props} undoable={false} disabled={active === false}/>}
            {editable && !deleted_at && <EditButton {...props} />}
            {deleted_at && <RestoreButton {...props}/>}
            {isShow && <ShowButton {...props} />}
            {exportable && <ExportRecord {...props} />}
            {customButtons && 
                (customButtons.length 
                    ? customButtons.map(customButton => React.cloneElement(customButton, props)) 
                    : cloneElement(customButtons, props)
                )
            }
        </div>
    );
};

const ListActions = withStyles(styles)(UnconnectedListActions);

ListActions.defaultProps = {
    deletable: true,
    editable: true,
    isShow: false,
    exportable: false,
};

export default ListActions;
