import { get } from 'lodash';
import { ListItem } from '@material-ui/core';
import React from 'react';

import TreeNodeWithChildren from './TreeNodeWithChildren';
import TreeNodeContent from './TreeNodeContent';

const TreeNode = ({ children, node, source, ...props }) => {
    const nodeChildren = get(node, 'children', []);
    const nodeId = get(node, source ? `${source}.id` : 'id');

    return (
        <ListItem dense disableGutters>
            {nodeChildren.length > 0 ? (
                <TreeNodeWithChildren key={`TreeNodeWithChildren_${nodeId}`}
                                      {...props}
                                      node={node}
                                      source={source}
                >
                    {children}
                </TreeNodeWithChildren>
            ) : (
                <TreeNodeContent key={`TreeNodeContent_${nodeId}`}
                                 {...props}
                                 node={node}
                                 source={source}
                >
                    {children}
                </TreeNodeContent>
            )}
        </ListItem>
    );
};

export default TreeNode;
