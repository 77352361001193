import { get } from 'lodash';
import React from 'react';
import { translate } from 'react-admin';

import { addressToString } from '../../helpers';
import { DELIVERY, PICKUP, DISPOSAL } from '../../deliveries/deliveryTypes';

const DEFAULT_SOURCE = 'address';

const UnconnectedAddressField = ({ record, showName, source, translate }) => {
    const address = get(record, source);
    const user = get(record, source);
    const name = get(record, 'surname');

    let type;

    if (address || user) {
        type = DELIVERY
    } else if (name) {
        type = PICKUP
    } else {
        type = DISPOSAL
    }


    const renderText = () => {
        switch (type) {
            case DELIVERY: return addressToString({ address, user, showName, translate });
            case PICKUP: return name;
            case DISPOSAL: return '-';
            default: return addressToString({ address, user, showName, translate });
        }
    }

    return (
        <span>
            {renderText()}
        </span>
    );
};

const AddressField = translate(UnconnectedAddressField);

AddressField.defaultProps = {
    record: {},
    source: DEFAULT_SOURCE,
    addLabel: true,
    showName: true,
};

export default AddressField;
