import React from 'react';
import { Edit, WithPermissions } from 'react-admin';

import { FullNameField } from '../components';

import ProfileForm from './ProfileForm';

const ProfileTitle = ({ record }) => (
    <span>
        #{record.id}. <FullNameField record={record} />
    </span>
);

const ProfileEdit = ({ permissions, staticContext, ...props }) => (
    <Edit title={<ProfileTitle />}
          undoable={false}
          id={JSON.parse(localStorage.getItem("user")).id}
          resource="users"
          basePath="users"
          {...props}
    >
        <ProfileForm permissions={permissions} />
    </Edit>
);

export default ({ location, match }) => (
    <WithPermissions
        authParams={{ key: match.path, params: match.params }}
        // location is not required but it will trigger a new permissions check if specified when it changes
        location={location}
        render={({ permissions }) => <ProfileEdit permissions={permissions} match={match} /> }
    />
);
