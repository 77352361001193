import React from "react";
import outsourcing from './index';
import {Button} from "react-admin";

const OutsourceButton = (...props) => {
    const {
        record: {articles, id},
        scannedBarcodes
    } = props[0];
    const outsource = articles.filter(
        ({barcodes}) =>
            !barcodes.filter(({id}) => !scannedBarcodes.includes(id)).length
    );
    return (
        <Button
            variant="contained"
            label="ra.action.outsource"
            disabled={!outsource.length}
            onClick={() => props[0].handleOutsource(id, outsource)}
        >
            <outsourcing.icon/>
        </Button>
    );
};

export default React.memo(OutsourceButton);
