import React, { useState } from 'react';
import { withTranslate, withDataProvider, CREATE } from 'react-admin';
import { TextField, Button, Card, CardContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { push } from 'react-router-redux';

const WarehouseCreateForm = ({ translate, dataProvider, dispatch }) => {
    const [warehouseName, setWarehouseName] = useState('');
    const [error, setError] = useState('');

    const validation = name => {
        if (name.length > 255) {
            setError(translate('ra.message.error_long_name'));
            return false;
        }

        if (name.length === 0) {
            setError(translate('ra.message.error_empty_name'));
            return false;
        }

        setError('');
        return true;
    }

    const handleAddWarehouse = async event => {
        event.preventDefault();

        if (validation(warehouseName)) {
            await dataProvider(CREATE, 'storageareas', {
                data: {
                    name: warehouseName,
                    area: 0,
                    children: [],
                }
            })

            dispatch(push('/storageareas'))
        }
    }

    return (
        <Card style={{ marginBottom: '8px' }}>
            <CardContent>
                <form onSubmit={handleAddWarehouse}>
                    <TextField
                        error={!!error}
                        type="text"
                        name="name"
                        label={translate('resources.warehouses.fields.name')}
                        placeholder={translate('resources.warehouses.fields.name')}
                        value={warehouseName}
                        onChange={e => setWarehouseName(e.target.value)}
                        variant="outlined"
                        helperText={error}
                    />
                    <Button type="submit" color="primary">{translate('ra.action.create')}</Button>
                </form>
            </CardContent>
        </Card >
    );
};

WarehouseCreateForm.propTypes = {

    // from withDataProvider
    dataProvider: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,

    // from withTranslate
    locale: PropTypes.string.isRequired,
    translate: PropTypes.func.isRequired,
}

const enhance = compose(
    withDataProvider,
    withTranslate
)

export default enhance(WarehouseCreateForm);