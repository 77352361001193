import React from 'react';
import { Create } from 'react-admin';

import UserForm from './UserForm';

const UserCreate = ({ permissions, ...props }) => (
    <Create {...props}>
        <UserForm permissions={permissions} />
    </Create>
);

export default UserCreate;
