import { withStyles, withWidth } from '@material-ui/core';
import React, { Component } from 'react';
import {
    BooleanInput,
    Button,
    ReferenceInput,
    SelectInput,
    TextInput, translate,
    withDataProvider,
    REDUX_FORM_NAME
} from 'react-admin';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import { ColorArray, FileInput, Measurement, Forklift as ForkliftIcon, AutocompleteInput } from '../components';
import { required } from '../validations';
import generalStyles from '../styles';
import { showAlertHelper } from '../helpers';
import {
    ROLE_AGENT, ROLE_CUSTOMER, ROLE_MAIN_CUSTOMER,
    ROLE_OFFICE_EMPLOYEE,
    ROLE_SUPER_ADMIN,
    ROLE_WAREHOUSE_MANAGER,
} from '../users/roles';

import { change } from 'redux-form';
import AddOtherCategoryButton from './AddOtherCategoryButton';
import { OBJECT_TYPE, PALLET_TYPE, CARTON_TYPE } from './articleTypes';
import WarehouseSelectInput from './WarehouseSelectInput';
import { OUTSOURCED } from './statuses';

const styles = theme => ({
    ...generalStyles(theme),
    isScratch: {
        marginTop: 16,
        marginBottom: 8,
    },
    categoryContainer: {
        display: 'flex',
        alignItems: 'baseline',
    },
    category: {
        flex: '1 0 1px',
    },
    type: {
        display: 'flex',
        flexDirection: 'column',
    },
    warehouses: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    measurement: {
        flex: '1 0 calc(33.33% - 48px)',
    },
    smallWidth: {
        minWidth: 100,
    },
    required: {
        '& label': {
            fontWeight: 'bold',
        },
    },
});

const CategoryAutocomplete = (...props) => (
    <AutocompleteInput
        {...props[0]}
        choices={
            props[0].createdCategory
                ? [props[0].createdCategory, ...props[0].choices]
                : props[0].choices
        }
        onSuggestionsFetchRequested={props[0].changeCategoryName}
        options={{
            fullWidth: true,
        }}
    />
);

class ArticleFormFields extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        const { record: { type, is_scratch, storagearea } } = this.props;

        this.state = {
            type,
            isScratch: is_scratch,
            isInWarehouse: !!storagearea,
            createdCategory: null,
            categoryName: '',
        };
    }

    static getDerivedStateFromProps({ record }, { storagearea }) {

        const isOpenQrCodeReader = localStorage.getItem('is_next') !== null;
        const changedStoragearea = localStorage.getItem('changed_storage_area') || undefined;

        return {
            storagearea: storagearea || isOpenQrCodeReader || !record.storagearea ?
                (changedStoragearea && parseInt(changedStoragearea)) || storagearea : undefined,
            isInWarehouse: !!record.storagearea,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    };

    componentDidUpdate() {
        if (!this.props.initial) { showAlertHelper.purgeState(); return; }

        const initialPhotos = this.props.initial.photos ? this.props.initial.photos.map(url => {
            const pathArray = url.split('/');
            return {
                src: url,
                title: pathArray[pathArray.length - 1]
            }
        }) : [];

        const initialDamages = this.props.initial.damages ? this.props.initial.damages.map(url => {
            const pathArray = url.split('/');
            return {
                src: url,
                title: pathArray[pathArray.length - 1]
            }
        }) : [];

        showAlertHelper.saveDefaultState({
            ...this.props.initial,
            photos: initialPhotos,
            damages: initialDamages,
        });

        showAlertHelper.saveCurrentState(this.props.current);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleAddCategory = category => {
        const { change, formName } = this.props;
        if (this._isMounted) {
            this.setState({ createdCategory: category, categoryName: '' });
            change(formName, "category.id", category.id);
        }
    };

    handleAfterUploadFile = () => {
        if (this._isMounted) {
            this.setState({ isLoading: false });
        }
    };

    handleBeforeUploadFile = () => {
        if (this._isMounted) {
            this.setState({ isLoading: true });
        }
    };

    handleChangeQrCodeStorageArea = value => {
        const { storagearea } = JSON.parse(value);

        this.handleChangeStorageArea(null, storagearea);
    };

    handleChangeStorageArea = (event, storagearea) => {
        const { onChangeStorageArea } = this.props;

        if (this._isMounted) {
            onChangeStorageArea(storagearea);

            this.setState({ storagearea });
        }
    };

    handleChangeType = (event, type) => {
        if (this._isMounted) {
            this.setState({ type });
        }
    };

    handleScratch = (event, isScratch) => {
        if (this._isMounted) {
            if (!isScratch) {
                const { onChangeDamages } = this.props;
                onChangeDamages([]);
            }
            this.setState({ isScratch });
        }
    };

    unsetCreatedCategory = () => {
        this.setState({
            createdCategory: null,
            categoryName: '',
        });
    }

    changeCategoryName = ({ value }) => {
        this.setState({ categoryName: value });
    }

    onlyInteger = value => {
        const { translate } = this.props;
        if (value && !String(value).match(/^\d+$/)) {
            return translate('resources.articles.integer_error');
        }
        return undefined;
    }

    render() {
        const {
            classes,
            onChangePhotos,
            onChangeDamages,
            permissions,
            record,
            resource,
            warehouses,
            translate,
        } = this.props;
        const { isScratch, type, storagearea, isInWarehouse, createdCategory, categoryName } = this.state;

        return (
            <div className={classes.container}>
                <div className={classes.input}>
                    <div className={classes.container}>
                        <SelectInput source="type"
                            resource={resource}
                            choices={
                                [CARTON_TYPE, PALLET_TYPE, OBJECT_TYPE]
                                    .map(type => ({
                                        id: type,
                                        name: translate(`resources.articles.type.${type}`),
                                    }))
                            }
                            onChange={this.handleChangeType}
                            validate={required(resource, 'type')}
                            className={`${classes.input} ${classes.smallWidth} ${classes.required}`}
                        />
                        <TextInput source="name"
                            resource={resource}
                            className={`${classes.input} ${classes.smallWidth}`}
                        />
                    </div>
                </div>

                <div className={`${classes.input} ${classes.categoryContainer}`}>
                    <div className={classes.category}>
                        <ReferenceInput resource={resource}
                            record={record}
                            allowEmpty={true}
                            label="resources.articles.fields.category"
                            source="category.id"
                            //defaultValue={category && category.id}
                            sort={{ field: 'name', order: 'ASC' }}
                            reference="article-categories"
                            className={type === OBJECT_TYPE ? classes.required : ''}
                            validate={type === OBJECT_TYPE ? required(resource, 'category') : null}
                            perPage={3000}
                            onChange={this.unsetCreatedCategory}
                        >
                            <CategoryAutocomplete
                                createdCategory={createdCategory}
                                changeCategoryName={this.changeCategoryName}
                            />
                        </ReferenceInput>
                    </div>
                    <AddOtherCategoryButton onAdd={this.handleAddCategory} categoryName={categoryName} />
                </div>

                <div className={classes.input}>
                    <div className={classes.container}>
                        <Measurement source="width"
                            resource={resource}
                            className={`${classes.input} ${classes.measurement} ${classes.smallWidth}`}
                            measure="cm"
                            integer
                            validate={this.onlyInteger}
                        />
                        <Measurement source="height"
                            resource={resource}
                            className={`${classes.input} ${classes.measurement} ${classes.smallWidth}`}
                            measure="cm"
                            integer
                            validate={this.onlyInteger}
                        />
                        <Measurement source="length"
                            resource={resource}
                            className={`${classes.input} ${classes.measurement} ${classes.smallWidth}`}
                            measure="cm"
                            integer
                            validate={this.onlyInteger}
                        />
                    </div>
                </div>

                <TextInput source="manufacturer" resource={resource} className={classes.input} />

                {(
                    permissions === ROLE_WAREHOUSE_MANAGER ||
                    permissions === ROLE_AGENT ||
                    permissions === ROLE_SUPER_ADMIN ||
                    permissions === ROLE_OFFICE_EMPLOYEE
                ) && (
                        <div className={`${classes.input} ${classes.categoryContainer}`}
                            style={{ flex: '1 0 calc(100% - 48px)' }}>
                            <WarehouseSelectInput
                                className={[
                                    classes.category,
                                    classes.warehouses,
                                    permissions === ROLE_WAREHOUSE_MANAGER && classes.required,
                                ].filter(Boolean).join(' ')}
                                permissions={permissions}
                                record={record}
                                resource={resource}
                                warehouses={warehouses}
                                defaultValue={storagearea}
                                onChange={this.handleChangeStorageArea}
                                onConfirmQrCode={!isInWarehouse && this.handleChangeQrCodeStorageArea}
                                disabled={!!isInWarehouse}
                                validate={
                                    permissions === ROLE_WAREHOUSE_MANAGER
                                        || (
                                            permissions !== ROLE_CUSTOMER
                                            && permissions !== ROLE_MAIN_CUSTOMER
                                            && record.status === OUTSOURCED
                                        ) ? required(resource, 'warehouse_place') : null
                                }
                            />
                            {isInWarehouse && (
                                <Button component={Link} to={`/transfer/${record.id}`} label="ra.action.transfer">
                                    <ForkliftIcon />
                                </Button>
                            )}
                        </div>
                    )}

                <div className={classes.input}>
                    <BooleanInput source="is_scratch"
                        onChange={this.handleScratch}
                        resource={resource}
                        className={classes.isScratch}
                    />
                    {isScratch && (
                        <FileInput resource="documents"
                            record={record}
                            source="damages"
                            label="resources.articles.fields.damages"
                            onBeforeUpload={this.handleBeforeUploadFile}
                            onAfterUpload={this.handleAfterUploadFile}
                            onChange={onChangeDamages}
                            accept={[
                                'image/x-png',
                                'image/gif',
                                'image/jpeg',
                                'image/png',
                            ].join(',')}
                            className={`${classes.required}`}
                            validate={required('articles', 'damages')}
                            isImageField={true}
                        />
                    )}
                </div>

                <FileInput resource="documents"
                    record={record}
                    source="photos"
                    label="resources.articles.fields.photos"
                    onBeforeUpload={this.handleBeforeUploadFile}
                    onAfterUpload={this.handleAfterUploadFile}
                    onChange={onChangePhotos}
                    accept={[
                        'image/x-png',
                        'image/gif',
                        'image/jpeg',
                        'image/png',
                    ].join(',')}
                    className={[
                        classes.input,
                        type === OBJECT_TYPE && classes.required,
                    ].filter(Boolean).join(' ')}
                    validate={type === OBJECT_TYPE ? required('articles', 'photos') : undefined}
                    isImageField={true}
                />

                <ColorArray source="colors" record={record} resource={resource} className={classes.input} />
            </div>
        );
    }

}

const mapStateToProps = state => ({
    initial: state.form[REDUX_FORM_NAME]?.initial,
    current: state.form[REDUX_FORM_NAME]?.values,
});

const enhance = compose(
    withStyles(styles),
    translate,
    withDataProvider,
    withWidth(),
    connect(
        mapStateToProps,
        { change },
    ),
);

ArticleFormFields.defaultProps = {
    formName: REDUX_FORM_NAME,
};

export default enhance(ArticleFormFields);
