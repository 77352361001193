import { get, range } from 'lodash';
import { Card, CardActions, CardContent, CardHeader, withStyles, withWidth } from '@material-ui/core';
import React, { Component } from 'react';
import { Pagination, GET_LIST, DELETE_MANY, showNotification, translate, withDataProvider } from 'react-admin';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import { BarcodeView, Datagrid, QRCodeField } from '../components';
import generalStyles from '../styles';

import BarcodeListActions from './BarcodeListActions';
import BarcodeActions from './BarcodeActions';

const styles = theme => ({
    ...generalStyles(theme),
    content: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    valueBarcode: {
        display: 'block',
        wordBreak: 'break-all',
        paddingTop: 8,
        paddingBottom: 8,
        color: theme.palette.primary.main,
    },
    align: {
        right: {
            justifyContent: 'flex-end',
        }
    }
});

const BarcodePagination = props => <Pagination rowsPerPageOptions={[4, 24, 48, 240, 480, 960]} {...props} />

const ValueBarcode = ({ className, host, record, route, source }) => {
    const value = get(record, source);

    return (
        <Link to={`${route}${value}`} className={className}>
            {`${host}${route}${value}`}
        </Link>
    );
};

class UnconnectedBarcodeList extends Component {

    _isMounted = false;
    state = {
        barcodes: [],
        page: 1,
        perPage: 24,
        selectedIds: [],
        isOpenDeleteDialog: false,
    };

    componentDidMount() {
        const { perPage } = this.state;
        this._isMounted = true;

        this.updateBarcodes(1, perPage, this.props.record);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleAddBarcode = () => {
        const { page, perPage } = this.state;
        this.updateBarcodes(page, perPage, this.props.record);
    };

    handleClickDeleteBarcodes = () => {
        this.setState({
            isOpenDeleteDialog: true,
        });
    };

    handleCloseDeleteDialog = () => {
        this.setState({
            isOpenDeleteDialog: false,
        });
    };

    handleConfirmDeleteDialog = async () => {
        const { dataProvider, dispatch } = this.props;
        const { barcodes, selectedIds } = this.state;

        try {
            const { data: deletedBarcodes } = await dataProvider(DELETE_MANY, 'barcodes', {
                ids: barcodes.filter((_, index) => selectedIds.indexOf(index) > -1)
                    .map(({ id }) => id),
            });

            this.setState({
                barcodes: barcodes.filter(({ id: barcodeId }) =>
                    deletedBarcodes.findIndex(({ id: deletedBarcodeId }) =>
                        deletedBarcodeId === barcodeId) === -1,
                ),
                selectedIds: [],
            });
        } catch (e) {
            dispatch(showNotification('resources.barcodes.errors.delete_selected', 'warning'));
        }

        this.handleCloseDeleteDialog();
    };

    handleDeleteBarcode = ({ id: deletedBarcodeId }) => {
        const { barcodes: barcodesState } = this.state;
        const barcodes = barcodesState.filter(({ id }) => id !== deletedBarcodeId);

        this.setState({ barcodes });
    };

    handleSelectAllBarcodes = selectedIds => {
        this.setState({ selectedIds });
    };

    handleSelectBarcode = selectedId => {
        const { selectedIds } = this.state;
        const id = selectedIds.indexOf(selectedId);

        let newSelectedIds;

        if (id > -1) {
            newSelectedIds = selectedIds.filter(item => item !== selectedId);
        } else {
            newSelectedIds = selectedIds.concat(selectedId);
        }

        this.setState({ selectedIds: newSelectedIds });
    };

    handlePageChange = async page => {
        const { perPage } = this.state;
        await this.updateBarcodes(page, perPage, this.props.record);
    };

    handlePerPageChange = async perPage => {
        await this.updateBarcodes(1, perPage, this.props.record);
    };

    updateBarcodes = async (page, perPage, record) => {
        const { dataProvider } = this.props;
        const { data: barcodes, total } = await dataProvider(GET_LIST, 'barcodes', {
            filter: this.props.used ? {
                'article][isNotNull': 1,
                'project': record.id,
            } : {
                    'article][isNull': 1,
                    'project': record.id,
                },
            pagination: {
                page,
                perPage,
            },
        });

        if (this._isMounted) {
            this.setState({ barcodes, perPage, page, total });
        }
    };

    render() {
        const {
            classes,
            className,
            resource,
            translate,
            host,
            record,
            route,
            addable,
            deletable,
            width,
            used,
            showAddAndPrint,
        } = this.props;
        const { barcodes, selectedIds, isOpenDeleteDialog, page, perPage, total } = this.state;

        return (
            <Card className={className}>
                <CardHeader
                    title={
                        <div className={classes.header}>
                            {translate('resources.' + resource + '.fields.' + (used ? 'used_barcodes' : 'unused_barcodes'))}
                            <BarcodeListActions
                                inHeader
                                addable={addable}
                                deletable={deletable}
                                onAdd={this.handleAddBarcode}
                                record={record}
                                handleClickDeleteBarcodes={this.handleClickDeleteBarcodes}
                                isOpenDeleteDialog={isOpenDeleteDialog}
                                selectedIds={selectedIds}
                                confirmDeleteDialog={this.handleConfirmDeleteDialog}
                                closeDeleteDialog={this.handleConfirmDeleteDialog}
                                allPrintedBarcodes={this.allPrintedBarcodes}
                                setAllPrintedBarcodes={this.setAllPrintedBarcodes}
                                barcodes={barcodes}
                                host={host}
                                route={route}
                                addAndPrint={showAddAndPrint}
                            />
                        </div>
                    } />

                <CardContent className={classes.content}>
                    <Datagrid data={{ ...barcodes }}
                        ids={range(barcodes.length)}
                        currentSort={{
                            field: '',
                        }}
                        hasBulkActions={true}
                        onSelect={this.handleSelectAllBarcodes}
                        onToggleItem={this.handleSelectBarcode}
                        selectedIds={selectedIds}
                    >
                        <ValueBarcode source="id"
                            host={host}
                            route={route}
                            label="barcode.value"
                            className={classes.valueBarcode}
                        />
                        {width !== 'xs' && (
                            <QRCodeField source="id" prefix={host + route} size={32} view={
                                <BarcodeView project={record} size={248} />
                            } />
                        )}

                        <BarcodeActions source="id"
                            prefix={host + route}
                            deletable={deletable}
                            onDelete={this.handleDeleteBarcode}
                            project={record}
                            headerClassName={classes.minWidth}
                        />
                    </Datagrid>
                </CardContent>
                <CardActions>
                    <BarcodePagination total={total}
                        page={page}
                        perPage={perPage}
                        setPerPage={this.handlePerPageChange}
                        setPage={this.handlePageChange} />
                </CardActions>
                <BarcodeListActions
                    addable={addable}
                    deletable={deletable}
                    onAdd={this.handleAddBarcode}
                    record={record}
                    handleClickDeleteBarcodes={this.handleClickDeleteBarcodes}
                    isOpenDeleteDialog={isOpenDeleteDialog}
                    selectedIds={selectedIds}
                    confirmDeleteDialog={this.handleConfirmDeleteDialog}
                    closeDeleteDialog={this.handleConfirmDeleteDialog}
                    allPrintedBarcodes={this.allPrintedBarcodes}
                    setAllPrintedBarcodes={this.setAllPrintedBarcodes}
                    barcodes={barcodes}
                    host={host}
                    route={route}
                />
            </Card>
        );
    }

    setAllPrintedBarcodes = allPrintedBarcodes => {
        this.allPrintedBarcodes = allPrintedBarcodes;
    };

}

const enhance = compose(
    withStyles(styles),
    withWidth(),
    translate,
    withDataProvider,
);

const BarcodeList = enhance(UnconnectedBarcodeList);

BarcodeList.defaultProps = {
    deletable: true,
    addable: true,
};

export default BarcodeList;
