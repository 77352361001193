import { get, debounce } from 'lodash';
import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { SimpleForm, TextInput, Toolbar, maxLength, translate, withDataProvider, GET_ONE, UPDATE } from 'react-admin';
import { compose } from 'recompose';

import { ToolbarContainer } from '../components';
import generalStyles from '../styles';
import { required } from '../validations';

import Structure from './Structure';
import { nextUniqueKey } from '../helpers';

const styles = theme => ({
    container: {
        width: '100%',
        maxWidth: theme.breakpoints.values['md'],
    },
    structure: {
        marginTop: theme.spacing.unit * 3,
    },
    spacer: generalStyles(theme).spacer,
});

class WarehouseForm extends Component {

    constructor(props) {
        super(props);

        const { record } = this.props;

        this.state = {
            name: get(record, 'name'),
            children: get(record, 'children', [])
                .map(child => ({
                    ...child,
                    uniqueKey: nextUniqueKey(),
                })), 
            opened: [],
        };

        this.handleChangeName = debounce(this.handleChangeName, 200);
    }

    handleOpenSection = sectionId => {
        const { opened } = this.state;

        const newOpened = opened.includes(sectionId) ? opened.filter(item => item !== sectionId) : opened.concat(sectionId);

        this.setState({
            opened: newOpened
        })
    }

    handleChangeName = async ({ target: { value } }) => {

        const { dataProvider, record } = this.props;

        await dataProvider(UPDATE, 'storageareas', {
            id: record.id,
            data: {
                ...record,
                name: value
            }
        });

        this.setState({
            name: value,
        });
    };

    findStorageArea = (obj, storageArea) => {
        if (typeof obj === 'object'
            && obj.children
            && Array.isArray(obj.children)
            && obj.children.length > 0) {

            const area = obj.children.find(item => item.id === storageArea);

            if (area) {
                return area
            } else {
                let result;
                for (let child of obj.children) {
                    result = this.findStorageArea(child, storageArea);
                    if (result) {
                        break;
                    }
                }
                return result;
            }
        }
    }

    handlePreload = async storageId => {

        const { dataProvider } = this.props;

        const stateCopy = JSON.parse(JSON.stringify(this.state));

        const storageArea = this.findStorageArea(stateCopy, storageId);

        if (storageArea && storageArea.children && storageArea.children.length > 0) {

            const newChildren = await Promise.all(storageArea.children.map(async child => {
                if (!child.id) return { ...child };

                const { data } = await dataProvider(GET_ONE, 'storageareas', {
                    id: child.id
                })

                return {
                    ...child,
                    children: data.children,
                }
            }));

            storageArea.children = newChildren;

            this.handleChangeStructure(stateCopy.children);
        }
    }

    handleChangeStructure = children => {
        this.setState({ children });
    };

    handleSave = (savedData, redirect) => {
        const { save } = this.props;
        const { children } = this.state;
        const area = children.reduce((prevValue, childSection) => prevValue + childSection.area, 0);

        save({
            ...savedData,
            area,
            children,
        }, redirect);
    };

    render() {
        const { classes, record, resource, dataProvider, ...props } = this.props;
        const { name, children, opened } = this.state;

        return (
            <SimpleForm
                {...props}
                redirect={record.id ? false : 'edit'}
                record={record}
                resource={resource}
                toolbar={<Toolbar>
                    <ToolbarContainer maxWidth="md" hideSaveButton={true} />
                </Toolbar>}
            >
                <TextInput
                    source="name"
                    onChange={this.handleChangeName}
                    validate={[
                        required(resource, 'name'),
                        maxLength(255),
                    ]} />
                <Structure
                    children={children}
                    formSection={record}
                    className={`${classes.container} ${classes.structure}`}
                    name={name}
                    onChange={this.handleChangeStructure}
                    onExpand={this.handlePreload}
                    openSection={this.handleOpenSection}
                    openedSections={opened}
                />
            </SimpleForm>
        );
    }
}

export default compose(
    withStyles(styles),
    translate,
    withDataProvider
)(WarehouseForm);
