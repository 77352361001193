import { get } from 'lodash';
import { List, withStyles } from '@material-ui/core';
import React from 'react';

import BaseTree from './BaseTree';
import TreeNode from './TreeNode';
import TreeNodeContent from './TreeNodeContent';

const styles = theme => ({
    root: {
        flex: '1 0 auto',
    },
    list: {
        paddingLeft: theme.spacing.unit * 6,
    },
});

class TreeNodeWithChildren extends BaseTree {

    constructor(props) {
        super(props);

        const { node } = this.props;
        const tree = get(node, 'children', []);
        this.state = { tree };
    }

    render() {
        const { children, classes, node, source, ...props } = this.props;
        const { tree: nodeChildren } = this.state;
        const nodeId = get(node, source ? `${source}.id` : 'id');

        return (
            <div className={classes.root}>
                <TreeNodeContent key={`TreeNodeContent_${nodeId}`}
                                 {...props}
                                 node={node}
                                 source={source}
                >
                    {children}
                </TreeNodeContent>
                <List dense className={classes.list}>
                    {nodeChildren.map(child => {
                        const childId = get(child, source ? `${source}.id` : 'id');

                        return <TreeNode key={`TreeNode_${childId}`}
                                         {...props}
                                         node={child}
                                         source={source}
                                         onAdd={this.handleAdd}
                                         onEdit={this.handleEdit}
                                         onDelete={this.handleDelete}
                        >
                            {children}
                        </TreeNode>;
                    })}
                </List>
            </div>
        );
    };

}

export default withStyles(styles)(TreeNodeWithChildren);
