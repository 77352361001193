import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import { Button } from 'react-admin';

import { DialogForm, AddressInput } from '../components';

class AddressDialog extends Component {

    state = {
        showDialog: false,
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleClose = () => {
        this.setState({ showDialog: false });
    };

    render() {
        const { title, onAdd } = this.props;
        const { showDialog } = this.state;

        return (
            <Fragment>
                <Button label={title} onClick={this.handleClick}>
                    <AddCircleIcon />
                </Button>

                <DialogForm form="address-dialog-form"
                            title={title}
                            showDialog={showDialog}
                            onClose={this.handleClose}
                            onSubmit={onAdd}
                >
                    <AddressInput formName="address-dialog-form" />
                </DialogForm>
            </Fragment>
        );
    }

}

AddressDialog.defaultProps = {
    title: 'pos.add_address',
};

export default AddressDialog;
