import { format } from 'date-fns';
import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { Edit, GET_LIST, SimpleForm, Toolbar, withDataProvider } from 'react-admin';
import { compose } from 'recompose';
import httpClient from '../providers/httpClient';

import { ToolbarContainer } from '../components';
import styles from '../styles';

import DeliveryFields from './DeliveryFields';
import DeliveryTitle from './DeliveryTitle';
import { PICKUP, DELIVERY, DISPOSAL } from './deliveryTypes';

class UnconnectedDeliveryForm extends Component {

    state = {
        unloadingAddresses: [],
        isOtherAddress: false,
        changingAddress: null,
        deliveryType: null,
    };

    constructor(props) {
        super(props);

        const { record: { address } } = this.props;
        this.state.isPickup = !address;
    }

    componentDidMount = async () => {
        const { dataProvider, record: { articles, address, surname } } = this.props;
        const { data: unloadingPoints } = await dataProvider(GET_LIST, 'loadingpoints', {
            filter: {
                articles_ids: articles.map(({ id }) => id),
                isDestination: 1,
            },
        });

        const unloadingAddresses = unloadingPoints.map(({ address }) => address);
        const isOtherAddress = !address || unloadingAddresses.findIndex(({ id }) => id === address.id) === -1;

        let deliveryType;

        if (address) {
            deliveryType = DELIVERY
        } else if (surname) {
            deliveryType = PICKUP
        } else {
            deliveryType = DISPOSAL
        }


        this.setState({
            unloadingAddresses,
            isOtherAddress,
            deliveryType,
        });
    };

    handleChangeAddress = isOtherAddress => {
        this.setState({
            isOtherAddress,
            isPickup: false,
            changingAddress: isOtherAddress ? this.props.record.address : null,
        });
    };

    onChangePickup = () => {
        this.setState({
            isPickup: true,
        });
    };

    handleSave = async ({ address, date, articles, ...delivery }, redirect) => {

        const { save } = this.props;
        const { isOtherAddress, changingAddress, deliveryType } = this.state;
        let changed = false;
        if (isOtherAddress && changingAddress) {
            delete address.created_at;
            delete address.deleted_at;
            delete address.updated_at;
            delete changingAddress.created_at;
            delete changingAddress.deleted_at;
            delete changingAddress.updated_at;
            Object.keys(address).map(key => {
                if (address[key] !== (changingAddress[key] || '')) {
                    changed = true;
                }
                return key;
            });
            if (changed) {
                const { json } = await httpClient(
                    `${process.env.REACT_APP_API_URL}/deliveries?filter[id][neq]=${delivery.id}&filter[address]=${address.id}`,
                    {
                        method: 'GET',
                    },
                );
                if (json.length) {
                    delete address.id;
                }
            }
        }
        save({
            ...delivery,
            articles: articles.map(el => {
                delete el.project;
                return el;
            }),
            address: deliveryType === DELIVERY ? (isOtherAddress ? address : {
                id: address.id,
            }) : undefined,
            date: date ? format(new Date(date), 'yyyy-MM-dd\'T\'HH:mm:ssxxx') : undefined,
        }, (...res) => {
            if (changed) {
                httpClient(
                    `${process.env.REACT_APP_API_URL}/loadingpoints`,
                    {
                        method: 'POST',
                        body: JSON.stringify({
                            address: {
                                id :res[2].address.id,
                            },
                            project: {
                                id: res[2].articles[0].project
                            },
                            is_destination: true,
                        }),
                    },
                ); 
            }
        });
    };

    handleChangeType = deliveryType => {
        this.setState({
            deliveryType
        })
    }

    setChangingAddress = changingAddress => {
        this.setState({
            changingAddress
        });
    }

    render() {
        const { classes, permissions, record, dataProvider, ...props } = this.props;
        const { unloadingAddresses, isOtherAddress, isPickup, deliveryType } = this.state;

        return (
            <SimpleForm {...props}
                        redirect={false}
                        record={record}
                        toolbar={
                            <Toolbar classes={{ spacer: classes.spacer }}>
                                <ToolbarContainer maxWidth="lg" />
                            </Toolbar>
                        }
                        save={this.handleSave}
            >
                <DeliveryFields unloadingAddresses={unloadingAddresses}
                                isOtherAddress={isOtherAddress}
                                isPickup={isPickup}
                                onChangeAddress={this.handleChangeAddress}
                                onChangePickup={this.onChangePickup}
                                setChangingAddress={this.setChangingAddress}
                                currentType={deliveryType}
                                onChangeType={this.handleChangeType}
                />
            </SimpleForm>
        );
    }

}

const enhance = compose(
    withStyles(styles),
    withDataProvider,
);

const DeliveryForm = enhance(UnconnectedDeliveryForm);

const DeliveryEdit = ({ permissions, ...props }) => (
    <Edit title={<DeliveryTitle />} undoable={false} {...props}>
        <DeliveryForm permissions={permissions} />
    </Edit>
);

export default DeliveryEdit;
