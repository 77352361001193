import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import { Add as AddIcon, Cancel as CancelIcon, Save as SaveIcon } from '@material-ui/icons';
import React, { cloneElement, Component } from 'react';
import { Button, SimpleForm, translate } from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { isSubmitting, reset, submit } from 'redux-form';

import EmptySpan from './EmptySpan';

const styles = theme => ({
    dialog: {
        zIndex: 2,
    },
    content: {
        padding: 0,
    },
    form: {
        marginTop: -theme.spacing.unit * 3,
    },
    actions: {
        paddingLeft: 12,
        paddingRight: 12,
    },
});

class UnconnectedDialogForm extends Component {

    _altButtonClicked = false;

    handleCloseClick = event => {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }

        const { form, reset, onClose } = this.props;

        if (onClose) {
            onClose();
        }

        reset(form);
    };

    handleSaveClick = event => {
        event.stopPropagation();

        const { form, submit } = this.props;

        this._altButtonClicked = false;

        submit(form);
    };

    handleAltSaveClick = event => {
        event.stopPropagation();

        const { form, submit } = this.props;

        this._altButtonClicked = true;

        submit(form);
    }

    handleSubmit = async resource => {
        const { onSubmit, onAltSubmit } = this.props;

        (onAltSubmit && this._altButtonClicked) ? await onAltSubmit(resource) : await onSubmit(resource);

        this.handleCloseClick();
    };

    render() {
        const {
            children,
            classes,
            form,
            isLoading,
            record,
            resource,
            saveButton,
            showDialog,
            size,
            title,
            titleParams,
            translate,
            customSaveButton,
            altSaveButton,
            disableMargin,
        } = this.props;

        return (
            <Dialog fullWidth
                maxWidth={size}
                open={showDialog}
                onClose={this.handleCloseClick}
                className={classes.dialog}
                onClick={event => {
                    event.stopPropagation();
                }}
            >
                <DialogTitle>
                    {translate(title, titleParams)}
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <SimpleForm form={form}
                                onSubmit={this.handleSubmit}
                                toolbar={<EmptySpan />}
                                className={!disableMargin ? classes.form : ''}
                                record={record}
                                resource={resource}
                    >
                        {children}
                    </SimpleForm>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                        <CancelIcon />
                    </Button>
                    {altSaveButton && cloneElement(altSaveButton, {
                        onClick: this.handleAltSaveClick,
                        disabled: isLoading,
                        children: isLoading ?
                            <CircularProgress size={20} thickness={2} /> : altSaveButton.props.children,
                    })}
                    {customSaveButton ? cloneElement(customSaveButton, {
                        onClick: this.handleSaveClick,
                        disabled: isLoading,
                        children: isLoading ?
                            <CircularProgress size={20} thickness={2} /> : customSaveButton.props.children,
                    }) : (
                        saveButton === 'add' ? (
                            <Button label="ra.action.add" onClick={this.handleSaveClick} disabled={isLoading}>
                                {isLoading ? <CircularProgress size={20} thickness={2} /> : <AddIcon />}
                            </Button>
                        ) : (
                            saveButton === 'save' ? (
                                <Button label="ra.action.save" onClick={this.handleSaveClick} disabled={isLoading}>
                                    {isLoading ? <CircularProgress size={20} thickness={2} /> : <SaveIcon />}
                                </Button>
                            ) : null
                        )
                    )}
                </DialogActions>
            </Dialog>
        );
    }

}

const mapStateToProps = (state, { form }) => ({
    isSubmitting: isSubmitting(form)(state),
    isLoading: state.admin.loading > 0,
});

const enhance = compose(
    connect(mapStateToProps, {
        submit,
        reset,
    }),
    withStyles(styles),
    translate,
);

const DialogForm = enhance(UnconnectedDialogForm);

DialogForm.defaultProps = {
    saveButton: 'add',
    form: 'form',
    size: 'md',
};

export default DialogForm;
