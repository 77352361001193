import { withStyles } from '@material-ui/core';
import React from 'react';
import { BooleanField, SimpleShowLayout, TextField } from 'react-admin';

import {
    FullNameField,
    MobileGrid as BaseMobileGrid,
} from '../components';
import { guttersContainer, marginGutters } from '../helpers';

const TitleMobileGrid = ({ record }) => (
    <span>
        #{record.id}. <FullNameField record={record} />
    </span>
);

const styles = theme => ({
    root: {
        ...guttersContainer(theme),
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    field: {
        '&.ra-field': {
            ...marginGutters(theme),
            flex: '1 0 1px',
            minWidth: 240,
        },
    },
});

const UnconnectedBodyMobileGrid = ({ record, classes, className, isEmbedComments, ...props }) => (
    <SimpleShowLayout className={`${className} ${classes.root}`} record={record} {...props}>
        <TextField source="email" className={classes.field} />
        <TextField source="username" className={classes.field} />
        <TextField source="role" className={classes.field} />

        <BooleanField source="enabled" className={classes.field} />

        <TextField source="mobile_phone_number" className={classes.field} />
        <TextField source="phone_number" className={classes.field} />
        <TextField source="created_by.email" label="resources.users.fields.created_by" className={classes.field} />
    </SimpleShowLayout>
);

export const BodyMobileGrid = withStyles(styles)(UnconnectedBodyMobileGrid);

BodyMobileGrid.defaultProps = {
    isEmbedComments: false,
};

const MobileGrid = props => (
    <BaseMobileGrid {...props}
                    title={<TitleMobileGrid />}
                    body={<BodyMobileGrid />}
    />
);

export default MobileGrid;
