import { keyBy } from 'lodash';
import { withStyles } from '@material-ui/core';
import React from 'react';
import {
    Datagrid as BaseDatagrid,
    DatagridBody,
    DatagridRow as BaseDatagridRow,
    NumberField,
    TextField,
    translate,
} from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'recompose';

const optionsNumberFormat = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
};

const styles = {
    expandedHeaderRow: {
        height: 0,
    },
    expandedHeaderCell: {
        borderBottom: 'none',
        fontSize: 0,
    },
    spaceMaxWidth: {
        width: 100,
    },
    expandIconCell: {
        '&.hidden': {
            opacity: 0,
            '& > div': {
                cursor: 'default',
                pointerEvents: 'none',
            },
        },
    },
    noExpand: {
        marginLeft: 24,
        width: 'calc(100% - 24px)',
    },
};

const ExpandedPanel = ({ record: { children, customers } }) => children.length > 0 || customers.length > 0 ? (
    <WarehousesDatagrid warehouses={children} customers={customers} />
) : null;

const DatagridRow = ({ record, classes: { expandIconCell, ...classes }, ...props }) => {
    const hiddenClass = ((record.children && record.children.length > 0) || (record.customers && record.customers.length > 0)) ? '' : ' hidden';

    return (
        <BaseDatagridRow {...props} classes={{
            ...classes,
            expandIconCell: expandIconCell + hiddenClass,
        }} record={record} />
    );
};

const UnconnectedWarehousesDatagrid = ({ classes, warehouses, customers, locale, translate }) => {
    const expand = warehouses.some(({ children, customers }) => children.length > 0 || customers.length > 0)
        && customers.length === 0 ? <ExpandedPanel /> : undefined;
    const isRoot = warehouses.some(({ level }) => level === 1);
    const source = customers.length > 0 ? customers.map(({ id, firstname, surname, area }) => ({
        id,
        name: `#${id}. ${(firstname + ' ' + surname).trim()}`,
        filled_area: area,
    })) : warehouses;

    return (
        <BaseDatagrid data={keyBy(source, 'id')}
            ids={source.map(({ id }) => id)}
            currentSort={{
                field: '',
            }}
            resource="storageareas"
            expand={expand}
            classes={isRoot ? undefined : {
                headerRow: classes.expandedHeaderRow,
                headerCell: classes.expandedHeaderCell,
                expandHeader: classes.expandedHeaderCell,
                expandIconCell: classes.expandIconCell,
            }}
            className={expand ? undefined : classes.noExpand}
            body={<DatagridBody row={
                <DatagridRow />
            } />}
        >
            <TextField source="name" sortable={false} />
            {customers.length === 0 && (
                <NumberField source="total_area"
                    label={translate('resources.storageareas.fields.total_area') + ', m²'}
                    locales={locale}
                    options={optionsNumberFormat}
                    headerClassName={classes.spaceMaxWidth}
                    sortable={false}
                />
            )}
            <NumberField source="free_area"
                label={translate('resources.storageareas.fields.free_area') + ', m²'}
                locales={locale}
                options={optionsNumberFormat}
                headerClassName={classes.spaceMaxWidth}
                sortable={false}
            />
        </BaseDatagrid>
    );
};

const mapStateToProps = state => ({
    locale: state.i18n.locale,
});

const enhance = compose(
    withStyles(styles),
    translate,
    connect(
        mapStateToProps,
        null,
    ),
);

const WarehousesDatagrid = enhance(UnconnectedWarehousesDatagrid);

WarehousesDatagrid.defaultProps = {
    warehouses: [],
    customers: [],
};

export default WarehousesDatagrid;