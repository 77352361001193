export const SIDEBAR_SIZE = 240;

export const guttersContainer = theme => {
    const gutters = marginGutters(theme);
    const smBreakpoint = theme.breakpoints.up('sm');

    return {
        marginLeft: -gutters.marginLeft,
        marginRight: -gutters.marginRight,
        [smBreakpoint]: {
            marginLeft: -gutters[smBreakpoint].marginLeft,
            marginRight: -gutters[smBreakpoint].marginRight,
        },
    };
};

export const marginGutters = theme => {
    const themeGutters = theme.mixins.gutters();
    const smBreakpoint = theme.breakpoints.up('sm');

    return {
        marginLeft: themeGutters.paddingLeft,
        marginRight: themeGutters.paddingRight,
        [smBreakpoint]: {
            marginLeft: themeGutters[smBreakpoint].paddingLeft,
            marginRight: themeGutters[smBreakpoint].paddingRight,
        },
    };
};

export const configStyles = theme => {
    const gutters = marginGutters(theme);
    const container = guttersContainer(theme);
    const upSmBreakpoint = theme.breakpoints.up('sm');

    const guttersSize = gutters.marginLeft + gutters.marginRight;
    const upSmGuttersSize = gutters[upSmBreakpoint].marginLeft + gutters[upSmBreakpoint].marginRight;
    const mediaMinWidth = SIDEBAR_SIZE +
        (theme.breakpoints.values['sm'] + upSmGuttersSize) * 2 +
        theme.spacing.unit * 3 + 5;

    return {
        gutters,
        container,
        upSmBreakpoint,
        guttersSize,
        upSmGuttersSize,
        mediaMinWidth,
    };
};
