import React from 'react';
import { Edit } from 'react-admin';

import { FullNameField } from '../components';

import UserForm from './UserForm';

const UserTitle = ({ record }) => (
    <span>
        #{record.id}. <FullNameField record={record} />
    </span>
);

const UserEdit = ({ permissions, ...props }) => (
    <Edit title={<UserTitle />} undoable={false} {...props}>
        <UserForm permissions={permissions} />
    </Edit>
);

export default UserEdit;
