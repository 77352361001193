import { Delete as DeleteIcon } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import { Button, Confirm } from 'react-admin';

class DeleteSectionButton extends Component {

    state = {
        isOpenDialog: false,
    };

    handleConfirmDeleteDialog = () => {
        const { onConfirm } = this.props;

        onConfirm();
        this.handleDialogClose();
    };

    handleDeleteClick = () => {
        this.setState({
            isOpenDialog: true,
        });
    };

    handleDialogClose = () => {
        this.setState({
            isOpenDialog: false,
        });
    };

    render() {
        const { section } = this.props;
        const { isOpenDialog } = this.state;

        return (
            <Fragment>
                <Button label="ra.action.delete"
                        color="secondary"
                        onClick={this.handleDeleteClick}
                        disabled={!section.deletable}
                >
                    <DeleteIcon />
                </Button>
                <Confirm isOpen={isOpenDialog}
                         title="ra.message.bulk_delete_title"
                         content="ra.message.bulk_delete_content"
                         translateOptions={{
                             name: section.name,
                             smart_count: 1,
                         }}
                         onConfirm={this.handleConfirmDeleteDialog}
                         onClose={this.handleDialogClose}
                />
            </Fragment>
        );
    }

}

export default DeleteSectionButton;
