import { SettingsBackupRestore } from "@material-ui/icons";
import React, { Component } from "react";
import { translate, crudUpdateMany } from "react-admin";
import { connect } from "react-redux";
import { compose } from "recompose";

import { ConfirmButton } from "../components";

class RestoreButton extends Component {
  handleConfirmRemove = () => {
    const {
      crudUpdateMany,
      resource,
      basePath,
      record: { id: userId }
    } = this.props;

    crudUpdateMany(resource, [userId], { deleted_at: null }, basePath);
  };

  render() {
    const { translate } = this.props;

    return (
      <ConfirmButton
        label="ra.action.restore"
        onConfirm={this.handleConfirmRemove}
        icon={<SettingsBackupRestore />}
        title="ra.message.restore_title"
        variant="text"
      >
        {translate("ra.message.restore_content")}
      </ConfirmButton>
    );
  }
}

const enhance = compose(
  translate,
  connect(
    undefined,
    { crudUpdateMany }
  )
);

export default enhance(RestoreButton);
