import { debounce, get } from 'lodash';
import { IconButton, withStyles } from '@material-ui/core';
import { Add as AddIcon, RemoveCircle as RemoveCircleIcon } from '@material-ui/icons';
import React, { Component } from 'react';
import { Button, TextInput } from 'react-admin';

const styles = {
    row: {
        display: 'flex',
        alignItems: 'baseline',
    },
    mostChild: {
        flex: '1 0 auto',
    },
    actions: {
        display: 'flex',
        // justifyContent: 'flex-end',
        marginTop: 8,
    },
};

class ColorArray extends Component {

    state = {
        values: [''],
    };

    constructor(props) {
        super(props);

        this.handleChangeColor = debounce(this.handleChangeColor, 200);
    }

    handleAddColor = () => {
        const { values } = this.state;
        values.push('');
        this.setState({ values });
    };

    handleChangeColor = (index, value) => {
        const { values } = this.state;
        values[index] = value;
        this.setState({ values });
    };

    handleRemoveColor = (index) => {
        const { values } = this.state;
        values.splice(index, 1);
        this.setState({ values });
    };

    static getDerivedStateFromProps({ record: recordProps, source }, { record: recordState }) {
        if (recordProps === recordState) {
            return {};
        }

        const values = get(recordProps, source, ['']);

        return {
            record: recordProps,
            values,
        };
    }

    render() {
        const { classes, className, record, resource, source, ...props } = this.props;
        const { values } = this.state;

        return (
            <div className={className}>
                {values.map((value, index) => (
                    <div key={index} className={classes.row}>
                        <TextInput {...props}
                                   className={classes.mostChild}
                                   source={`${source}[${index}]`}
                                   label={`resources.${resource}.fields.${source}`}
                                   record={{
                                       [source]: value,
                                   }}
                                   onChange={(e, value) => this.handleChangeColor(index, value)}
                        />
                        {values.length > 1 && (
                            <IconButton onClick={() => this.handleRemoveColor(index)} color="secondary">
                                <RemoveCircleIcon />
                            </IconButton>
                        )}
                    </div>
                ))}

                <div className={classes.actions}>
                    <Button onClick={this.handleAddColor}
                            variant="contained"
                            color="primary"
                            label="ra.action.add_color"
                    >
                        <AddIcon />
                    </Button>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ColorArray);
