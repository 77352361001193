import { Typography, withStyles } from '@material-ui/core';
import React, { Fragment, cloneElement } from 'react';

const styles = {
    root: {
        color: '#000',
        border: '1px solid transparent',
        borderRadius: 9,
        overflow: 'hidden',
        boxSizing: 'border-box',
    },
    container: {
        position: 'relative',
    },
    project: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        transform: 'rotate(-90deg)',
        transformOrigin: '0 100%',
    },
    barcode: {
        position: 'absolute',
        bottom: '100%',
    },
    description: {
        position: 'absolute',
        top: '100%',
        left: 0,
        overflow: 'hidden',
    },
    company: {
        position: 'absolute',
        top: '100%',
        left: '100%',
        overflow: 'hidden',
        transform: 'rotate(-90deg) translate(0, 2px)',
        transformOrigin: '0 0',
    },
    barcodeNumbers: {
        fontWeight: 'bold',
    },
    barcodeBigNumbers: {
        fontWeight: 'bold',
    },
};

const DEFAULT_SIZE = 170.14;

const BarcodeView = ({ classes, project, record, size, children: QRCode }) => QRCode ? (
    <div className={classes.root}
        style={{
            width: size,
            height: size,
            padding: 28 / DEFAULT_SIZE * size,
        }}
    >
        <div className={classes.container}>
            {cloneElement(QRCode, { size: (DEFAULT_SIZE - 56) / DEFAULT_SIZE * size })}
            {project && (
                <Fragment>
                    <Typography
                        variant="subheading"
                        className={classes.project}
                        style={{ fontSize: 14 / DEFAULT_SIZE * size }}
                    >
                        PID:{' '}
                        <i className={classes.barcodeNumbers} style={{ fontSize: 20 / DEFAULT_SIZE * size }}>
                            {project.id}
                        </i>
                    </Typography>

                    {project.customer.address && (
                        <Fragment>
                            <div
                                className={classes.description}
                                style={{
                                    width: size,
                                    fontSize: 11 / DEFAULT_SIZE * size,
                                    lineHeight: `${14 / DEFAULT_SIZE * size}px`,
                                    height: 28 / DEFAULT_SIZE * size,
                                }}
                            >
                                {project.customer.address.company
                                    ? project.customer.address.company
                                    : `${project.customer.user.firstname} ${project.customer.user.surname}`
                                }

                            </div>
                        </Fragment>
                    )}
                </Fragment>
            )}
            {record && record.id && (
                <Typography
                    variant="subheading"
                    className={classes.barcode}
                    style={{ fontSize: 12 / DEFAULT_SIZE * size }}
                >
                    QID:{' '}
                    <i className={classes.barcodeNumbers} style={{ fontSize: 20 / DEFAULT_SIZE * size }}>
                        {String(record.id).slice(0, -3)}
                    </i>
                    <i className={classes.barcodeBigNumbers} style={{ fontSize: 22 / DEFAULT_SIZE * size }}>
                        {String(record.id).slice(-3)}
                    </i>
                </Typography>
            )}
        </div>
    </div>
) : null;

export default withStyles(styles)(BarcodeView);
