import { MyLocation as MyLocationsIcon, Delete } from '@material-ui/icons';
import { withStyles, FormLabel } from '@material-ui/core';
import React, { Component } from 'react';
import { Button, TextInput, SelectInput, REDUX_FORM_NAME, withTranslate } from 'react-admin';
import { DateTimeInput } from '../components/DateInput';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { AddressInput, Marker as MarkerIcon, Pickup } from '../components';
import { required } from '../validations';
import styles from '../styles';
import { addressToString, showAlertHelper } from '../helpers';
import deliveryTypes, {
    DELIVERY,
    PICKUP,
    DISPOSAL,
    COMMENT,
    ADDRESS,
    SURNAME,
    DATE
} from './deliveryTypes';

const typesTabs = {
    [DELIVERY]: {
        label: 'delivery',
        icon: <MyLocationsIcon />,
        fields: [ADDRESS, DATE, COMMENT]
    },
    [PICKUP]: {
        label: 'pickup',
        icon: <Pickup />,
        fields: [SURNAME, DATE, COMMENT]
    },
    [DISPOSAL]: {
        label: 'disposal',
        icon: <Delete />,
        fields: [DATE, COMMENT]
    },
}

const deliveryFields = {
    [DATE]: ({ resource, record, classes, key }) => (
        <DateTimeInput
            source="date"
            label="resources.deliveries.fields.date"
            resource={resource}
            record={record}
            className={classes.fullWidth}
            key={key}
            validate={required('deliveries', 'date')}
            options={{
                format: 'dd.MM.yyyy, HH:mm',
            }}
        />
    ),
    [COMMENT]: ({ resource, record, classes, key }) => (
        <TextInput
            source="comment"
            multiline={true}
            resource={resource}
            record={record}
            className={classes.fullWidth}
            key={key}
        />
    ),
    [SURNAME]: ({
        classes,
        resource,
        record,
        key,
    }) => (
        <div key={key} className={`${classes.fullWidth} ${classes.flex} ${classes.flexEnd}`}>
            <TextInput source="surname"
                label="resources.deliveries.fields.surname"
                resource={resource}
                record={record}
                className={classes.flexChild}
                validate={required('deliveries', 'surname')}
            />
        </div>
    ),
    [ADDRESS]: ({
        isPopup,
        isOtherAddress,
        classes,
        record,
        resource,
        unloadingAddresses,
        handleClickOtherAddress,
        formName,
        setChangingAddress,
        handleClickChoiceAddress,
        key
    }) => (
        !isOtherAddress
            ? (
                <div key={key} className={`${classes.fullWidth} ${classes.flex} ${classes.flexEnd}`}>
                    <SelectInput label="resources.delivery.fields.address"
                        source="address.id"
                        validate={required('delivery', 'address')}
                        className={classes.flexChild}
                        resource={resource}
                        record={record}
                        choices={unloadingAddresses}
                        optionText={address => addressToString({ address })}
                    />
                    <Button variant="contained"
                        label="ra.action.other"
                        className={`${classes.buttonEnd} ${classes.marginInput}`}
                        onClick={handleClickOtherAddress}
                    >
                        <MyLocationsIcon />
                    </Button>
                </div>
            )
            : (
                <>
                    <AddressInput 
                        isPopup={isPopup}
                        formName={formName}
                        isOtherAddress={isOtherAddress}
                        setChangingAddress={setChangingAddress}
                    />
                    <div className={`${classes.fullWidth} ${classes.flex}`}>
                        {unloadingAddresses.length > 0 && (
                            <Button variant="contained"
                                label="ra.action.choice_address"
                                className={`${classes.buttonEnd} ${classes.marginInput}`}
                                onClick={handleClickChoiceAddress}
                            >
                                <MarkerIcon />
                            </Button>
                        )}
                    </div>
                </>
            )
    )
}

class UnconnectedDeliveryFields extends Component {

    state = {
        popupHeight: 0,
    };

    componentDidMount() {
        this.setState({
            popupHeight: this.calculateHeight()
        })
    }

    componentDidUpdate() {
        showAlertHelper.saveDefaultState(this.props.initial);
        showAlertHelper.saveCurrentState(this.props.current);
    }

    handleChangeAddress = isOtherAddress => {
        const { onChangeAddress } = this.props;

        onChangeAddress(isOtherAddress);
    };

    handleClickChoiceAddress = () => {
        this.handleChangeAddress(false);
    };

    handleClickOtherAddress = () => {
        this.handleChangeAddress(true);
    };

    handleClickPickup = () => {
        const { onChangePickup } = this.props;

        onChangePickup();
    };

    calculateHeight = () => {
        const fieldsLength = deliveryTypes.map((deliveryType) => {
            const tab = typesTabs[deliveryType];
            return tab.fields.length;
        });
        
        return 72*Math.max(...fieldsLength)+36;
    }

    render() {
        const { classes, translate, currentType, onChangeType, isPopup } = this.props;
        
        return (
            <div style={{height: isPopup ? this.state.popupHeight : 'auto' }}>
                <div className={`${classes.fullWidth}`} key={1}>
                    <FormLabel>{translate('resources.delivery.fields.type')}: &nbsp;</FormLabel>
                    {deliveryTypes.map((deliveryType, index) => {
                        const delivery = typesTabs[deliveryType];
                        return (
                            <Button
                                key={index}
                                label={`ra.action.${delivery.label}`}
                                variant={deliveryType === currentType ? 'contained' : 'outlined'}
                                onClick={() => onChangeType(deliveryType)}
                                className={classes.tabButton}
                            >
                                {!!delivery.icon && delivery.icon}
                            </Button>
                        )
                    })}
                </div>
                <div className={`${classes.fullWidth}`} key={2}>
                    {deliveryTypes.map(deliveryType => {
                        if (deliveryType !== currentType) return;

                        const delivery = typesTabs[deliveryType];

                        return (delivery.fields && delivery.fields.length > 0
                            ? delivery.fields.map((fieldName, index) => {
                                const field = deliveryFields[fieldName];
                                return field({
                                    ...this.props,
                                    key: index,
                                    handleClickOtherAddress: this.handleClickOtherAddress,
                                    handleClickPickup: this.handleClickPickup,
                                    handleClickChoiceAddress: this.handleClickChoiceAddress,
                                })
                            })
                            : null);
                    })}
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    initial: state.form[REDUX_FORM_NAME]?.initial,
    current: state.form[REDUX_FORM_NAME]?.values,
});

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps),
    withTranslate,
)

const DeliveryFields = enhance(UnconnectedDeliveryFields);

DeliveryFields.defaultProps = {
    formName: REDUX_FORM_NAME,
};

export default DeliveryFields;
