import React, { Component } from 'react';
import { Create } from 'react-admin';

import ArticleCategoryForm from './ArticleCategoryForm';

class ArticleCategoryCreate extends Component {

    render() {
        const { ...props } = this.props;

        return (
            <Create {...props}>
                <ArticleCategoryForm />
            </Create>
        );
    }

}

export default ArticleCategoryCreate;
