import { Add as AddIcon } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import { Button } from 'react-admin';

import { DialogForm } from '../components';
import { formUniqueKey } from '../helpers';

import FieldsSection from './FieldsSection';

class AddSectionButton extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isOpenDialog: false,
        }

        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    handleAddSectionClick = () => {
        this.setState({
            isOpenDialog: true,
        });
    };

    handleConfirmDialog = resource => {
        if (!this._isMounted) return;

        const { onAdd } = this.props;

        onAdd(resource);
    };

    handleDialogClose = () => {
        if (!this._isMounted) return;

        this.setState({
            isOpenDialog: false,
        });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { section } = this.props;
        const { isOpenDialog } = this.state;

        return (
            <Fragment>
                <Button label="ra.action.add_subsection" onClick={this.handleAddSectionClick}>
                    <AddIcon />
                </Button>
                <DialogForm form={`add-section-form-${formUniqueKey(section)}`}
                            onClose={this.handleDialogClose}
                            onSubmit={this.handleConfirmDialog}
                            size="sm"
                            showDialog={isOpenDialog}
                            title="resources.warehouses.action.add_subsection"
                            titleParams={{ name: section.name }}
                >
                    <FieldsSection />
                </DialogForm>
            </Fragment>
        );
    }

}

export default AddSectionButton;
