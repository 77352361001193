import { Card, CardHeader, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { translate } from 'react-admin';
import { compose } from 'recompose';

import { BodyMobileGrid as ArticleBodyMobileGrid } from '../articles/MobileGrid';
import generalStyles from '../styles';

import RemoveArticleButton from './RemoveArticleButton';

const styles = theme => ({
    ...generalStyles(theme),
    root: {
        marginTop: theme.spacing.unit,
    },
});

const DeliveryArticle = ({ classes, deliveryId, article, basePath, permissions, translate }) => (
    <Card className={classes.root}>
        <CardHeader title={
            <div className={`${classes.flex} ${classes.flexCenter}`}>
                <Typography variant="title" className={classes.flexChild}>
                    {translate('resources.articles.name', { smart_count: 1 })} #{article.id}
                </Typography>
                <RemoveArticleButton record={article} deliveryId={deliveryId} />
            </div>
        } />
        <ArticleBodyMobileGrid record={article}
                               permissions={permissions}
                               basePath={basePath}
                               resource="articles"
        />
    </Card>
);

const enhance = compose(
    withStyles(styles),
    translate,
);

export default enhance(DeliveryArticle);
