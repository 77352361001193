import React, { cloneElement } from 'react';

const Container = ({ className, children, isReplaceProps, excludeProps, ...parentProps }) => {
    const props = {};
    Object.keys(parentProps).forEach(key => {
        if (excludeProps.indexOf(key) === -1) {
            props[key] = parentProps[key];
        }
    });

    return (
        <div className={className}>
            {isReplaceProps ? children.map((child, index) => cloneElement(child, {
                ...props,
                key: index,
            })) : children}
        </div>
    );
};

Container.defaultProps = {
    isReplaceProps: false,
    excludeProps: [],
};

export default Container;
