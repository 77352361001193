import { Spellcheck as ApproveIcon } from '@material-ui/icons';
import React, { Component } from 'react';
import { Button, crudUpdateMany } from 'react-admin';
import { connect } from 'react-redux';

import { CONFIRMED } from './statuses';

class ApproveButton extends Component {

    handleApprove = () => {
        const { basePath, crudUpdateMany, record } = this.props;

        crudUpdateMany('deliveries', [record.id], { status: CONFIRMED }, basePath);
    };

    render() {
        return (
            <Button label="ra.action.approve" onClick={this.handleApprove}>
                <ApproveIcon />
            </Button>
        );
    }

}

export default connect(undefined, { crudUpdateMany })(ApproveButton);
