import {withStyles} from '@material-ui/core';
import React, {Fragment} from 'react';
import {DateField, List, NumberField, Responsive} from 'react-admin';

import {
    AddressField,
    Datagrid,
    ListActions,
    ListFilter as DeliveryFilter,
} from '../components';
import CancelButton from './CancelButton';
import styles from '../styles';
import {ROLE_CUSTOMER} from '../users/roles';

import ApproveButton from './ApproveButton';
import ExpandDelivery from './ExpandDelivery';
import MobileGrid from './MobileGrid';
import {CONFIRMED, OUTSOURCED} from './statuses';
import TypeField from './TypeField';

const CustomDeliveryActions = ({permissions, record, ...props}) => (
    <Fragment>
        {(permissions !== ROLE_CUSTOMER || record.status !== CONFIRMED) && <CancelButton record={record} {...props} />}
        {permissions !== ROLE_CUSTOMER && record.status !== CONFIRMED && <ApproveButton record={record} {...props} />}
    </Fragment>
);

const DeliveryActions = ({permissions, ...props}) => (
    <ListActions {...props}
                 deletable={false}
                 isShow={true}
                 customButtons={
                     <CustomDeliveryActions permissions={permissions}/>
                 }
    />
);

const DeliveryList = ({classes, permissions, basePath, ...props}) => (
    <List {...props}
          filters={<DeliveryFilter permissions={permissions}/>}
          filter={{'status][neq': OUTSOURCED}}
          perPage={25}
          bulkActionButtons={false}
          exporter={false}
          basePath={basePath}
    >
        <Responsive
            medium={<MobileGrid permissions={permissions}/>}
            large={
                <Datagrid expand={<ExpandDelivery permissions={permissions} basePath={basePath}/>}>
                    <NumberField source="id"/>
                    <AddressField showName
                                  sortBy="address"
                                  label="resources.delivery.fields.recipient"
                                  headerClassName={classes.fullWidth}
                    />
                    <TypeField
                            sortBy="address"
                            label="resources.delivery.fields.type"
                            headerClassName={classes.fullWidth}
                            sortable={false}
                        />
                    <DateField showTime={true} source="date" className={classes.nowrap}/>
                    <DeliveryActions headerClassName={classes.minWidth} permissions={permissions}/>
                </Datagrid>
            }
        />
    </List>
);

export default withStyles(styles)(DeliveryList);
