import { withStyles } from '@material-ui/core';
import { get } from 'lodash';
import React, { Component } from 'react';
import { Show, SimpleShowLayout, TextField, withDataProvider, GET_ONE } from 'react-admin';
import { compose } from 'recompose';

import Structure from './Structure';
import { nextUniqueKey } from '../helpers';

const WarehouseTitle = ({ record }) => (
    <span>
        #{record.id}. {record.name}
    </span>
);

const styles = theme => ({
    container: {
        width: '100%',
        maxWidth: theme.breakpoints.values['md'],
    },
    structure: {
        marginTop: theme.spacing.unit * 3,
    },
});

class UnconnectedWrapWarehouseShow extends Component {

    constructor(props) {
        super(props)

        const { record } = this.props;

        this.state = {
            children: get(record, 'children', [])
                .map(child => ({
                    ...child,
                    uniqueKey: nextUniqueKey(),
                })),
            opened: [],
        }
    }

    findStorageArea = (obj, storageArea) => {
        if (typeof obj === 'object'
            && obj.children
            && Array.isArray(obj.children)
            && obj.children.length > 0) {

            const area = obj.children.find(item => item.id === storageArea);

            if (area) {
                return area
            } else {
                let result;
                for (let child of obj.children) {
                    result = this.findStorageArea(child, storageArea);
                    if (result) {
                        break;
                    }
                }
                return result;
            }
        }
    }

    handlePreload = async storageId => {

        const { dataProvider } = this.props;

        const stateCopy = JSON.parse(JSON.stringify(this.state));

        const storageArea = this.findStorageArea(stateCopy, storageId);

        if (storageArea && storageArea.children && storageArea.children.length > 0) {

            const newChildren = await Promise.all(storageArea.children.map(async child => {
                if (!child.id) return { ...child };

                const { data } = await dataProvider(GET_ONE, 'storageareas', {
                    id: child.id
                })

                return {
                    ...child,
                    children: data.children,
                }
            }));

            storageArea.children = newChildren;

            this.handleChangeStructure(stateCopy.children);
        }
    }

    handleOpenSection = sectionId => {
        const { opened } = this.state;

        const newOpened = opened.includes(sectionId) ? opened.filter(item => item !== sectionId) : opened.concat(sectionId);

        this.setState({
            opened: newOpened
        })
    }

    handleChangeStructure = children => {
        this.setState({ children });
    };

    render() {
        const { classes, record } = this.props;
        const { children, opened } = this.state;

        return (
            <SimpleShowLayout record={record}>
                <TextField source="name" />
                <Structure children={children}
                    className={`${classes.container} ${classes.structure}`}
                    name={record.name}
                    disabled={true}
                    onExpand={this.handlePreload}
                    openSection={this.handleOpenSection}
                    openedSections={opened}
                />
            </SimpleShowLayout>
        )
    }
}

const enhance = compose(
    withStyles(styles),
    withDataProvider
)

const WrapWarehouseShow = enhance(UnconnectedWrapWarehouseShow);

const WarehouseShow = props => (
    <Show title={<WarehouseTitle />} actions={null} {...props}>
        <WrapWarehouseShow />
    </Show>
);

export default WarehouseShow;
