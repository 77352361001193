import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { GET_ONE, GET_LIST, showNotification, withDataProvider } from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';

import { ChipsInput } from '../components';
import { ROLE_COLLECTOR_GUY, ROLE_CUSTOMER, ROLE_MAIN_CUSTOMER } from '../users/roles';

import ArticleInfos from './ArticleInfos';
import ProjectID from './ProjectID';
import Statuses, { NOT_IN_WAREHOUSE, OUTSOURCED } from './statuses';

const styles = {
    fullWidth: {
        width: '100%',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
};

class BarcodeList extends Component {

    _isMounted = false;

    state = {
        barcodes: [],
        isOpenQrCodeReader: false,
    };

    static getDerivedStateFromProps({ permissions }, { barcodes }) {
        const isOpenQrCodeReader = permissions !== ROLE_COLLECTOR_GUY
            && localStorage.getItem('is_next') !== null
            && localStorage.getItem('is_create') === null;

        return {
            isOpenQrCodeReader: isOpenQrCodeReader,
            barcodes: isOpenQrCodeReader ? [] : barcodes,
        };
    }

    componentDidMount = async () => {
        const { barcodeId, record } = this.props;
        let state;

        this._isMounted = true;

        if (!record.id) {
            if (!barcodeId) {
                return;
            }

            const projectId = await this.getProjectByBarcode(barcodeId);

            if (!projectId || !this._isMounted) {
                return;
            }

            const barcodes = [{
                id: barcodeId,
                project_id: projectId,
            }];

            state = {
                barcodes,
                projectId,
            };

            this.handleChange(barcodes);
        } else {
            state = {
                barcodes: record.barcodes,
                projectId: record.barcodes.length > 0 ? record.barcodes[0].project_id : null,
            };
        }

        if (this._isMounted) {
            this.setState(state, this.updateArticleInfos);
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    getProjectByBarcode = async barcodeId => {
        const { dataProvider, record: { id }, showNotification, dispatch } = this.props;
        const { barcodes } = this.state;
        const { data: { project_id, article_id } } = await dataProvider(GET_ONE, 'barcodes', {
            id: barcodeId,
        });

        if (article_id) {
            if (!id) {
                if (barcodes.length === 0) {
                    dispatch(push(`/articles/${article_id}/create?barcode=${barcodeId}`));
                } else {
                    showNotification('ra.message.error_add_barcode_to_article', 'warning');
                }
            } else if (id !== article_id) {
                showNotification('ra.message.error_add_barcode_to_article', 'warning');
            }

            return null;
        }

        return project_id;
    };

    handleChange = barcodes => {
        const { onChange } = this.props;

        onChange(barcodes);
    };

    handleChangeQrCode = async value => {
        const { barcodes, projectId: projectIdState } = this.state;
        const [, search] = value.split('?');

        const searchParams = new URLSearchParams(search);
        const barcodeId = parseInt(searchParams.get('barcode'));

        if (barcodes.findIndex(({ id }) => id === barcodeId) > -1) {
            return;
        }

        const projectId = await this.getProjectByBarcode(barcodeId);

        if (projectId && (!projectIdState || projectIdState === projectId)) {
            barcodes.push({
                id: barcodeId,
                project_id: projectId,
            });

            this.setState({
                barcodes,
                projectId,
            });
            this.handleChange(barcodes);
        }
    };

    handleCloseQrCodeReader = () => {
        localStorage.removeItem('is_next');
    };

    handleDeleteBarcode = barcodeId => {
        const { barcodes: barcodesState } = this.state;
        const barcodes = barcodesState.filter(({ id }) => id !== barcodeId);

        this.setState({ barcodes });
        this.handleChange(barcodes);
    };

    render() {
        const { classes, className, isLoading, source, resource } = this.props;
        const { barcodes, projectId, isOpenQrCodeReader, inWarehouse, total } = this.state;

        return (
            <div className={`${className} ${classes.fullWidth}`}>
                <div className={classes.header}>
                    <ProjectID projectId={projectId} />

                    {inWarehouse !== undefined && total > 0 && (
                        <ArticleInfos inWarehouse={inWarehouse} total={total} />
                    )}
                </div>
                <ChipsInput source={source}
                    resource={resource}
                    values={barcodes}
                    isOpenQrCodeReader={isOpenQrCodeReader}
                    isLoading={isLoading}
                    buttonLabel="resources.articles.action.scan_barcode"
                    onDeleteElement={this.handleDeleteBarcode}
                    onChangeQrCode={this.handleChangeQrCode}
                    onCloseQrCodeReader={this.handleCloseQrCodeReader}
                />
            </div>
        );
    }

    updateArticleInfos = async () => {
        const { dataProvider, permissions } = this.props;
        const { projectId } = this.state;

        if (permissions !== ROLE_MAIN_CUSTOMER && permissions !== ROLE_CUSTOMER && permissions !== ROLE_COLLECTOR_GUY) {
            const { total } = await dataProvider(GET_LIST, 'articles', {
                filter: {
                    project: projectId
                }
            });

            const { total: in_warehouse } = await dataProvider(GET_LIST, 'articles', {
                filter: {
                    'status][in][': Statuses.filter(item => item !== NOT_IN_WAREHOUSE && item !== OUTSOURCED)
                }
            })

            if (this._isMounted) {
                this.setState({
                    inWarehouse: in_warehouse,
                    total,
                });
            }
        }
    };

}

const enhance = compose(
    withStyles(styles),
    withDataProvider,
    connect(undefined, { showNotification }),
);

export default enhance(BarcodeList);
