import { withStyles } from '@material-ui/core';
import { Add as AddIcon, Print as PrintIcon } from '@material-ui/icons';
import React, { useState, useRef } from 'react';
import { Button, NumberInput, TextInput, CREATE, minValue, withDataProvider } from 'react-admin';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useReactToPrint } from 'react-to-print';
import QRCode from 'qrcode.react';

import { BarcodeView } from '../components';

import { DialogForm } from '../components';
import { required } from '../validations';

const styles = {
    fullWidth: {
        width: '100%',
    },
};

const AddBarcodeButton = ({
    projectId,
    classes,
    dataProvider,
    onAdd,
    host,
    route,
    barcodesStyles,
    record,
    addAndPrint,
}) => {
    const [isOpenDialog, toggleDialog] = useState(false);
    const [barcodesForPrinting, setBarcodes] = useState([]);

    const barcodesEl = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => barcodesEl.current,
    });

    const handleAddBarcodeClick = () => toggleDialog(true);

    const handleDialogClose = () => toggleDialog(false);

    const handleConfirmDialog = async data => {
        const { data: project } = await dataProvider(CREATE, 'barcodes', { data });

        onAdd(project);
    }

    const handleAddAndPrint = async data => {
        const { data: project } = await dataProvider(CREATE, 'barcodes', { data });

        setBarcodes(project.barcodes);

        onAdd(project);

        handlePrint();
    }

    return (
        <>
            <Button label="ra.action.add" onClick={handleAddBarcodeClick}>
                <AddIcon />
            </Button>
            <DialogForm form={`add-barcodes`}
                onClose={handleDialogClose}
                onSubmit={handleConfirmDialog}
                onAltSubmit={handleAddAndPrint}
                size="sm"
                showDialog={isOpenDialog}
                title="resources.barcodes.action.add_barcodes"
                altSaveButton={addAndPrint && (
                    <Button label="ra.action.add_and_print">
                        <PrintIcon />
                    </Button>)}
            >
                <TextInput source="project_id"
                    label="resources.barcodes.fields.project_id"
                    disabled={true}
                    validate={required('barcodes', 'project_id')}
                    defaultValue={projectId}
                    className={classes.fullWidth}
                />
                <NumberInput source="barcodes_number"
                    label="resources.barcodes.fields.barcodes_number"
                    defaultValue={1}
                    inputProps={{
                        min: 1,
                    }}
                    validate={[
                        required('storageareas', 'quantity'),
                        minValue(1),
                    ]}
                    className={classes.fullWidth}
                />
            </DialogForm>
            <div style={{ display: 'none' }}>
                <div ref={barcodesEl} className={barcodesStyles.printedBarcodes}>
                    <div className={barcodesStyles.printedContainer}>
                        {
                            barcodesForPrinting
                                .map(({ id }) => (
                                    <div key={id} className={barcodesStyles.printedBarcode}>
                                        <BarcodeView project={record} record={{ id }} size={170.14}>
                                            <QRCode value={host + route + id} level="H" />
                                        </BarcodeView>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

AddBarcodeButton.propTypes = {
    className: PropTypes.string,
    projectId: PropTypes.number.isRequired,
    onAdd: PropTypes.func.isRequired,
    host: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    barcodesStyles: PropTypes.objectOf(PropTypes.string).isRequired,
    record: PropTypes.objectOf(PropTypes.any).isRequired,

    // from withStyles
    classes: PropTypes.objectOf(PropTypes.string).isRequired,

    //from withDataProvider
    dataProvider: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,

}

const enhance = compose(
    withStyles(styles),
    withDataProvider,
);

export default enhance(AddBarcodeButton);
