import { withStyles } from '@material-ui/core';
import React, { cloneElement } from 'react';
import { CardActions, CreateButton, List, NumberField, ReferenceField, Responsive } from 'react-admin';

import {
    AddressField,
    Datagrid,
    ListActions as ProjectActions,
    ListFilter as ProjectFilter,
    UpdatedAtField,
} from '../components';
import ExportProjectsRecords from '../components/ExportRecords';
import styles from '../styles';
import {
    ROLE_AGENT,
    ROLE_CUSTOMER,
    ROLE_MAIN_CUSTOMER,
    ROLE_OFFICE_EMPLOYEE,
    ROLE_SUPER_ADMIN,
    ROLE_WAREHOUSE_MANAGER,
    ROLE_COLLECTOR_GUY
} from '../users/roles';

import MobileGrid from './MobileGrid';

const ProjectsActions = ({ filters, resource, showFilter, displayedFilters, filterValues, hasCreate, basePath, exportable }) => (
    <CardActions>
        {cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {hasCreate && <CreateButton basePath={basePath} />}
        {exportable && <ExportProjectsRecords />}
    </CardActions>
);

const ProjectList = ({ classes, permissions, ...props }) => (
    <List {...props}
          filters={<ProjectFilter />}
          perPage={25}
          bulkActionButtons={false}
          actions={
            <ProjectsActions 
                exportable={ 
                    permissions !== ROLE_WAREHOUSE_MANAGER && 
                    permissions !== ROLE_COLLECTOR_GUY &&   
                    permissions !== ROLE_MAIN_CUSTOMER &&  
                    permissions !== ROLE_CUSTOMER 
                } 
            />
          }
    >
        <Responsive
            medium={<MobileGrid permissions={permissions} exportable={true} />}
            large={
                <Datagrid>
                    {(permissions === ROLE_MAIN_CUSTOMER || permissions === ROLE_CUSTOMER || permissions === ROLE_WAREHOUSE_MANAGER) ? (
                        <AddressField source="customer.address"
                                      label="resources.projects.fields.customer"
                                      headerClassName={classes.fullWidth}
                                      sortable={false}
                        />
                    ) : (
                        <ReferenceField source="customer.id"
                                        label="resources.projects.fields.customer"
                                        reference="customers"
                                        sortBy="customer"
                                        headerClassName={classes.fullWidth}
                        >
                            <AddressField />
                        </ReferenceField>
                    )}
                    <NumberField source="order_number" headerClassName={classes.minWidth} sortBy="orderNumber" />
                    <NumberField source="loading_points.length"
                                 label="resources.projects.fields.loading_points"
                                 headerClassName={classes.minWidth}
                                 sortable={false}
                    />

                    <UpdatedAtField sortBy="updatedAt" headerClassName={classes.minWidth} />
                    <ProjectActions
                        headerClassName={classes.minWidth}
                        deletable={
                            permissions === ROLE_SUPER_ADMIN ||
                            permissions === ROLE_AGENT ||
                            permissions === ROLE_OFFICE_EMPLOYEE
                        }
                        editable={
                            permissions !== ROLE_MAIN_CUSTOMER &&
                            permissions !== ROLE_CUSTOMER
                        }
                        isShow={
                            permissions === ROLE_MAIN_CUSTOMER ||
                            permissions === ROLE_CUSTOMER
                        }
                        exportable={ 
                            permissions !== ROLE_WAREHOUSE_MANAGER && 
                            permissions !== ROLE_COLLECTOR_GUY &&   
                            permissions !== ROLE_MAIN_CUSTOMER &&  
                            permissions !== ROLE_CUSTOMER 
                        }
                    />
                </Datagrid>
            }
        />
    </List>
);

export default withStyles(styles)(ProjectList);
