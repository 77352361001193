import React from 'react';

import { Card } from '../components';
import UserFormFields from '../users/UserFormFields';

const UserCard = ({ source, record, onChangeRole, permissions, ...props }) => (
    <Card {...props} source={source} >
        <UserFormFields onChangeRole={onChangeRole}
                        permissions={permissions}
                        record={record}
                        resource="users"
                        roles={[]}
                        source={source}
                        chooseAgent={true}
        />
    </Card>
);

UserCard.defaultProps = {
    source: 'user',
};

export default UserCard;
