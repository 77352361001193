import { withStyles } from '@material-ui/core';
import React from 'react';
import { SimpleForm, Toolbar } from 'react-admin';

import { ToolbarContainer } from '../components';
import generalStyles from '../styles';

import UserFormFields from './UserFormFields';
import {
    ROLE_COLLECTOR_GUY,
    ROLE_CUSTOMER,
    ROLE_MAIN_CUSTOMER,
    ROLE_OFFICE_EMPLOYEE,
    ROLE_WAREHOUSE_MANAGER,
} from './roles';

const styles = theme => ({
    container: {
        width: '100%',
        maxWidth: theme.breakpoints.values['lg'],
    },
    spacer: generalStyles(theme).spacer,
});

const UserForm = ({ classes, permissions, record, ...props }) => (
    <SimpleForm {...props}
                redirect={record.id ? false : 'edit'}
                record={record}
                toolbar={
                    <Toolbar classes={{ spacer: classes.spacer }}>
                        <ToolbarContainer maxWidth="lg" />
                    </Toolbar>
                }
    >
        <UserFormFields className={classes.container}
                        permissions={permissions}
                        roles={record.id ? [] : (
                            permissions === ROLE_MAIN_CUSTOMER ? [
                                ROLE_MAIN_CUSTOMER,
                                ROLE_CUSTOMER,
                            ] : (
                                permissions === ROLE_OFFICE_EMPLOYEE ? [
                                    ROLE_COLLECTOR_GUY,
                                    ROLE_WAREHOUSE_MANAGER,
                                ] : [
                                    ROLE_OFFICE_EMPLOYEE,
                                    ROLE_COLLECTOR_GUY,
                                    ROLE_WAREHOUSE_MANAGER,
                                ]
                            )
                        )}
                        chooseAgent={true}
        />
    </SimpleForm>
);

export default withStyles(styles)(UserForm);
