import React from 'react';
import { NumberField, ReferenceField, SimpleShowLayout } from 'react-admin';

import {
    AddressField,
    CommentList,
    FullNameField,
    MobileGrid as BaseMobileGrid,
    UpdatedAtField,
} from '../components';
import {
    ROLE_AGENT,
    ROLE_CUSTOMER,
    ROLE_MAIN_CUSTOMER,
    ROLE_OFFICE_EMPLOYEE,
    ROLE_SUPER_ADMIN,
    ROLE_WAREHOUSE_MANAGER,
    ROLE_COLLECTOR_GUY,
} from '../users/roles';
import { guttersContainer, marginGutters } from '../helpers';
import { withStyles } from '@material-ui/core';
import ProjectAreasShow from './ProjectAreasShow';

const TitleMobileGrid = ({ record }) => (
    <span>
        #{record.id}. <FullNameField record={record.customer} /> ({record.customer.address.company})
    </span>
);

const LoadingPoints = ({ record }) => record.loading_points.map((loading_point, index) => (
    <div key={loading_point.address.id}>
        #{index + 1}. <AddressField record={loading_point} />
    </div>
));

LoadingPoints.defaultProps = {
    source: 'loading_points',
    addLabel: true,
};

const styles = theme => ({
    root: {
        ...guttersContainer(theme),
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    gutter: {
        '&.ra-field': {
            color: theme.palette.primary.special,
            ...marginGutters(theme),
            flex: '1 0 80%',
        },
    },
    field: {
        '&.ra-field': {
            color: theme.palette.primary.special,
            flex: '1 0 calc(33.33% - 48px)',
            minWidth: 280,
        },
    },
    marginTop: {
        marginTop: 8,
    },
});

const UnconnectedBodyMobileGrid = props => {
    const {
        record,
        classes,
        className,
        isEmbedComments,
        isShowProjectArea,
        permissions,
        ...rest
    } = props;

    return (
        <SimpleShowLayout className={`${className} ${classes.root}`} record={record} {...rest}>
            {(permissions === ROLE_MAIN_CUSTOMER || permissions === ROLE_CUSTOMER || permissions === ROLE_WAREHOUSE_MANAGER) ? (
                <AddressField className={`${classes.field} ${classes.gutter}`}
                              source="customer.address"
                              label="resources.projects.fields.customer"
                              showName={false}
                />
            ) : (
                <ReferenceField source="customer.id"
                                label="resources.projects.fields.customer"
                                reference="customers"
                                className={`${classes.field} ${classes.gutter}`}
                >
                    <AddressField showName={false} />
                </ReferenceField>
            )}
            {record.order_number && (
                <NumberField source="order_number" className={`${classes.field} ${classes.gutter}`} />
            )}
            {record.loading_points.length > 0 && <LoadingPoints className={classes.gutter} />}

            <UpdatedAtField className={`${classes.field} ${classes.gutter}`} />

            {isShowProjectArea && <ProjectAreasShow className={`${classes.gutter} ${classes.marginTop}`} />}

            {isEmbedComments && <CommentList record={record} className={`${classes.gutter} ${classes.marginTop}`} />}
        </SimpleShowLayout>
    );
};

export const BodyMobileGrid = withStyles(styles)(UnconnectedBodyMobileGrid);

BodyMobileGrid.defaultProps = {
    isEmbedComments: false,
    isShowProjectArea: false,
};

const MobileGrid = ({ permissions, ...props }) => (
    <BaseMobileGrid {...props}
                    permissions={permissions}
                    title={<TitleMobileGrid />}
                    body={<BodyMobileGrid />}
                    deletable={permissions === ROLE_SUPER_ADMIN || permissions === ROLE_AGENT || permissions === ROLE_OFFICE_EMPLOYEE}
                    editable={permissions !== ROLE_MAIN_CUSTOMER && permissions !== ROLE_CUSTOMER}
                    isShow={permissions === ROLE_MAIN_CUSTOMER || permissions === ROLE_CUSTOMER}
                    exportable={
                        permissions !== ROLE_WAREHOUSE_MANAGER &&
                        permissions !== ROLE_COLLECTOR_GUY &&
                        permissions !== ROLE_MAIN_CUSTOMER &&
                        permissions !== ROLE_CUSTOMER
                    }
    />
);

export default MobileGrid;
