import React from 'react';
import { Create } from 'react-admin';

import CustomerForm from './CustomerForm';

const CustomerCreate = ({ permissions, ...props }) => (
    <Create {...props}>
        <CustomerForm permissions={permissions} />
    </Create>
);

export default CustomerCreate;
