import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { addField, showNotification, withDataProvider } from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { ChipsInput } from '../components';

import ArticleInfos from './ArticleInfos';
import ProjectID from './ProjectID';

const styles = {
    fullWidth: {
        width: '100%',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    error: {
        marginBottom: 20,
    },
};

class ListOfRevertedBarcode extends Component {

    _isMounted = false;

    state = {
        revertedBarcodes: [],
    };

    static getDerivedStateFromProps({ defaultValue, ...props }, { revertedBarcodes }) {
        if (defaultValue && defaultValue.length > 0) {
            defaultValue.forEach(barcodeId => {
                if (!revertedBarcodes.includes(barcodeId)) {
                    revertedBarcodes.push(barcodeId);
                }
            })
        }

        return {
            revertedBarcodes,
        };
    }

    componentDidMount = async () => {
        this._isMounted = true;
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = barcodes => {
        const { input } = this.props;

        input.onChange(barcodes);
    };

    handleChangeQrCode = async value => {
        const { record: { barcodes } } = this.props;
        const { revertedBarcodes } = this.state;
        const barcodeId = this.getBarcodeIdByUrl(value);

        if (revertedBarcodes.includes(barcodeId) || !barcodes.some(({ id }) => id === barcodeId)) {
            return;
        }

        revertedBarcodes.push(barcodeId);

        this.setState({ revertedBarcodes });
        this.handleChange(revertedBarcodes);
    };

    getBarcodeIdByUrl = url => {
        const [, search] = url.split('?');

        const searchParams = new URLSearchParams(search);

        return parseInt(searchParams.get('barcode'));
    };

    render() {
        const {
            classes,
            className,
            isLoading,
            source,
            record: {
                project: projectId,
                barcodes,
            },
            resource,
            meta: {
                touched,
                error,
            },
        } = this.props;
        const { revertedBarcodes } = this.state;

        const total = barcodes.length;
        const inWarehouse = revertedBarcodes.length;

        return (
            <div className={
                [
                    className,
                    classes.fullWidth,
                    !!(touched && error) ? classes.error : undefined,
                ].filter(Boolean).join(' ')
            }>
                <div className={classes.header}>
                    <ProjectID projectId={projectId} />

                    <ArticleInfos inWarehouse={inWarehouse} total={total} />
                </div>
                <ChipsInput source={source}
                            resource={resource}
                            selected={revertedBarcodes}
                            values={barcodes}
                            isLoading={isLoading}
                            buttonLabel="resources.articles.action.scan_barcode"
                            onChangeQrCode={this.handleChangeQrCode}
                />
            </div>
        );
    }

}

const enhance = compose(
    addField,
    withStyles(styles),
    withDataProvider,
    connect(undefined, { showNotification }),
);

export default enhance(ListOfRevertedBarcode);
