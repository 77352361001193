import { RESET_PASSWORD, SEND_EMAIL } from './actions';
import httpClient from './httpClient';

export default (type, params) => {
    const options = {
        method: 'POST',
        body: JSON.stringify(params),
    };

    switch (type) {
        case SEND_EMAIL: {
            const url = `${process.env.REACT_APP_API_URL}/send-email`;

            return httpClient(url, options).then(({ json }) => json);
        }
        case RESET_PASSWORD: {
            const url = `${process.env.REACT_APP_API_URL}/reset`;

            return httpClient(url, options).then(({ json }) => json);
        }
        default: {
            return httpClient('', {});
        }
    }
}
