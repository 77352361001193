import { get, set } from 'lodash';
import React from 'react';
import { TextField, translate } from 'react-admin';

const ArticleType = ({ record, source, translate, ...props }) => {
    const value = get(record, source);
    const clonedRecord = { ...record };
    set(clonedRecord, source, translate(`resources.articles.type.${value}`));

    return (
        <TextField record={clonedRecord} source={source} {...props} />
    );
};

ArticleType.defaultProps = {
    addLabel: true,
};

export default translate(ArticleType);
