import { format } from 'date-fns';
import {
    LocalShipping as LocalShippingIcon,
    Save as SaveIcon,
} from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import { Button, GET_LIST, setListSelectedIds, withDataProvider } from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import httpClient from '../providers/httpClient';
import { push } from 'react-router-redux';

import { DialogForm } from '../components';
import DeliveryFields from '../deliveries/DeliveryFields';
import { DELIVERY } from '../deliveries/deliveryTypes';
import { IN_CART } from '../articles/statuses';

class DeliveryButton extends Component {

    _isMounted = false;
    state = {
        unloadingAddresses: [],
        isOpenDialog: false,
        isOtherAddress: false,
        changingAddress: null,
        deliveryType: DELIVERY,
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getArticles = async () => {
        const { dataProvider, total, userId } = this.props;

        if (userId) {
            const { data: articles } = await dataProvider(GET_LIST, `articles?filter[status][in][]=${IN_CART}&filter[updatedBy][in][]=${userId}&limit=${total ?? 1000}`, {});

            return articles.map(item => item.id);
        } else {
            const user = JSON.parse(localStorage.getItem('user'));

            if (!user?.id) return;

            const { data: articles } = await dataProvider(GET_LIST, `articles?filter[status][in][]=${IN_CART}&filter[updatedBy][in][]=${user.id}&limit=${total ?? 1000}`, {});

            return articles.map(item => item.id);
        }
    }

    handleConfirmDialog = async ({ address, date, ...delivery }) => {
        const { setListSelectedIds, record, dispatch } = this.props;
        const { isOtherAddress, changingAddress, deliveryType } = this.state;

        const selectedIds = this.props.deliverAll ? await this.getArticles() : this.props.selectedIds;

        if (selectedIds || (record && record.id)) {
            setListSelectedIds('articles', []);
            let changed = false;
            if (isOtherAddress && changingAddress) {
                delete address.created_at;
                delete address.deleted_at;
                delete address.updated_at;
                delete changingAddress.created_at;
                delete changingAddress.deleted_at;
                delete changingAddress.updated_at;
                Object.keys(address).map(key => {
                    if (address[key] !== (changingAddress[key] || '')) {
                        changed = true;
                    }
                    return key;
                });
                if (changed) {
                    const { json } = await httpClient(
                        `${process.env.REACT_APP_API_URL}/deliveries?filter[id][neq]=${delivery.id}&filter[address]=${address.id}`,
                        {
                            method: 'GET',
                        },
                    );
                    if (json.length) {
                        delete address.id;
                    }
                }
            }

            const result = await httpClient(
                `${process.env.REACT_APP_API_URL}/deliveries`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        ...delivery,
                        address: deliveryType === DELIVERY ? (isOtherAddress ? address : {
                            id: address.id,
                        }) : undefined,
                        articles_ids: selectedIds || [record.id],
                        date: date ? format(new Date(date), 'yyyy-MM-dd\'T\'HH:mm:ssxxx') : undefined,
                    }),
                }
            );

            if (isOtherAddress) {
                httpClient(
                    `${process.env.REACT_APP_API_URL}/loadingpoints`,
                    {
                        method: 'POST',
                        body: JSON.stringify({
                            address: {
                                id: result.json[0].address.id,
                            },
                            project: {
                                id: result.json[0].articles[0].project
                            },
                            is_destination: true,
                        }),
                    },
                );
            }

            dispatch(push('shopping-cart'));

        }
    };

    handleDeliverClick = async event => {
        event.stopPropagation();

        const { dataProvider, record } = this.props;
        const selectedIds = this.props.deliverAll ? await this.getArticles() : this.props.selectedIds;
        if (selectedIds || (record && record.id)) {
            const { data: unloadingPoints } = await dataProvider(GET_LIST, 'loadingpoints', {
                filter: {
                    articles_ids: selectedIds || [record.id],
                    isDestination: 1,
                },
            });

            if (this._isMounted) {
                const unloadingAddresses = unloadingPoints.map(({ address }) => address);

                if (unloadingAddresses.length === 0) {
                    this.onChangeAddress(true);
                }

                this.setState({
                    unloadingAddresses,
                    isOpenDialog: true,
                });
            }
        }
    };

    handleChangeType = deliveryType => {
        this.setState({
            deliveryType
        })
    }

    handleDialogClose = () => {
        if (this._isMounted) {
            this.setState({
                isOpenDialog: false,
            });
        }
    };

    onChangeAddress = isOtherAddress => {
        this.setState({
            isOtherAddress,
            isPickup: false,
        });
    };

    onChangePickup = () => {
        this.setState({
            isPickup: true,
        });
    };

    setChangingAddress = changingAddress => {
        this.setState({
            changingAddress
        });
    }

    render() {
        const { isOpenDialog, unloadingAddresses, isOtherAddress, isPickup, deliveryType } = this.state;

        const { deliverAll } = this.props;

        return (
            <Fragment>
                <Button style={deliverAll ? { marginRight: '12px' } : {}} label={deliverAll ? 'ra.action.order_all' : 'ra.action.order'} onClick={this.handleDeliverClick}>
                    <LocalShippingIcon />
                </Button>

                <DialogForm
                    disableMargin
                    form="deliver"
                    onClose={this.handleDialogClose}
                    onSubmit={this.handleConfirmDialog}
                    size='sm'
                    showDialog={isOpenDialog}
                    title="ra.action.order"
                    resource="deliveries"
                    customSaveButton={
                        <Button label="ra.action.order_now">
                            <SaveIcon />
                        </Button>
                    }
                >
                    <DeliveryFields
                        isPopup
                        unloadingAddresses={unloadingAddresses}
                        isOtherAddress={isOtherAddress}
                        isPickup={isPickup}
                        formName="deliver"
                        onChangeAddress={this.onChangeAddress}
                        onChangePickup={this.onChangePickup}
                        setChangingAddress={this.setChangingAddress}
                        currentType={deliveryType}
                        onChangeType={this.handleChangeType}
                    />
                </DialogForm>
            </Fragment>
        );
    }

}

const enhance = compose(
    connect(undefined, { setListSelectedIds }),
    withDataProvider,
);

export default enhance(DeliveryButton);
