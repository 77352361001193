import React from 'react';
import { SimpleShowLayout } from 'react-admin';

import {
    MobileGrid as BaseMobileGrid,
    UpdatedAtField,
} from '../components';
import { ROLE_AGENT, ROLE_SUPER_ADMIN } from '../users/roles';

const TitleMobileGrid = ({ record }) => (
    <span>
        #{record.id}. {record.name}
    </span>
);

const BodyMobileGrid = ({ record, className, ...props }) => (
    <SimpleShowLayout className={className} record={record} {...props}>
        <UpdatedAtField />
    </SimpleShowLayout>
);

const MobileGrid = ({ permissions, ...props }) => (
    <BaseMobileGrid {...props}
                    permissions={permissions}
                    title={<TitleMobileGrid />}
                    body={<BodyMobileGrid />}
                    deletable={permissions === ROLE_SUPER_ADMIN || permissions === ROLE_AGENT}
                    editable={permissions === ROLE_SUPER_ADMIN || permissions === ROLE_AGENT}
    />
);

export default MobileGrid;
