import { Card, CardHeader, Typography, withStyles } from "@material-ui/core";
import React from "react";
import { translate } from "react-admin";
import { compose } from "recompose";
import { lightTheme } from "../layout/themes";

import { BodyMobileGrid as ArticleBodyMobileGrid } from "../articles/MobileGrid";
import generalStyles from "../styles";
import ExchangeArticleButton from "./ExchangeArticleButton";
import RemoveArticleButton from "./RemoveArticleButton";
import { OUTSOURCED } from "../articles/statuses";

const styles = theme => ({
  ...generalStyles(theme),
  root: {
    marginTop: theme.spacing.unit
  }
});

const DeliveryArticle = ({
  classes,
  deliveryId,
  article,
  permissions,
  translate,
  scannedBarcodes,
  handleConfirmRemove,
  exchange
}) => {
  const scannedArticleRowStyle = ({ barcodes, status }) => {
    if (status === OUTSOURCED) {
      return {
        backgroundColor: lightTheme.palette.primary.outsourced
      };
    } else if (
      !barcodes.filter(({ id }) => !scannedBarcodes.includes(id)).length
    ) {
      return {
        backgroundColor: lightTheme.palette.primary.scanned
      };
    }
  };
  return (
    <Card className={classes.root} style={scannedArticleRowStyle(article)}>
      <CardHeader
        title={
          <div className={`${classes.flex} ${classes.flexCenter}`}>
            <Typography variant="title" className={classes.flexChild}>
              {translate("resources.articles.name", { smart_count: 1 })} #
              {article.id}
            </Typography>
            <RemoveArticleButton
              record={article}
              deliveryId={deliveryId}
              scannedBarcodes={scannedBarcodes}
              handleConfirmRemove={handleConfirmRemove}
            />
            <ExchangeArticleButton
              record={article}
              scannedBarcodes={scannedBarcodes}
              deliveryId={deliveryId}
              exchange={exchange}
            />
          </div>
        }
      />
      <ArticleBodyMobileGrid
        record={article}
        permissions={permissions}
        resource="articles"
        outsourcingPage={true}
        scannedBarcodes={scannedBarcodes}
      />
    </Card>
  );
};

const enhance = compose(
  withStyles(styles),
  translate
);

export default enhance(DeliveryArticle);
