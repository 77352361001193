import { Avatar, Card, CardContent, CardHeader, CircularProgress, withStyles } from '@material-ui/core';
import { Edit as EditIcon, Save as SaveIcon } from '@material-ui/icons';
import RichTextInput from 'ra-input-rich-text';
import React, { Component, Fragment } from 'react';
import { Button } from 'react-admin';
import { connect } from 'react-redux';
import TimeAgo from 'react-timeago';
import { compose } from 'recompose';

import DeleteButton from './DeleteButton';
import ReplyButton from './ReplyButton';

const styles = theme => ({
    card: {
        width: '100%',
    },
    '@global': {
        'p:first-child': {
            marginTop: 0,
        },
        'p:last-child': {
            marginBottom: 0,
        },
    },
    content: {
        color: theme.palette.primary.special
    }
});

class Comment extends Component {

    state = {
        isEdit: false,
    };

    constructor(props) {
        super(props);

        const { node: { body } } = this.props;

        this.body = body;
    }

    handleChangeComment = body => {
        this.body = body;
    };

    handleConfirmDelete = () => {
        const { node: { id: commentId }, onDelete } = this.props;

        onDelete(commentId);
    };

    handleEditClick = () => {
        this.setState({
            isEdit: true,
        });
    };

    handleSaveClick = async () => {
        const { node: { id: commentId }, onEdit } = this.props;

        await onEdit(commentId, this.body);

        this.setState({
            isEdit: false,
        });
    };

    handleReply = async body => {
        const { node: { id: commentId }, onAdd } = this.props;

        return await onAdd(commentId, body);
    };

    render() {
        const {
            classes,
            currentUser: {
                id: currentUserId,
            },
            isLoading,
            node: {
                author: {
                    id: authorId,
                    firstname, 
                    surname,
                    photo: avatar
                },
                created_at,
            },
        } = this.props;
        const { isEdit } = this.state;

        return (
            <Card className={classes.card}>
                <CardHeader
                    avatar={
                        avatar ?
                            <Avatar aria-label="recipe" src={avatar} alt={(firstname ? firstname.charAt(0) : '') + (surname ? surname.charAt(0) : '')} />
                            :
                            <Avatar aria-label="recipe">
                                {(firstname ? firstname.charAt(0) : '') + (surname ? surname.charAt(0) : '')}
                            </Avatar>
                    }
                    title={`${firstname} ${surname}`.trim()}
                    subheader={
                        <TimeAgo date={created_at} />
                    }
                    action={
                        <div>
                            {currentUserId && authorId === currentUserId && (
                                <Fragment>
                                    <DeleteButton onConfirm={this.handleConfirmDelete} />
                                    {isEdit ? (
                                        <Button label="ra.action.save" color="primary" onClick={this.handleSaveClick}>
                                            {isLoading ? <CircularProgress size={20} thickness={2} /> : <SaveIcon />}
                                        </Button>
                                    ) : (
                                        <Button label="ra.action.edit" color="primary" onClick={this.handleEditClick}>
                                            <EditIcon />
                                        </Button>
                                    )}
                                </Fragment>
                            )}
                            <ReplyButton onReply={this.handleReply} />
                        </div>
                    }
                />
                {isEdit ? (
                    <CardContent>
                        <RichTextInput source="body"
                                       meta={{ error: null }}
                                       input={{
                                           value: this.body,
                                           onChange: this.handleChangeComment,
                                       }}
                        />
                    </CardContent>
                ) : <CardContent className={classes.content} dangerouslySetInnerHTML={{ __html: this.body }} />}
            </Card>
        );
    }

}

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        null,
    ),
);

export default enhance(Comment);
