import React from 'react';
import {Show, CardActions, ListButton} from 'react-admin';

import {BodyMobileGrid} from './MobileGrid';
import ArticleTitle from './ArticleTitle';
import {ROLE_COLLECTOR_GUY} from "../users/roles";
import AddToCartButton from "./AddToCartButton";

const ArticleShowActions = ({permissions, data, resource, basePath, ...props}) => {
    if (!data) return null;

    return (
        <CardActions>
            {(permissions !== ROLE_COLLECTOR_GUY) && (
                <AddToCartButton record={data} resource={resource} basePath={basePath}/>
            )}
            <ListButton record={data} resource={resource} basePath={basePath}/>
        </CardActions>
    )
};

const ArticleShow = ({permissions, ...props}) => (
    <Show title={<ArticleTitle/>} actions={<ArticleShowActions permissions={permissions}/>} {...props}>
        <BodyMobileGrid isEmbedComments={true} permissions={permissions}/>
    </Show>
);

export default ArticleShow;
