import React, { Component } from 'react';
import { DELETE_MANY, withDataProvider } from 'react-admin';
import { push } from 'react-router-redux';

import ScanDeliveryBarcodes from '../deliveries/ScanDeliveryBarcodes';

class Revert extends Component {

    handleRevert = async (deliveryId, projectId, articles) => {
        const { dataProvider, dispatch } = this.props;

        await dataProvider(DELETE_MANY, `deliveries/${deliveryId}/articles`, {
            ids: articles.map(({ id }) => id),
        });

        dispatch(push(`projects/${projectId}`));
    };

    render() {
        const { location } = this.props;

        return (
            <ScanDeliveryBarcodes location={location}
                                  completeButton="revert"
                                  onComplete={this.handleRevert}
            />
        );
    }

}

export default withDataProvider(Revert);
