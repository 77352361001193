import {
    CircularProgress,
    Dialog as BaseDialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    withStyles,
} from '@material-ui/core';
import { CheckCircle as ActionCheck, ErrorOutline as AlertError } from '@material-ui/icons';
import React from 'react';
import { Button, translate } from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'recompose';

const styles = theme => ({
    contentText: {
        [theme.breakpoints.up('sm')]: {
            minWidth: 400,
        },
        color: theme.palette.primary.special
    },
    confirmPrimary: {
        color: theme.palette.primary.main,
    },
});

const UnconnectedDialog = ({
                               children,
                               classes,
                               content,
                               customButtons,
                               disabled,
                               isLoading,
                               isOpen,
                               onClose,
                               onConfirm,
                               title,
                               translate,
                               translateOptions,
                           }) => (
    <BaseDialog open={isOpen} onClose={onClose} aria-labelledby="alert-dialog-title">
        {title && <DialogTitle id="alert-dialog-title">
            {translate(title, translateOptions)}
        </DialogTitle>}
        <DialogContent className={classes.contentText}>
            {(content && translate(content, translateOptions)) || children}
        </DialogContent>
        <DialogActions>
            <Button label="ra.action.cancel" onClick={onClose}>
                <AlertError />
            </Button>
            {onConfirm && (
                <Button label="ra.action.confirm"
                        onClick={onConfirm}
                        className={`ra-confirm ${classes.confirmPrimary} autoFocus`}
                        disabled={isLoading || disabled}
                >
                    {isLoading ? (
                        <CircularProgress size={20} thickness={2} />
                    ) : (
                        <ActionCheck />
                    )}
                </Button>
            )}
            {customButtons}
        </DialogActions>
    </BaseDialog>
);

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
    withStyles(styles),
    translate,
    connect(
        mapStateToProps,
        null,
    ),
);

const Dialog = enhance(UnconnectedDialog);

Dialog.defaultProps = {
    disabled: false,
};

export default Dialog;
