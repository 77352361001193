import { withStyles } from '@material-ui/core';
import { AddShoppingCart } from '@material-ui/icons';
import React, { Component } from 'react';
import { Button, crudUpdateMany } from 'react-admin';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import styles from '../styles';

import { IN_CART, IN_WAREHOUSE } from './statuses';

class AddToCartButton extends Component {

    handleAddToCart = event => {
        event.stopPropagation();

        const { basePath, selectedIds, crudUpdateMany, record } = this.props;

        if (selectedIds || (record && record.id)) {
            crudUpdateMany('articles', selectedIds || [record.id], { status: IN_CART }, basePath);
        }
    };

    render() {
        const { classes, record } = this.props;

        return !record || record.status === IN_WAREHOUSE ? (
            <Button label="ra.action.add_to_cart" className={classes.nowrap} onClick={this.handleAddToCart}>
                <AddShoppingCart />
            </Button>
        ) : null;
    }

}

const enhance = compose(
    withStyles(styles),
    connect(undefined, { crudUpdateMany }),
);

export default enhance(AddToCartButton);
