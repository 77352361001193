import { Computer as ComputerIcon, Settings as SettingsIcon, AccountCircle } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import { DashboardMenuItem, MenuItemLink, Responsive, WithPermissions, translate, changeListParams } from 'react-admin';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { push as pushAction } from 'react-router-redux';

import agents from '../agents';
import articleCategories from '../articleCategories';
import deliveries from '../deliveries';
import articles from '../articles';
import customers from '../customers';
import projects from '../projects';
import warehouses from '../warehouses';
import users from '../users';
import outsourcing from '../outsourcing';
import { showAlertHelper } from '../helpers';

import {
    ROLE_AGENT,
    ROLE_COLLECTOR_GUY,
    ROLE_CUSTOMER,
    ROLE_MAIN_CUSTOMER,
    ROLE_OFFICE_EMPLOYEE,
    ROLE_SUPER_ADMIN,
    ROLE_WAREHOUSE_MANAGER,
} from '../users/roles';
import { Dialog } from '../components';

class Menu extends Component {

    state = {
        isOpenConfirmDialog: false,
    };

    toPathname = '';

    constructor(props) {
        super(props);

        this.handleMenuClick = this.handleMenuClick.bind(this);
    }

    handleConfirmDialog = () => {
        const { dispatch, push } = this.props;

        this.removeArticleParamsFromLocalStorage();

        dispatch(push(this.toPathname));
        this.handleDialogClose();
        showAlertHelper.purgeState();
    };

    handleDialogClose = () => {
        this.setState({
            isOpenConfirmDialog: false,
        });
    };

    handleMenuClick(event) {
        const { dispatch, location: { pathname } } = this.props;

        if (pathname === '/shopping-cart') dispatch(changeListParams('articles', {}));

        if (/^\/[a-z]+\/(\d+|create)$/g.test(pathname) && showAlertHelper.compareStates()) {
            this.toPathname = event.currentTarget.pathname;

            this.setState({
                isOpenConfirmDialog: true,
            });

            event.preventDefault();
        } else {
            showAlertHelper.purgeState();
            this.removeArticleParamsFromLocalStorage();
        }
    };

    removeArticleParamsFromLocalStorage = () => {
        localStorage.removeItem('changed_storage_area');
        localStorage.removeItem('is_create');
        localStorage.removeItem('is_edit');
    };

    render() {
        const { logout, translate, location, match } = this.props;
        const { isOpenConfirmDialog } = this.state;

        return (
            <div>
                <Dialog isOpen={isOpenConfirmDialog}
                    title="ra.message.to_list"
                    content="ra.message.to_list_content"
                    onConfirm={this.handleConfirmDialog}
                    onClose={this.handleDialogClose}
                />
                {' '}
                <WithPermissions location={location}
                    match={match}
                    render={({ permissions: role }) => (
                        <Fragment>
                            {(role === ROLE_SUPER_ADMIN || role === ROLE_AGENT
                                || role === ROLE_WAREHOUSE_MANAGER || role === ROLE_OFFICE_EMPLOYEE) && (
                                    <DashboardMenuItem onClick={this.handleMenuClick}
                                        leftIcon={<ComputerIcon />}
                                    />
                                )}
                            {(role !== ROLE_COLLECTOR_GUY && role !== ROLE_WAREHOUSE_MANAGER
                                && role !== ROLE_MAIN_CUSTOMER && role !== ROLE_CUSTOMER) && (
                                    <MenuItemLink to="/customers"
                                        primaryText={translate(`resources.customers.name`, {
                                            smart_count: 2,
                                        })}
                                        leftIcon={<customers.icon />}
                                        onClick={this.handleMenuClick}
                                    />
                                )}
                            {role !== ROLE_COLLECTOR_GUY && (
                                <MenuItemLink to="/projects"
                                    primaryText={translate(`resources.projects.name`, {
                                        smart_count: 2,
                                    })}
                                    leftIcon={<projects.icon />}
                                    onClick={this.handleMenuClick}
                                />
                            )}
                            {(role === ROLE_SUPER_ADMIN || role === ROLE_AGENT
                                || role === ROLE_WAREHOUSE_MANAGER || role === ROLE_OFFICE_EMPLOYEE) && (
                                    <MenuItemLink to="/storageareas"
                                        primaryText={translate(`resources.warehouses.name`, {
                                            smart_count: 2,
                                        })}
                                        leftIcon={<warehouses.icon />}
                                        onClick={this.handleMenuClick}
                                    />
                                )}
                        </Fragment>
                    )}
                />
                <MenuItemLink to="/articles"
                    primaryText={translate(`resources.articles.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<articles.icon />}
                    onClick={this.handleMenuClick}
                />
                <WithPermissions location={location}
                    match={match}
                    render={({ permissions: role }) => (
                        <Fragment>
                            {role === ROLE_SUPER_ADMIN && (
                                <MenuItemLink to="/agents"
                                    primaryText={translate(`resources.agents.name`, {
                                        smart_count: 2,
                                    })}
                                    leftIcon={<agents.icon />}
                                    onClick={this.handleMenuClick}
                                />
                            )}
                            {(role === ROLE_SUPER_ADMIN || role === ROLE_AGENT
                                || role === ROLE_OFFICE_EMPLOYEE || role === ROLE_MAIN_CUSTOMER) && (
                                    <MenuItemLink to="/users"
                                        primaryText={translate(`resources.users.name`, {
                                            smart_count: 2,
                                        })}
                                        leftIcon={<users.icon />}
                                        onClick={this.handleMenuClick}
                                    />
                                )}
                            {role !== ROLE_COLLECTOR_GUY && role !== ROLE_CUSTOMER
                                && role !== ROLE_MAIN_CUSTOMER && (
                                    <MenuItemLink to="/outsourcing"
                                        primaryText={translate(`resources.outsourcing.name`, {
                                            smart_count: 2,
                                        })}
                                        leftIcon={<outsourcing.icon />}
                                        onClick={this.handleMenuClick}
                                    />
                                )}
                        </Fragment>
                    )}
                />
                <MenuItemLink to="/deliveries"
                    primaryText={translate(`resources.deliveries.multiply_name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<deliveries.icon />}
                    onClick={this.handleMenuClick}
                />

                <Responsive xsmall={
                    <Fragment>
                        <MenuItemLink to="/profile"
                            primaryText={translate('pos.profile')}
                            leftIcon={<AccountCircle />}
                            onClick={this.handleMenuClick}
                        />
                        <MenuItemLink to="/configuration"
                            primaryText={translate('pos.configuration')}
                            leftIcon={<SettingsIcon />}
                            onClick={this.handleMenuClick}
                        />
                        <WithPermissions location={location}
                            match={match}
                            render={({ permissions: role }) =>
                                role !== ROLE_MAIN_CUSTOMER && role !== ROLE_CUSTOMER ? (
                                    <MenuItemLink to="/article-categories"
                                        primaryText={translate('resources.article-categories.name', {
                                            smart_count: 2,
                                        })}
                                        leftIcon={<articleCategories.icon />}
                                        onClick={this.handleMenuClick}
                                    />
                                ) : null}
                        />
                        {logout}
                    </Fragment>
                } medium={null} />
            </div>
        );
    }

}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
    locale: state.i18n.locale,
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        dispatch => ({
            push: pushAction,
            dispatch
        }),
    ),
    translate,
);

export default enhance(Menu);
