import { get } from 'lodash';
import { Card, CardHeader, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { CardContentInner, NumberField, SimpleShowLayout, TextField, translate } from 'react-admin';
import { compose } from 'recompose';

import { styles } from './ProjectAreas';

const measurementStyles = {
    root: {
        overflow: 'hidden',
    },
    inline: {
        float: 'left',
        marginRight: '0.25em',
    },
};

const UnconnectedMeasurement = ({ measure, className, classes, ...props }) => (
    <div className={`${className} ${classes.root}`}>
        <NumberField {...props} className={classes.inline} />
        <Typography component="span" variant="body1" className={classes.inline}>{measure}</Typography>
    </div>
);

const Measurement = withStyles(measurementStyles)(UnconnectedMeasurement);
Measurement.defaultProps = {
    addLabel: true,
};

const ProjectAreasShow = ({ translate, classes, record, className }) => {
    const areas = get(record, 'project_areas', []);
    const totalArea = get(record, 'area', 0);

    return totalArea === 0 ? null : (
        <Card className={className}>
            <CardHeader className={classes.header} title={
                <span>
                    {translate('resources.projects.fields.area')} <span className={classes.totalArea}>
                        ({translate('resources.projects.fields.total_area')}: {totalArea}m²)
                    </span>
                </span>
            } />

            <CardContentInner>
                {areas.length > 0 && (
                    <div className={classes.container}>
                        {areas.map(({ storage_area: { full_name }, ...area }) => (
                            <div key={area.id} className={`${classes.input} ${classes.projectAreas}`}>
                                <div className={classes.titleProjectAreas}>
                                    {full_name}
                                </div>
                                <SimpleShowLayout record={area} className={classes.contentProjectAreas}>
                                    {area.area && (
                                        <Measurement source="area"
                                                     label="resources.project_areas.fields.area"
                                                     className={classes.fullWidth}
                                                     measure="m²"
                                        />
                                    )}
                                    {area.transfer_tools && (
                                        <TextField source="transfer_tools"
                                                   label="resources.general.fields.transfer_tools"
                                                   className={classes.fullWidth}
                                        />
                                    )}
                                </SimpleShowLayout>
                            </div>
                        ))}
                    </div>
                )}

                {record.measurements && (
                    <SimpleShowLayout record={record} className={classes.container}>
                        <Measurement source="measurements" resource="projects" measure="m³" />
                    </SimpleShowLayout>
                )}
            </CardContentInner>
        </Card>
    );
};

const enhance = compose(
    withStyles(styles),
    translate,
);

export default enhance(ProjectAreasShow);
