import {Card, CardActions, withStyles} from '@material-ui/core';
import {SettingsBackupRestore} from '@material-ui/icons';
import outsourcing from '../outsourcing';
import React, {Component} from 'react';
import {Button, GET_LIST, withDataProvider} from 'react-admin';
import {compose} from 'recompose';

import {QrReaderButton} from '../components';

import ProjectList from './ProjectList';

const styles = theme => ({
    root: {
        maxWidth: theme.breakpoints.values['md'],
    },
    actions: {
        justifyContent: 'flex-end',
        paddingLeft: 20,
        paddingRight: 20,
    },
});

class ScanDeliveryBarcodes extends Component {

    _isMounted = false;
    state = {
        projects: [],
        isCompleteDelivery: false,
    };

    addScannedBarcode = barcodeId => {
        const {completeButton} = this.props;
        const {projects} = this.state;
        const article = this.findArticleByBarcode(barcodeId);

        if (article === null) {
            return;
        }

        let project = projects.find(({id}) => id === article.project);

        if (project === undefined) {
            project = this.createScannedProject(article.project);
            projects.push(project);
        }

        let scannedArticle = project.articles.find(({id}) => id === article.id);

        if (scannedArticle === undefined) {
            scannedArticle = this.createScannedArticle(article);
            project.articles.push(scannedArticle);
        }

        if (!scannedArticle.barcodes.includes(barcodeId)) {
            scannedArticle.barcodes.push(barcodeId);
        }

        project.scannedBarcodes = project.articles
            .reduce((accumulate, {barcodes}) => accumulate + barcodes.length, 0);

        const scannedBarcodes = projects.reduce((accumulate, {scannedBarcodes}) => accumulate + scannedBarcodes, 0);
        const totalBarcodes = projects.reduce((accumulate, {totalBarcodes}) => accumulate + totalBarcodes, 0);

        if (this._isMounted) {
            this.setState({
                projects,
                isCompleteDelivery: completeButton === 'outsource' ?
                    (scannedBarcodes === totalBarcodes) : projects.every(
                        ({articles}) => articles.every(
                            ({totalBarcodes, barcodes}) => barcodes.length === totalBarcodes,
                        ),
                    ),
            });
        }
    };

    componentDidMount = async () => {
        const {location: {search}} = this.props;
        const searchParams = new URLSearchParams(search);
        const barcodeId = parseInt(searchParams.get('barcode'));

        this._isMounted = true;

        const article = await this.fetchArticleByBarcode(barcodeId);

        if (article !== null) {
            const delivery = await this.fetchDeliveryByArticle(article.id);

            if (this._isMounted) {
                this.setState({delivery}, () => {
                    this.addScannedBarcode(barcodeId);
                });
            }
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    createScannedArticle = ({id, barcodes}) => ({
        id,
        totalBarcodes: barcodes.length,
        barcodes: [],
    });

    createScannedProject = articleProject => {
        const {delivery} = this.state;
        const totalBarcodes = delivery.articles.filter(article => article.project === articleProject)
            .reduce((accumulator, article) => accumulator + article.barcodes.length, 0);

        return {
            id: articleProject,
            articles: [],
            totalBarcodes,
        };
    };

    fetchArticleByBarcode = async barcodeId => {
        const {dataProvider} = this.props;

        const {data: articles} = await dataProvider(GET_LIST, 'articles', {
            queryParams: {
                fromAll: 1,
            },
            filter: {
                'barcodes.id': barcodeId,
            },
        });

        if (articles.length > 0) {
            return articles[0];
        }

        return null;
    };

    fetchDeliveryByArticle = async articleId => {
        const {dataProvider} = this.props;

        const {data: deliveries} = await dataProvider(GET_LIST, 'deliveries', {
            filter: {
                'articles.id': articleId,
            },
        });

        if (deliveries.length > 0) {
            return deliveries[0];
        }

        return null;
    };

    findArticleByBarcode = barcodeId => {
        const {delivery} = this.state;

        if (delivery && delivery.articles) {
            for (const article of delivery.articles) {
                if (article.barcodes.findIndex(({id}) => id === barcodeId) > -1) {
                    return article;
                }
            }
        }

        return null;
    };

    handleComplete = () => {
        const {onComplete} = this.props;
        const {delivery, projects} = this.state;

        onComplete(delivery.id, projects[0].id, projects[0].articles);
    };

    handleConfirmBarcode = value => {
        const [, search] = value.split('?');

        const searchParams = new URLSearchParams(search);
        const barcodeId = parseInt(searchParams.get('barcode'));

        this.addScannedBarcode(barcodeId);
    };

    render() {
        const {classes, completeButton} = this.props;
        const {isCompleteDelivery, projects} = this.state;

        return (
            <Card className={classes.root}>
                <ProjectList projects={projects}/>
                <CardActions className={classes.actions}>
                    <QrReaderButton onConfirm={this.handleConfirmBarcode}/>
                    <Button variant="contained"
                            label={`ra.action.${completeButton}`}
                            disabled={!isCompleteDelivery}
                            onClick={this.handleComplete}
                    >
                        {completeButton === 'outsource' ? <outsourcing.icon/> : <SettingsBackupRestore/>}
                    </Button>
                </CardActions>
            </Card>
        );
    }

}

const enhance = compose(
    withStyles(styles),
    withDataProvider,
);

export default enhance(ScanDeliveryBarcodes);
