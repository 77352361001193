import { isEqual } from 'lodash';
import { withStyles } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import QRCode from 'qrcode.react';

import { BarcodeView } from '../components';
import { nextUniqueKey } from '../helpers';

import TreeForPrint from './TreeForPrint';

const styles = {
    qrCodeContainer: {
        boxSizing: "border-box",
        padding: 3.78,
        float: "left",
        "&:nth-child(24n + 1), &:nth-child(24n + 2), &:nth-child(24n + 3), &:nth-child(24n + 4)": {
            paddingTop: 39.44
        },
        "&:nth-child(24n), &:nth-child(24n - 1), &:nth-child(24n - 2), &:nth-child(24n - 3)": {
            paddingBottom: 24.32
        }
    },
    descriptionBarcode: {
        color: '#000',
        position: 'absolute',
        fontSize: 10,
        lineHeight: '10px',
        wordBreak: 'break-all',
        top: -20,
        left: -20,
        width: '155px !important',
        height: '155px !important',
        "& > div": {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            position: 'absolute',
            width: 134
        },
        "& .snake-0": {
            transform: 'rotate(-90deg)',
            transformOrigin: '0 100%',
            bottom: 20,
            left: 10,
        },
        "& .snake-1": {
            transform: 'rotate(-90deg)',
            transformOrigin: '0 100%',
            bottom: 20,
            left: 20,
        },
        "& .snake-2": {
            top: 0,
            left: 20,
        },
        "& .snake-3": {
            top: 10,
            left: 20,
        },
        "& .snake-4": {
            right: 0,
            bottom: -10,
            transform: 'rotate(90deg)',
            transformOrigin: '100% 0',
        },
        "& .snake-5": {
            right: 10,
            bottom: -10,
            transform: 'rotate(90deg)',
            transformOrigin: '100% 0',
        },
        "& .snake-6": {
            bottom: 0,
            left: 0,
            transform: 'rotate(180deg)',
        },
        "& .snake-7": {
            bottom: 10,
            left: 0,
            transform: 'rotate(180deg)',
        },
    },
};

class QRCodeWithDescription extends Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.sectionId !== nextProps.sectionId || this.props.path !== nextProps.path;
    }

    render() {
        const { sectionId, sectionName, path, size, classes } = this.props;

        return (
            <Fragment>
                <QRCode value={JSON.stringify({
                            storagearea: sectionId,
                            name: sectionName,
                        })}
                        size={size}
                        style={{ display: 'block' }}
                        level="H"
                />
                <div
                    className={classes.descriptionBarcode}
                >
                    {
                        path.map((el, ind) => {
                            return (
                                <div className={`snake-${ind}`} key={ind}>
                                    {el}
                                </div>
                            )
                        })
                    }
                </div>
            </Fragment>
        );
    }

}

class NodeForPrint extends Component {

    cloneSection(section) {
        return {
            ...section,
            children: section.children.map(child => ({
                ...child,
                uniqueKey: child.uniqueKey || nextUniqueKey(),
            })),
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !isEqual(this.props.section, nextProps.section);
    }

    render() {
        const { section: propsSection, classes, isRoot, path: pathProps, disabled } = this.props;
        const section = this.cloneSection(propsSection);

        const area = !!section.area ? `(${section.area}m²)` : '';
        const path = !isRoot
            ? [...pathProps, `${section.name} ${area}`]
            : [...pathProps];

        return section ? (
            <Fragment>
                {!isRoot && (
                    <Fragment>
                        {section.id && section.children.length === 0 && (
                            <div className={classes.qrCodeContainer}>
                                <BarcodeView size={170.14}>
                                    <QRCodeWithDescription sectionId={section.id} 
                                                           sectionName={section.name} 
                                                           path={path} 
                                                           classes={classes}/>
                                </BarcodeView>
                            </div>
                        )}
                    </Fragment>
                )}
                {section.children.length > 0 && (
                    <TreeForPrint
                        children={section.children}
                        path={path}
                        disabled={disabled}
                    />
                )}
            </Fragment>
        ) : null;
    }
}

export default withStyles(styles)(NodeForPrint);
