import BaseDateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';
import {
    DatePicker as BaseDatePicker,
    TimePicker as BaseTimePicker,
    DateTimePicker as BaseDateTimePicker,
    MuiPickersUtilsProvider,
} from 'material-ui-pickers';
import { addField, FieldTitle } from 'ra-core';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class DateFnsUtils extends BaseDateFnsUtils {

    getStartOfMonth(value) {
        return this.startOfMonth(value);
    }

}

const dateFnsLocale = {
    en: enLocale,
    de: deLocale,
};

class UnconnectedBasePickerComponent extends Component {

    onChange(date) {
        this.props.input.onChange(date);
        this.props.input.onBlur();
    }

    render() {
        const {
            input,
            options,
            label,
            source,
            resource,
            isRequired,
            className,
            meta,
            utils,
            locale,
            PickerComponent,
        } = this.props;

        const { touched, error } = meta;

        return (
            <MuiPickersUtilsProvider utils={utils} locale={dateFnsLocale[locale]}>
                <PickerComponent {...options}
                                 label={
                                     <FieldTitle label={label}
                                                 source={source}
                                                 resource={resource}
                                                 isRequired={isRequired}
                                     />
                                 }
                                 margin="normal"
                                 error={!!(touched && error)}
                                 helperText={touched && error}
                                 ref={(node) => {
                                     this.picker = node;
                                 }}
                                 className={className}
                                 value={input.value ? input.value : null}
                                 onChange={date => this.onChange(date)}
                />
            </MuiPickersUtilsProvider>
        );
    }

}

const mapStateToProps = state => ({
    locale: state.i18n.locale,
});

const BasePickerComponent = connect(
    mapStateToProps,
    undefined,
)(UnconnectedBasePickerComponent);

BasePickerComponent.defaultProps = {
    input: {},
    isRequired: 'false',
    label: '',
    meta: { touched: false, error: false },
    options: {},
    resource: '',
    source: '',
    labelTime: '',
    className: '',
    utils: DateFnsUtils,
    locale: undefined,
};

const DatePicker = props => <BasePickerComponent {...props} PickerComponent={BaseDatePicker} />;
const TimePicker = props => <BasePickerComponent {...props} PickerComponent={BaseTimePicker} />;
const DateTimePicker = props => <BasePickerComponent {...props} PickerComponent={BaseDateTimePicker} />;

export const DateInput = addField(DatePicker);
export const TimeInput = addField(TimePicker);
export const DateTimeInput = addField(DateTimePicker);
