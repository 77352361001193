import { ViewQuilt as ArticleIcon } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import { Button, SimpleShowLayout, DateField } from 'react-admin';

import {
    AddressField,
    FullNameField,
    MobileGrid as BaseMobileGrid,
} from '../components';
import CancelButton from './CancelButton';
import { ROLE_CUSTOMER } from '../users/roles';

import { CONFIRMED } from './statuses';
import ApproveButton from './ApproveButton';
import DeliveryArticle from './DeliveryArticle';
import TypeField from './TypeField';

const TitleMobileGrid = ({ record }) => (
    <span>
        #{record.id}. {record.address ? (
            <FullNameField record={record.address} />
        ) : record.surname}
    </span>
);

export const BodyMobileGrid = ({ record, basePath, className, isShownArticles, permissions, ...props }) => (
    <SimpleShowLayout className={className} record={record} permissions={permissions} basePath={basePath} {...props}>
        <AddressField
            showName={false}
            label="resources.delivery.fields.recipient"
        />
        <TypeField
            sortBy="address"
            label="resources.delivery.fields.type"
            sortable={false}
        />
        <DateField showTime={true} source="date" />
        {isShownArticles && record.articles.map(article => (
            <DeliveryArticle key={article.id}
                deliveryId={record.id}
                article={article}
                permissions={permissions}
                basePath={basePath}
            />
        ))}
    </SimpleShowLayout>
);

class DeliveryActions extends Component {

    handleToggleArticles = () => {
        const { isShownArticles, setState } = this.props;

        setState({
            isShownArticles: !isShownArticles,
        });
    };

    render() {
        const { permissions, record, isShownArticles, setState, ...props } = this.props;

        return (
            <Fragment>
                {(permissions !== ROLE_CUSTOMER || record.status !== CONFIRMED) && (
                    <CancelButton record={record} {...props} />
                )}
                {permissions !== ROLE_CUSTOMER && record.status !== CONFIRMED && (
                    <ApproveButton record={record} {...props} />
                )}
                <Button label={isShownArticles ? 'ra.action.hide_articles' : 'ra.action.show_articles'}
                    onClick={this.handleToggleArticles}
                >
                    <ArticleIcon />
                </Button>
            </Fragment>
        );
    }

}

const MobileGrid = props => (
    <BaseMobileGrid {...props}
        title={<TitleMobileGrid />}
        body={<BodyMobileGrid />}
        deletable={false}
        customButtons={
            <DeliveryActions />
        }
    />
);

export default MobileGrid;
