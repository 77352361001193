import React from 'react';

import SectionNode from './SectionNode';

const SectionTree = ({ children, className, onChange, onDeleteSection, path, disabled, onExpand, visible, openSection, formSection }) => (
    <div className={`${className}`}>
        {children.map((section, sectionIndex) => (
            <SectionNode
                key={section.uniqueKey}
                formSection={formSection}
                section={section}
                sectionIndex={sectionIndex}
                onDelete={onDeleteSection}
                onExpand={onExpand}
                onChange={onChange}
                path={path}
                disabled={disabled}
                visible={visible}
                openSection={openSection}
            />
        ))}
    </div>
);

export default SectionTree;
