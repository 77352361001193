import React from 'react';
import { SimpleShowLayout } from 'react-admin';

import {
    AddressField,
    FullNameField,
    MobileGrid as BaseMobileGrid,
    UpdatedAtField,
} from '../components';

const TitleMobileGrid = ({ record }) => (
    <span>
        #{record.id}. <FullNameField record={record} /> {record.address.company && `(${record.address.company})`}
    </span>
);

const BodyMobileGrid = ({ record, className, ...props }) => (
    <SimpleShowLayout className={className} record={record} {...props}>
        <AddressField showName={false} />
        {record.invoice_address && <AddressField source="invoice_address" />}
        <UpdatedAtField />
    </SimpleShowLayout>
);

const MobileGrid = props => (
    <BaseMobileGrid {...props}
                    title={<TitleMobileGrid />}
                    body={<BodyMobileGrid />}
    />
);

export default MobileGrid;
