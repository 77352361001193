export const NOT_IN_WAREHOUSE = 'NOT_IN_WAREHOUSE';
export const IN_WAREHOUSE = 'IN_WAREHOUSE';
export const IN_CART = 'IN_CART';
export const ORDERED = 'ORDERED';
export const CONFIRMED = 'CONFIRMED';
export const OUTSOURCED = 'OUTSOURCED';

export default [
    NOT_IN_WAREHOUSE,
    IN_WAREHOUSE,
    IN_CART,
    ORDERED,
    CONFIRMED,
    OUTSOURCED,
];
