import { createBrowserHistory } from 'history';
import React, { useEffect } from 'react';
import { Admin, resolveBrowserLocale } from 'react-admin';
import languages from './users/languages';
import { Redirect } from 'react-router-dom';

import './App.css';

import { Resource } from './components';
import { Dashboard as BaseDashboard } from './dashboard';
import { Login, Layout } from './layout';
import customRoutes from './routes';
import themeReducer from './reducers/themeReducer';

import articleCategories from './articleCategories';
import agents from './agents';
import articles from './articles';
import customers from './customers';
import projects from './projects';
import warehouses from './warehouses';
import users from './users';
import deliveries from './deliveries';
import shoppingCart from './shoppingCart';
import outsourcing from './outsourcing';

import { dataProvider, authProvider, i18nProvider, sagas as customSagas } from './providers';
import { actionsHelper, showAlertHelper } from './helpers';
import {
    ROLE_AGENT,
    ROLE_COLLECTOR_GUY,
    ROLE_CUSTOMER,
    ROLE_MAIN_CUSTOMER,
    ROLE_OFFICE_EMPLOYEE,
    ROLE_SUPER_ADMIN,
    ROLE_WAREHOUSE_MANAGER,
} from './users/roles';
import expandReducer from './reducers/expandReducer';
import cartsReducer from './reducers/cartsReducer';

const history = createBrowserHistory();

const Dashboard = ({ permissions, ...props }) => {
    if (permissions === ROLE_SUPER_ADMIN || permissions === ROLE_AGENT || permissions === ROLE_WAREHOUSE_MANAGER || permissions === ROLE_OFFICE_EMPLOYEE) {
        return (
            <BaseDashboard permissions={permissions} {...props} />
        );
    }
    if (permissions === ROLE_COLLECTOR_GUY || permissions === ROLE_MAIN_CUSTOMER || permissions === ROLE_CUSTOMER) {
        return (
            <Redirect to="/articles" />
        );
    }

    return null;
};

const App = () => {
    const defaultLocale = 'en';
    let browserLocale = resolveBrowserLocale(defaultLocale);
    let language = languages.find(language => language.id === browserLocale);
    if (!language) {
        browserLocale = defaultLocale;
    }

    useEffect(() => {
        showAlertHelper.purgeState();
    }, [])

    return (
        <Admin title="Warehouse"
               dataProvider={dataProvider}
               customReducers={{ theme: themeReducer, openedSections: expandReducer, carts: cartsReducer }}
               customRoutes={customRoutes}
               customSagas={customSagas}
               authProvider={authProvider}
               dashboard={Dashboard}
               loginPage={Login}
               appLayout={Layout}
               locale={browserLocale}
               i18nProvider={i18nProvider}
               history={history}
        >
            {role => [
                <Resource name="users" {...actionsHelper({
                    isCreate: role === ROLE_SUPER_ADMIN || role === ROLE_AGENT || role === ROLE_OFFICE_EMPLOYEE || role === ROLE_MAIN_CUSTOMER,
                    isEdit: role === ROLE_SUPER_ADMIN || role === ROLE_AGENT || role === ROLE_OFFICE_EMPLOYEE || role === ROLE_MAIN_CUSTOMER,
                }, users)} />,
                (role !== ROLE_COLLECTOR_GUY && role !== ROLE_MAIN_CUSTOMER && role !== ROLE_CUSTOMER) ? (
                    <Resource name="customers" {...actionsHelper({
                        isCreate: role !== ROLE_WAREHOUSE_MANAGER,
                        isEdit: role !== ROLE_WAREHOUSE_MANAGER,
                    }, customers)} />
                ) : null,
                role !== ROLE_COLLECTOR_GUY ? (
                    <Resource name="projects" {...actionsHelper({
                        isCreate: role === ROLE_SUPER_ADMIN || role === ROLE_AGENT || role === ROLE_OFFICE_EMPLOYEE,
                        isEdit: role !== ROLE_MAIN_CUSTOMER && role !== ROLE_CUSTOMER,
                    }, projects)} />
                ) : null,
                <Resource name="storageareas" {...actionsHelper({
                    isEdit: role !== ROLE_WAREHOUSE_MANAGER
                        && role !== ROLE_OFFICE_EMPLOYEE
                        && role !== ROLE_MAIN_CUSTOMER
                        && role !== ROLE_CUSTOMER,
                }, warehouses)} />,
                <Resource name="articles" {...actionsHelper({
                    isCreate: role !== ROLE_MAIN_CUSTOMER && role !== ROLE_CUSTOMER,
                    isEdit: role !== ROLE_MAIN_CUSTOMER && role !== ROLE_CUSTOMER,
                }, articles)} />,
                role === ROLE_SUPER_ADMIN ? (
                    <Resource name="agents" {...agents} />
                ) : null,
                <Resource name="article-categories" {...actionsHelper({
                    isCreate: role === ROLE_SUPER_ADMIN
                        || role === ROLE_AGENT
                        || role === ROLE_WAREHOUSE_MANAGER
                        || role === ROLE_OFFICE_EMPLOYEE
                        || role === ROLE_COLLECTOR_GUY,
                    isEdit: role === ROLE_SUPER_ADMIN
                        || role === ROLE_AGENT
                        || role === ROLE_WAREHOUSE_MANAGER
                        || role === ROLE_OFFICE_EMPLOYEE,
                }, articleCategories)} />,
                role !== ROLE_COLLECTOR_GUY ? (
                    <Resource name="shopping-cart" {...shoppingCart} />
                ) : null,
                role !== ROLE_COLLECTOR_GUY && role !== ROLE_CUSTOMER && role !== ROLE_MAIN_CUSTOMER ? (
                    <Resource name="outsourcing" {...outsourcing} />
                ) : null,
                role !== ROLE_COLLECTOR_GUY ? (
                    <Resource name="deliveries" {...actionsHelper({
                        isCreate: false,
                        isEdit: true,
                        isShow: true,
                    }, deliveries)} />
                ) : null,
            ]}
        </Admin>
    );
}

export default App;
