import { CircularProgress } from '@material-ui/core';
import { Reply as ReplyIcon } from '@material-ui/icons';
import RichTextInput from 'ra-input-rich-text';
import React, { Component, Fragment } from 'react';
import { Button } from 'react-admin';
import { connect } from 'react-redux';

import Dialog from '../Dialog';

class ReplyButton extends Component {

    state = {
        body: '',
        isOpenReplyDialog: false,
    };

    handleChangeComment = body => {
        this.setState({ body });
    };

    handleReplyClick = () => {
        this.setState({
            isOpenReplyDialog: true,
        });
    };

    handleReplyDialogClose = () => {
        this.setState({
            isOpenReplyDialog: false,
        });
    };

    handleSendComment = async () => {
        const { onReply } = this.props;

        await onReply(this.state.body);

        this.handleReplyDialogClose();
    };

    render() {
        const { isLoading } = this.props;
        const { isOpenReplyDialog, body } = this.state;

        return (
            <Fragment>
                <Button label="ra.action.reply" color="primary" onClick={this.handleReplyClick}>
                    <ReplyIcon />
                </Button>
                <Dialog isOpen={isOpenReplyDialog}
                        onClose={this.handleReplyDialogClose}
                        customButtons={(
                            <Button label="ra.action.reply"
                                    color="primary"
                                    disabled={isLoading || !body}
                                    onClick={this.handleSendComment}
                            >
                                {isLoading ? <CircularProgress size={20} thickness={2} /> : <ReplyIcon />}
                            </Button>
                        )}
                >
                    <RichTextInput source="body"
                                   meta={{ error: null }}
                                   input={{
                                       value: body,
                                       onChange: this.handleChangeComment,
                                   }}
                    />
                </Dialog>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

export default connect(mapStateToProps, null)(ReplyButton);
