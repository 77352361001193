import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import ShoppingCartList from './ShoppingCartList';
import ShoppingCartShow from './ShoppingCartShow';

export default {
    list: ShoppingCartList,
    icon: ShoppingCartIcon,
    show: ShoppingCartShow
};
