import { Dialog, DialogActions, DialogContent, withStyles } from '@material-ui/core';
import { Close as CloseIcon, Check as CheckIcon } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import { Button, ImageField, NumberField, ReferenceField, TextField, FunctionField } from 'react-admin';
import { AddressField, Datagrid, ListActions as ArticleActions, UpdatedAtField } from '../components';
import generalStyles from '../styles';
import {
    ROLE_AGENT,
    ROLE_COLLECTOR_GUY,
    ROLE_CUSTOMER,
    ROLE_MAIN_CUSTOMER, ROLE_OFFICE_EMPLOYEE,
    ROLE_SUPER_ADMIN,
    ROLE_WAREHOUSE_MANAGER,
} from '../users/roles';
import { OUTSOURCED } from './statuses';
import AddToCartButton from './AddToCartButton';
import ArticleType from './ArticleType';
import ColorsField from "./ColorsField";
import LightboxField from '../components/LightboxField';

const styles = theme => {
    const { minWidth, nowrap } = generalStyles(theme);

    return {
        minWidth,
        datagrid: {
            '& td:not(:last-child)': {
                maxWidth: 300,
                wordWrap: 'break-word'
            },
        },
        nowrap: {
            ...nowrap,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 150,
        },
        articlePhotoModal: {
            maxWidth: 500,
        },
        imageField: {
            textAlign: 'center',
            '& img': {
                margin: 0,
                cursor: 'pointer',
                width: '100px',
                height: '100px',
                objectFit: 'cover',
                objectPosition: 'center'
            },
        },
        barcodesContainer: {
            '& > span': {
                display: 'inline-block',
                padding: '5px',
                borderRadius: '50%',
                margin: 2,
            }
        },
        scanned: {
            background: theme.palette.primary.scanned,
        },
        notScanned: {
            background: theme.palette.primary.notScanned,
        }
    };
};

const BarcodesForOutsourcingPage = ({ record, classes, scannedBarcodes, ...props }) => {

    const fullyScannedArticle = !record.barcodes.filter(({ id }) => !scannedBarcodes.includes(id)).length;

    return (
        <div className={classes.barcodesContainer}>
            {record.barcodes.map(({ id }) =>
                <span
                    className={
                        fullyScannedArticle || record.status === OUTSOURCED
                            ? null
                            : scannedBarcodes.includes(id)
                                ? classes.scanned
                                : classes.notScanned
                    }
                    key={id}
                >
                    {id}
                </span>)
            }
        </div>
    )
};

const Barcodes = ({ record, classes, ...props }) => (
    <TextField {...props}
        source="barcodes"
        record={{ barcodes: record.barcodes.map(({ id }) => id).join(', ') }}
        className={classes.nowrap}
    />
);

Barcodes.defaultProps = {
    addLabel: true,
};

const ArticleDatagrid = ({ record, classes, customActions, permissions, basePath, outsourcingPage, scannedBarcodes, ...props }) => (
    <Fragment>
        <Datagrid basePath={basePath} {...props} className={classes.datagrid}>
            {!outsourcingPage
                ? <Barcodes label="QID" sortable={false} classes={classes} />
                : <BarcodesForOutsourcingPage label="QID" sortable={false} classes={classes}
                    scannedBarcodes={scannedBarcodes} />
            }
            <LightboxField
                source="photos"
                label="Photo"
                sortable={false}
            />
            <TextField source="name" />
            <TextField
                source="category.name"
                label="resources.articles.fields.category"
                sortBy="category"
            />
            <ColorsField label="resources.articles.fields.colors" />
            <TextField source="width" label="resources.articles.fields.width" />
            <TextField source="height" label="resources.articles.fields.height" />
            <TextField source="length" label="resources.articles.fields.length" />
            <ArticleType source="type" />
            {permissions !== ROLE_COLLECTOR_GUY && (
                <ReferenceField
                    source="barcodes[0].project_id"
                    label="resources.articles.fields.project"
                    reference="projects"
                    sortable={false}
                    basePath="/projects"
                    linkType={
                        (
                            permissions === ROLE_WAREHOUSE_MANAGER
                            || permissions === ROLE_MAIN_CUSTOMER
                            || permissions === ROLE_CUSTOMER
                        ) ? 'show' : 'edit'
                    }
                >
                    <AddressField source="customer.address" />
                </ReferenceField>
            )}
            <TextField source="manufacturer"
                label="resources.articles.fields.manufacturer"
            />
            <TextField source="storagearea.full_name"
                label="resources.articles.fields.warehouse_place"
                sortBy="storagearea"
            />
            <FunctionField
                render={record => record.thread?.num_comments || ''}
                label="resources.articles.fields.comments"
                sortable={false}
            />
            <FunctionField
                render={record => record.is_scratch ? <CheckIcon /> : ''}
                label="resources.articles.fields.is_scratch"
                sortBy="isScratch"
            />
            <UpdatedAtField
                sortBy="updatedAt"
                headerClassName={classes.minWidth}
            />
            <NumberField source="id" sortable={false} />
            {!customActions && (
                <ArticleActions headerClassName={classes.minWidth}
                    editable={
                        permissions !== ROLE_MAIN_CUSTOMER
                        && permissions !== ROLE_CUSTOMER
                    }
                    deletable={
                        permissions === ROLE_SUPER_ADMIN
                        || permissions === ROLE_AGENT
                        || permissions === ROLE_OFFICE_EMPLOYEE
                    }
                    isShow={
                        permissions === ROLE_MAIN_CUSTOMER
                        || permissions === ROLE_CUSTOMER
                    }
                    customButtons={permissions !== ROLE_COLLECTOR_GUY &&
                        <AddToCartButton />}
                />
            )}
            {customActions && (
                <ArticleActions headerClassName={classes.minWidth}
                    editable={false}
                    deletable={false}
                    isShow={false}
                    customButtons={customActions}
                />
            )}
        </Datagrid>
    </Fragment>
);

export default withStyles(styles)(ArticleDatagrid);
