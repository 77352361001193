import { keyBy } from 'lodash';
import { withStyles } from '@material-ui/core';
import React from 'react';

import ArticleDatagrid from '../articles/ArticleDatagrid';
import generalStyles from '../styles';
import RemoveArticleButton from './RemoveArticleButton';

const styles = theme => {
    const { nowrap } = generalStyles(theme);

    return {
        nowrap,
        lastLine: {
            '& > tbody > tr:last-child > td': {
                border: 'none',
            },
        },
    };
};

const ExpandDelivery = ({ classes, permissions, basePath, record: { articles, id: deliveryId } }) => (
    <ArticleDatagrid ids={articles.map(({ id }) => id)}
                     data={keyBy(articles, 'id')}
                     currentSort={{
                         field: 'id',
                         order: 'DESC',
                     }}
                     basePath={basePath}
                     permissions={permissions}
                     className={classes.lastLine}
                     customActions={
                         <RemoveArticleButton className={classes.nowrap} deliveryId={deliveryId} />
                     }
    />
);

export default withStyles(styles)(ExpandDelivery);
