import { Card, CardContent, CardHeader, Chip, Divider, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { translate } from 'react-admin';
import { compose } from 'recompose';

const styles = theme => ({
    root: {
        marginTop: 12,
        marginBottom: 12,
    },
    container: {
        paddingTop: 12,
        paddingBottom: 12,
    },
    header: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    barcodesField: {
        display: 'flex',
        alignItems: 'center',
    },
    barcodesList: {
        marginLeft: '1rem',
    },
    chip: {
        marginRight: theme.spacing.unit,
    },
});

const Article = ({ classes, translate, article: { id: articleId, totalBarcodes, barcodes } }) => (
    <Card className={classes.root}>
        <CardHeader title={
            <div className={classes.header}>
                <Typography variant="title">Article ID: #{articleId}</Typography>
                <Typography variant="caption">
                    {translate('resources.acceptance.fields.scanned_barcodes')}: {barcodes.length}/{totalBarcodes}
                </Typography>
            </div>
        } />
        <Divider />
        <CardContent className={`${classes.container} ${classes.barcodesField}`}>
            <Typography variant="caption">QR codes:</Typography>
            <div className={classes.barcodesList}>
                {barcodes.map(barcodeId => (
                    <Chip key={barcodeId} label={barcodeId} className={classes.chip} />
                ))}
            </div>
        </CardContent>
    </Card>
);

const enhance = compose(
    withStyles(styles),
    translate,
);

export default enhance(Article);
