import { CircularProgress, withStyles } from '@material-ui/core';
import { Done as DoneIcon, NavigateNext as NavigateNextIcon } from '@material-ui/icons';
import React, { Component } from 'react';
import { Button } from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { guttersContainer } from '../helpers';

const styles = theme => ({
    form: {
        ...guttersContainer(theme),
    },
    done: {
        marginLeft: '0.5em',
    },
    noWrap: {
        display: 'flex',
        flexWrap: 'noWrap',
    },
});

class ArticleActions extends Component {

    state = {
        isDone: false,
        isNext: false,
    };

    handleDone = () => {
        const { handleSubmitWithRedirect } = this.props;

        this.setState({
            isDone: true,
            isNext: false,
        });

        handleSubmitWithRedirect('done')();
    };

    handleNext = () => {
        const { handleSubmitWithRedirect } = this.props;

        this.setState({
            isDone: false,
            isNext: true,
        });

        handleSubmitWithRedirect('next')();
    };

    render() {
        const { classes, isLoading } = this.props;
        const { isNext, isDone } = this.state;

        return (
            <div className={classes.noWrap}>
                <Button label="ra.action.next"
                        size="medium"
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                        className={classes.done}
                        onClick={this.handleNext}
                        alignIcon="right"
                >
                    {isLoading && isNext ? <CircularProgress size={20} thickness={2} /> : <NavigateNextIcon />}
                </Button>

                <Button label="ra.action.done"
                        size="medium"
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                        className={classes.done}
                        onClick={this.handleDone}
                >
                    {isLoading && isDone ? <CircularProgress size={20} thickness={2} /> : <DoneIcon />}
                </Button>
            </div>
        );
    }

}

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        null,
    ),
);

export default enhance(ArticleActions);
