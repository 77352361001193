import React from 'react';
import {Show, CardActions, ListButton} from 'react-admin';

import {BodyMobileGrid} from './MobileGrid';
import DeliveryTitle from "./DeliveryTitle";
import CancelButton from "./CancelButton";
import ApproveButton from "./ApproveButton";
import {CONFIRMED} from './statuses';
import {ROLE_CUSTOMER} from '../users/roles';

const DeliveryShowActions = ({permissions, data, resource, basePath, ...props}) => {
    if (!data) return null;

    return (
        <CardActions>
            {(permissions !== ROLE_CUSTOMER || data.status !== CONFIRMED) && (
                <CancelButton record={data} resource={resource} basePath={basePath}/>
            )}
            {permissions !== ROLE_CUSTOMER && data.status !== CONFIRMED && (
                <ApproveButton record={data} resource={resource} basePath={basePath}/>
            )}
            <ListButton record={data} resource={resource} basePath={basePath}/>
        </CardActions>
    )
};

const DeliveryShow = ({permissions, ...props}) => (
    <Show title={<DeliveryTitle/>} actions={<DeliveryShowActions permissions={permissions}/>} {...props}>
        <BodyMobileGrid isShownArticles={true} permissions={permissions}/>
    </Show>
);

export default DeliveryShow;
