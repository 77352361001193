import { withStyles } from '@material-ui/core';
import { ImageField as BaseImageField} from 'react-admin';

const ImageField = withStyles({
    list: {
        margin: '0 -0.5rem',
        padding: 0
    },
}, {
    name: 'ImageField',
})(BaseImageField);

ImageField.defaultProps = {
    addLabel: true,
};

export default ImageField;
