import { CircularProgress, withStyles } from '@material-ui/core';
import { List as ActionList, Save as ContentSave } from '@material-ui/icons';
import React, { Component, Children, cloneElement } from 'react';
import { Button, Confirm, DeleteButton, showNotification } from 'react-admin';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import { compose } from 'recompose';

import EmptySpan from './EmptySpan';

const styles = theme => ({
    defaultToolbar: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
    },
    xsWidth: {
        maxWidth: theme.breakpoints.values['xs'],
    },
    smWidth: {
        maxWidth: theme.breakpoints.values['sm'],
    },
    mdWidth: {
        maxWidth: theme.breakpoints.values['md'],
    },
    lgWidth: {
        maxWidth: theme.breakpoints.values['lg'],
    },
    xlWidth: {
        maxWidth: theme.breakpoints.values['xl'],
    },
    listIcon: {
        marginRight: '0.5em',
        fontSize: 24,
    },
    listLabel: {
        paddingLeft: 0,
    },
    saveButton: {
        marginLeft: '0.5em',
    },
    buttons: {
        display: 'flex',
    },
    children: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 6,
        },
    },
});

class UnconnectedToolbarContainer extends Component {

    state = {
        isOpenToListDialog: false,
    };

    handleConfirmToListDialog = () => {
        const { basePath, dispatch, push } = this.props;

        localStorage.removeItem('changed_storage_area');
        localStorage.removeItem('is_create');
        localStorage.removeItem('is_edit');

        dispatch(push(basePath));
    };

    handleDialogClose = () => {
        this.setState({
            isOpenToListDialog: false,
        });
    };

    handleSaveClick = event => {
        const { handleSubmitWithRedirect, invalid, saving, showNotification, record } = this.props;
        if (saving) {
            // prevent double submission
            event.preventDefault();
        } else {
            if (invalid) {
                showNotification('ra.message.invalid_form', 'warning');
            }
            // always submit form explicitly regardless of button type
            if (event) {
                event.preventDefault();
            }

            handleSubmitWithRedirect(record.id ? false : 'edit')();
        }
    };

    handleToListClick = () => {
        this.setState({
            isOpenToListDialog: true,
        });
    };

    render() {
        const {
            classes,
            children,
            className,
            dispatch,
            maxWidth,
            push,
            saving,
            showNotification,
            deletable,
            isShowToListButton,
            hideSaveButton,
            ...props
        } = this.props;
        const { isOpenToListDialog } = this.state;
        const { id, deleted_at } = props.record;
        const redirect = id ? false : 'edit';

        return (
            <div className={`${classes.defaultToolbar} ${className} ${maxWidth && classes[maxWidth + 'Width']}`}>
                {deletable && id && !deleted_at ? (
                    <DeleteButton {...props} undoable={false} size="medium" disabled={props.record.deletable === false}/>
                ) : <EmptySpan />}
                <div className={classes.buttons}>
                    {isShowToListButton && (
                        <Button size="medium"
                                label="ra.action.list"
                                onClick={this.handleToListClick}
                        >
                            <ActionList />
                        </Button>
                    )}
                    <Confirm isOpen={isOpenToListDialog}
                             title="ra.message.to_list"
                             content="ra.message.to_list_content"
                             onConfirm={this.handleConfirmToListDialog}
                             onClose={this.handleDialogClose}
                    />
                    {!hideSaveButton && <Button size="medium"
                            className={classes.saveButton}
                            label="ra.action.save"
                            variant="contained"
                            color={saving ? 'default' : 'primary'}
                            onClick={this.handleSaveClick}
                    >
                        {saving && saving.redirect === redirect ? (
                            <CircularProgress size={22} thickness={2} />
                        ) : (
                            <ContentSave />
                        )}
                    </Button>}
                    {children && (
                        <div className={classes.children}>
                            {Children.map(children, child => cloneElement(child, props))}
                        </div>
                    )}
                </div>
            </div>
        );
    };

}

const enhance = compose(
    withStyles(styles),
    connect(null, dispatch => ({
        push: pushAction,
        dispatch,
    })),
    connect(undefined, { showNotification }),
);

const ToolbarContainer = enhance(UnconnectedToolbarContainer);

ToolbarContainer.defaultProps = {
    isShowToListButton: true,
    deletable: true,
};

export default ToolbarContainer;
