import { get } from 'lodash';
import React from 'react';
import { translate } from 'react-admin';

const DEFAULT_SOURCE = 'address';

const UnconnectedTypeField = ({ record, source = DEFAULT_SOURCE, translate }) => {
    const address = get(record, source);
    const name = get(record, 'surname');

    let type;

    if (address) {
        type = 'delivery'
    } else if (name) {
        type = 'pickup'
    } else {
        type = 'disposal'
    }

    return (
        <span>
            {translate(`resources.outsourcing.type.${type}`)}
        </span>
    );
};

const TypeField = translate(UnconnectedTypeField);

TypeField.defaultProps = {
    record: {},
    source: DEFAULT_SOURCE,
    addLabel: true,
};

export default TypeField