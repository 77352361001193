import { withStyles, Card, CardContent, CardHeader } from '@material-ui/core';
import { Print as PrintIcon } from '@material-ui/icons';
import React, { Component, Fragment } from 'react';
import { translate } from 'react-admin';
import { compose } from 'recompose';
import { Button } from 'react-admin';

import AddSectionButton from './AddSectionButton';
import SectionNode from './SectionNode';
import ReactToPrint from 'react-to-print';
import NodeForPrint from './NodeForPrint';

const styles = {
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    printButton: {
        float: 'right',
        marginRight: 24,
        marginTop: 16,
        marginBottom: 16,
    },
    printedBarcodes: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    printedContainer: {
        width: 711,
        minWidth: 711,
    },
};

class PrintAllBarcodes extends Component {

    constructor(props) {
        super(props);

        this.state = {
            children: this.props.children,
        };
    }

    handleBeforeGetContent = () => {
        const children = this.props.onPrint();

        return new Promise(resolve => {
            this.setState({ children }, () => {
                resolve();
            });
        });
    }

    render() {
        const { classes, name, disabled } = this.props;
        const { children } = this.state;

        return (
            <Fragment>
                <ReactToPrint content={() => this.allPrintedBarcodes}
                    onBeforeGetContent={this.handleBeforeGetContent}
                    trigger={() => (
                        <Button label="ra.action.print_all" className={classes.printButton}>
                            <PrintIcon />
                        </Button>
                    )}
                />
                <div style={{ display: 'none' }}>
                    <div ref={this.setAllPrintedBarcodes} className={classes.printedBarcodes}>
                        <div className={classes.printedContainer}>
                            <NodeForPrint section={{ children: children || [] }}
                                isRoot={true}
                                path={[name]}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    setAllPrintedBarcodes = allPrintedBarcodes => {
        this.allPrintedBarcodes = allPrintedBarcodes;
    };

}

class Structure extends Component {

    handleAddSection = resource => {
        this.sectionNode.handleAddSection(resource);
    };

    handleOnChange = ({ children }) => {
        const { onChange } = this.props;
        onChange(children);
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.name !== nextProps.name || this.props.disabled !== nextProps.disabled || this.props.openedSections !== nextProps.openedSections || this.props.children !== nextProps.children;
    }

    handlePrint = () => {
        return this.props.children;
    }

    render() {
        const { children, classes, className, name, translate, record, disabled, onExpand, openSection, formSection } = this.props;

        return (
            <Card className={className}>
                <PrintAllBarcodes children={children}
                    onPrint={this.handlePrint}
                    classes={classes}
                    name={name}
                    disabled={disabled}
                />
                <CardHeader title={
                    <div className={classes.header}>
                        <div>{translate('resources.warehouses.data.structure')} {name && `(${name})`}</div>
                        {!disabled && (
                            <AddSectionButton onAdd={this.handleAddSection}
                                section={{
                                    ...record,
                                    name,
                                }}
                            />
                        )}
                    </div>
                } />
                <CardContent>
                    <SectionNode section={{ children: children || [] }}
                        formSection={formSection}
                        isRoot={true}
                        innerRef={this.setSectionNode}
                        onChange={this.handleOnChange}
                        path={[name]}
                        onExpand={onExpand}
                        disabled={disabled}
                        visible={true}
                        openSection={openSection}
                    />
                </CardContent>

            </Card>
        );
    }

    setSectionNode = sectionNode => {
        if (sectionNode) this.sectionNode = sectionNode.getWrappedInstance();
    };

}

const enhance = compose(
    withStyles(styles),
    translate,
);

export default enhance(Structure);
