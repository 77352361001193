import React from 'react';
import { Edit } from 'react-admin';

import { FullNameField } from '../components';

import CustomerForm from './CustomerForm';

const CustomerTitle = ({ record }) => (
    <span>
        #{record.id}. <FullNameField record={record} /> {record.address.company && `(${record.address.company})`}
    </span>
);

const CustomerEdit = ({ permissions, ...props }) => (
    <Edit title={<CustomerTitle />} undoable={false} {...props}>
        <CustomerForm permissions={permissions} />
    </Edit>
);

export default CustomerEdit;
