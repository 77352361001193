import { SwapHoriz as ExchangeIcon } from "@material-ui/icons";
import React, { Component } from "react";
import { translate } from "react-admin";
import { QrReaderButton } from "../components";
import { OUTSOURCED } from "../articles/statuses";

class ExchangeArticleButton extends Component {
  exchange = result => {
    const { handleConfirmBarcode } = this.props;
    handleConfirmBarcode(result);
  };

  render() {
    const {
      record: { id: articleId, barcodes, status },
      className,
      scannedBarcodes,
      deliveryId,
      exchange
    } = this.props;
    return (
      <QrReaderButton
        onConfirm={value => exchange(value, deliveryId, articleId)}
        className={className}
        label="resources.deliveries.actions.exchange_article"
        icon={<ExchangeIcon />}
        variant="text"
        disabled={
          !barcodes.filter(({ id }) => !scannedBarcodes.includes(id)).length ||
          status === OUTSOURCED
        }
      />
    );
  }
}

export default translate(ExchangeArticleButton);
