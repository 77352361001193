import {
    Avatar,
    Button,
    Card,
    CardActions,
    CircularProgress,
    MuiThemeProvider,
    createMuiTheme,
    withStyles,
} from '@material-ui/core';
import { Lock as LockIcon } from '@material-ui/icons';
import React, { Component } from 'react';
import { Notification, showNotification, email as emailValidation, translate } from 'react-admin';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';

import { formatTime } from '../helpers';
import { SEND_EMAIL } from '../providers/actions';
import withRecoveryPasswordProvider from '../providers/withRecoveryPasswordProvider';

import { styles } from './Login';
import renderInput from './renderInput';
import { lightTheme } from './themes';

const email = emailValidation();

class ForgotPassword extends Component {

    restore = userData => {
        const { dispatch, recoveryPasswordProvider, translate } = this.props;

        recoveryPasswordProvider(SEND_EMAIL, userData)
            .then(({ token_lifetime }) => {
                dispatch(showNotification('resetting.check_email', 'info', {
                    messageArgs: { token_lifetime: formatTime(token_lifetime, translate) },
                }));
            });
    };

    render() {
        const { classes, handleSubmit, isLoading, translate } = this.props;

        return (
            <div className={classes.main}>
                <Card className={classes.card}>
                    <div className={classes.avatar}>
                        <Avatar className={classes.icon}>
                            <LockIcon />
                        </Avatar>
                    </div>
                    <form onSubmit={handleSubmit(this.restore)}>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field autoFocus
                                       name="email"
                                       component={renderInput}
                                       label={translate('resources.users.fields.email')}
                                       disabled={isLoading}
                                />
                            </div>
                        </div>
                        <CardActions className={classes.actions}>
                            <Button variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={isLoading}
                                    className={classes.button}
                                    fullWidth
                            >
                                {isLoading && <CircularProgress size={20} thickness={2} />}
                                {translate('ra.action.restore_password')}
                            </Button>
                        </CardActions>
                    </form>
                </Card>
                <Notification />
            </div>
        );
    }

}

const enhance = compose(
    translate,
    reduxForm({
        form: 'forgotPassword',
        validate: (values, props) => {
            const { translate } = props;
            const errors = {};

            if (!values.email) {
                const translateField = translate('resources.users.fields.email');
                errors.email = translate('ra.validation.required', { field: translateField });
            }

            const emailMessage = email(values.email, values, { translate });
            if (emailMessage) {
                errors.email = emailMessage;
            }

            return errors;
        },
    }),
    withRecoveryPasswordProvider,
    withStyles(styles),
);

const EnhancedForgotPassword = enhance(ForgotPassword);

const ForgotPasswordWithTheme = props => (
    <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <EnhancedForgotPassword {...props} />
    </MuiThemeProvider>
);

export default ForgotPasswordWithTheme;
