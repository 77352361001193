import { Typography } from '@material-ui/core';
import React from 'react';
import { translate } from 'react-admin';

const ArticleInfos = ({ inWarehouse, total, translate }) => (
    <Typography>
        {`${inWarehouse} ${translate('resources.projects.articleinfos.of')} ${total} ${translate('resources.projects.articleinfos.scanned')}`}
    </Typography>
);

export default translate(ArticleInfos);
